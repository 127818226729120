import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { flatRoadMap } from '@src/pages/roadMap';
import { useDerivedState } from 'femo';
import UndertakeRoute from './UndertakeRoute';
const DispatchRoute = () => {
    const renderRoutes = (arr) => arr.map((road, index) => (<Route key={road.path} exact={false} path={road.path} render={(routeProps) => <UndertakeRoute road={road} index={index} {...routeProps}/>}/>));
    // 后续UndertakeRoute中不会再校验road中第一层的权限
    const [routeElements] = useDerivedState(() => renderRoutes(flatRoadMap()), [flatRoadMap]);
    return (<Switch>
      {routeElements}
    </Switch>);
};
export default DispatchRoute;
