export default {
    name: 'name',
    jobName: 'job_name',
    teamName: 'team_full_path',
    rootTeamName: 'root_team_name',
    leaderName: 'leader_name',
    staffType: 'customer_type',
    mobile: 'mobile',
    email: 'personal_email',
    companyName: 'company_name',
    workplace: 'work_place',
    offerType: 'offer_type_desc',
    createdTime: 'created_at_ut',
    creator: 'created_name',
    boardingTime: 'boarding_time_ut',
    quitTime: 'quit_time_uit',
    staffStatus: 'customer_status',
    salary: 'offer_salary',
    internSalary: 'salary',
    probationSalary: 'probation_salary',
    regularSalary: 'turn_regular_salary',
    firstChannel: 'request_channel_first',
    secondChannel: 'request_channel_second',
    thirdChannel: 'request_channel_third',
    breakContractTime: 'break_contract_time_ut',
    boardPlace: 'boarding_place',
    work_type: 'work_type',
};
