import { RoadMapType } from '@src/pages/interface';
import { gluer } from 'femo';
import { lazy } from 'react';
const roadRegular = gluer({
    name: '制度确认',
    path: '/compliance/confirm-r',
    type: RoadMapType.fallen,
    hasSider: false,
    hasHeader: false,
    component: lazy(() => import('./Regular')),
});
const roadSale = gluer({
    name: '制度确认',
    path: '/compliance/confirm-s',
    type: RoadMapType.fallen,
    hasSider: false,
    hasHeader: false,
    component: lazy(() => import('./Sale')),
});
export default {
    roadRegular,
    roadSale,
};
