import React from 'react';
import sentry from '@src/tools/sentry';
import Failed from '@src/components/Failed';
import { withRouter } from 'react-router-dom';
import Placeholder from '@src/components/Placeholder';
class Crash extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
            text: '页面崩溃了，请稍后重试...',
            error: null,
        };
    }
    static getDerivedStateFromError(error) {
        const errorStr = String(error);
        const tmpState = {
            hasError: true,
            error,
        };
        if (errorStr.startsWith('ChunkLoadError')) {
            tmpState.text = (<section>
          <section>网络出现问题，请刷新页面重试。</section>
          <section>
            (<Placeholder text={errorStr}/>)
          </section>
        </section>);
        }
        return tmpState;
    }
    componentDidCatch(error, _info) {
        // console.log(error, info);
        // 手动上报错误异常
        sentry.reportError(error);
    }
    render() {
        if (this.state.hasError && !this.props.fallback) {
            return (<Failed text={this.state.text}/>);
        }
        if (this.state.hasError && this.props.fallback) {
            return this.props.fallback(this.state.error);
        }
        return this.props.children;
    }
}
export default withRouter(Crash);
