export default {
    name: 'name',
    staff_type: 'staff_type',
    company: 'company',
    team: 'team',
    staff_status: 'staff_status',
    channel: 'channel',
    created_time: 'created_time',
    created_by: 'created_by',
    quit_time: 'quit_time',
    cost_status: 'cost_status',
    issuer_status: 'issuer_status',
    boarding_time: 'boarding_time',
};
