import { RoadMapType } from '@src/pages/interface';
import { gluer } from 'femo';
import { lazy } from 'react';
const initRoad = {
    name: '员工手册确认',
    path: '/push/staff-principle',
    component: lazy(() => import('./SaleService/StaffPrinciple')),
    type: RoadMapType.fallen,
    hasSider: false,
    hasHeader: false,
};
export default gluer(initRoad);
