import useVisible from '@src/hooks/useVisible';
import { useCallback, useEffect, useRef, useState, } from 'react';
const useStaffDetail = (v = false) => {
    const tmpResult = useVisible(v);
    const flagRef = useRef(false);
    const [candidate, updateCandidate] = useState();
    const show = useCallback((c) => {
        updateCandidate(c);
        tmpResult.show();
    }, [tmpResult]);
    const [result, updateResult] = useState(() => ({
        ...tmpResult,
        show,
        candidate,
    }));
    useEffect(() => {
        if (flagRef.current) {
            updateResult({
                ...tmpResult,
                show,
                candidate,
            });
        }
        else {
            flagRef.current = true;
        }
    }, [show, candidate, tmpResult]);
    return result;
};
export default useStaffDetail;
