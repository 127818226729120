import React, { useCallback, useState, } from 'react';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import ConditionSelect, { Condition } from '@src/pages/components/ConditionSelect';
import { Form } from 'antd';
import { getSafe, isEmpty } from '@src/tools/util';
import moment from 'moment';
import Picker from '@src/pages/components/filter/components/TimePicker/Picker';
const conditions = [{
        name: '等于',
        id: Condition.equal,
    }, {
        name: '晚于等于',
        id: Condition.notEarlier,
    }, {
        name: '早于等于',
        id: Condition.notLater,
    }];
const TimeFilterField = (props) => {
    const { renderExtends, valueSpanStyle, conditions, format, ...rest } = props;
    const renderCondition = useCallback((_v, condition, onChange) => (<ConditionSelect conditions={conditions} value={condition} onChange={onChange}/>), []);
    const [fncCache] = useState(() => new Map());
    const pickerChange = useCallback((onChange) => {
        if (fncCache.has(onChange)) {
            return fncCache.get(onChange);
        }
        const fnc = (v) => {
            let r = v;
            if (!(v instanceof Array)) {
                r = [v];
            }
            onChange(r.map((m) => {
                if (!isEmpty(m)) {
                    return moment(m).format(format);
                }
                return undefined;
            }));
        };
        fncCache.set(onChange, fnc);
        return fnc;
    }, [format]);
    const renderValue = useCallback((v, c, onChange) => {
        const oc = pickerChange(onChange);
        const condition = getSafe(c, '[0]');
        // 这里的处理对应：所有的value都是数组
        return <Picker format={format} condition={condition} value={v} onChange={oc}/>;
    }, [format]);
    return (<Form.Item {...fieldLayout} {...rest}>
      <FilterFieldCompose valueSpanStyle={valueSpanStyle} renderValue={renderValue} renderCondition={renderCondition} renderExtends={renderExtends}/>
    </Form.Item>);
};
TimeFilterField.defaultProps = {
    conditions,
    format: 'YYYY-MM-DD',
};
export default TimeFilterField;
