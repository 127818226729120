import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import StaffTypeField from '@src/pages/HR/formFields/StaffType';
import { getSafe } from '@src/tools/util';
import { useModel } from 'femo';
import permission from '@src/model/permission';
import NeedProbation from '@src/pages/HR/formFields/NeedProbation';
import StaffNameField from '@src/pages/HR/formFields/Name';
import MobileField from '@src/pages/HR/formFields/Mobile';
import PersonalEmailField from '@src/pages/HR/formFields/PersonalEmail';
import EnterpriseEmailField from '@src/pages/HR/formFields/EnterpriseEmail';
import EntryTimeField from '@src/pages/HR/formFields/EntryTime';
import JobNameField from '@src/pages/HR/formFields/JobName';
import OrganizationField from '@src/pages/HR/formFields/Organization';
import WorkPlaceField from '@src/pages/HR/formFields/WorkPlace';
import CompanyField from '@src/pages/HR/formFields/Company';
import ContractTypeField from '@src/pages/HR/formFields/ContractType';
import ContractStartField from '@src/pages/HR/formFields/TimeStart';
import ContractEndField from '@src/pages/HR/formFields/TimeEnd';
import TurnRegularField from '@src/pages/HR/formFields/TureRegular';
import { fieldNames } from '@src/pages/HR/Organization/Struct/modals/StaffCommon/fields/config';
import Infinite from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/Infinite';
import { contractEndTimeRules, getRules } from '@src/pages/HR/Organization/Struct/formUtils';
import { SalaryPaymentKind, StaffType, User } from '@src/api/interface';
import SalaryField from '@src/pages/HR/formFields/Salary';
import SalaryTypeField from '@src/pages/HR/formFields/SalaryType';
import FloatingBonusField from '@src/pages/HR/formFields/FloatingBonus';
const RegularForm = (props) => {
    const { form, orgId, isInfiniteType, staffType, stickyMobile, disableStaffTypeField, extraFields, typeFilter, initialStaffType, hideCorpEmail, checkMobile, canSelectEmail, initEmailSuffix, fromOfferTwo, orgRecord, } = props;
    const st = staffType || initialStaffType;
    const [perm] = useModel(permission.permission);
    const corpEmailIsRequired = st === StaffType.regular || st === StaffType.regularPractice;
    const salaryHiddenFlag = useMemo(() => fromOfferTwo
        && getSafe(orgRecord, 'intern_salary_hidden_flag')
        && !getSafe(perm, `${User.ModuleHRSubAuthKey.function_hr_offer_intern_salary_hidden_manage}`), [orgRecord, perm, fromOfferTwo]);
    return (<>
      {extraFields}
      <Row>
        <Col span={12}>
          <StaffTypeField initialValue={initialStaffType} typeFilter={typeFilter} disabled={disableStaffTypeField} name={fieldNames.staffType} rules={getRules('请选择')}/>
        </Col>
        <Col span={4}/>
        <Col span={8}>
          <NeedProbation name={fieldNames.need_probation}/>
        </Col>
      </Row>
      <header>个人信息</header>
      <Row>
        <Col span={12}>
          <StaffNameField rules={getRules('请输入')} name={fieldNames.staffName}/>
        </Col>
        <Col span={12}>
          <MobileField needCheck={checkMobile} stickyMobile={stickyMobile} rules={getRules('请输入')} name={fieldNames.staffMobile}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <PersonalEmailField rules={getRules('请输入')} name={fieldNames.personal_email}/>
        </Col>
        {!hideCorpEmail && (<Col span={12}>
              <EnterpriseEmailField initEmailSuffix={initEmailSuffix} canSelectEmail={canSelectEmail} isRequired={corpEmailIsRequired} rules={corpEmailIsRequired ? getRules('请输入', true) : []} placeholder={corpEmailIsRequired ? '请输入' : '请输入（选填）'} name={fieldNames.corpEmail}/>
            </Col>)}
      </Row>
      <header>岗位信息</header>
      <Row>
        <Col span={12}>
          <EntryTimeField name={fieldNames.entry_time_ut} rules={getRules('请选择')}/>
        </Col>
        <Col span={12}>
          <JobNameField rules={getRules('请输入')} label='岗位名称' name={fieldNames.job_id}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <OrganizationField rules={getRules('请选择')} disableTop initialValue={orgId} name={fieldNames.teamId}/>
        </Col>
        <Col span={12}>
          <WorkPlaceField rules={getRules('请选择')} name={fieldNames.work_place}/>
        </Col>
      </Row>
      <header>合同信息</header>
      <Row>
        <Col span={12}>
          <CompanyField rules={getRules('请选择')} name={fieldNames.company_id}/>
        </Col>
        <Col span={12}>
          <ContractTypeField rules={getRules('请选择')} name={fieldNames.contract_type}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            <Col span={16}>
              <SalaryField labelCol={{
            sm: { span: 9 },
            xs: { span: 24 },
        }} wrapperCol={{
            sm: {
                span: 15,
            },
            xs: {
                span: 24,
            },
        }} label='试用期薪资' disabled={salaryHiddenFlag} name={fieldNames.probation_salary} rules={getRules('请输入')}/>
            </Col>
            <Col span={7}>
              <SalaryTypeField disabled={salaryHiddenFlag} rules={getRules('请选择')} initialValue={SalaryPaymentKind.byMonth} name={fieldNames.probation_salary_type}/>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={16}>
              <SalaryField labelCol={{
            sm: { span: 9 },
            xs: { span: 24 },
        }} wrapperCol={{
            sm: {
                span: 15,
            },
            xs: {
                span: 24,
            },
        }} label='正常薪资' disabled={salaryHiddenFlag} name={fieldNames.regular_salary} rules={getRules('请输入')}/>
            </Col>
            <Col span={7}>
              <SalaryTypeField disabled={salaryHiddenFlag} rules={getRules('请选择')} initialValue={SalaryPaymentKind.byMonth} name={fieldNames.regular_salary_type}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FloatingBonusField rules={getRules('请输入')} label='浮动奖金' name={fieldNames.floatingBonus}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <ContractStartField rules={getRules('请选择')} name={fieldNames.contract_start_ut} endName={fieldNames.contract_end_ut} form={form}/>
        </Col>
        <Col span={12}>
          {isInfiniteType
            ? <Infinite name='合同结束：'/>
            : <ContractEndField rules={[...getRules('请选择'), ...contractEndTimeRules]} name={fieldNames.contract_end_ut} startName={fieldNames.contract_start_ut} form={form}/>}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <TurnRegularField rules={getRules('请选择')} name={fieldNames.turn_regular_time_ut}/>
        </Col>
      </Row>
    </>);
};
RegularForm.defaultProps = {
    checkMobile: true,
};
export default RegularForm;
