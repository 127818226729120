import { common } from '@src/api/actions';
import { getSafe } from '@src/tools/util';
import { genRaceQueue } from 'femo';
import model from './jobListModel';
const listQueue = genRaceQueue();
export const getJobList = () => {
    const promise = model.jobList(() => common.getCommonJobList().then((res) => getSafe(res, 'data.job_list') || []));
    listQueue.push(promise);
    return promise;
};
