import React, { useCallback, useState, useMemo, } from 'react';
import { Row, Col } from 'antd';
import { useModel } from 'femo';
import permission from '@src/model/permission';
import { getSafe } from '@src/tools/util';
import StaffTypeField from '@src/pages/HR/formFields/StaffType';
import StaffNameField from '@src/pages/HR/formFields/Name';
import MobileField from '@src/pages/HR/formFields/Mobile';
import PersonalEmailField from '@src/pages/HR/formFields/PersonalEmail';
import EnterpriseEmailField from '@src/pages/HR/formFields/EnterpriseEmail';
import EntryTimeField from '@src/pages/HR/formFields/EntryTime';
import JobNameField from '@src/pages/HR/formFields/JobName';
import OrganizationField from '@src/pages/HR/formFields/Organization';
import WorkPlaceField from '@src/pages/HR/formFields/WorkPlace';
import CompanyField from '@src/pages/HR/formFields/Company';
import ContractTypeField from '@src/pages/HR/formFields/ContractType';
import SalaryField from '@src/pages/HR/formFields/Salary';
import SalaryTypeField from '@src/pages/HR/formFields/SalaryType';
import ContractStartField from '@src/pages/HR/formFields/TimeStart';
import ContractEndField from '@src/pages/HR/formFields/TimeEnd';
import { fieldNames } from '@src/pages/HR/Organization/Struct/modals/StaffCommon/fields/config';
import Infinite from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/Infinite';
import { contractEndTimeRules, getRules } from '@src/pages/HR/Organization/Struct/formUtils';
import { User } from '@src/api/interface';
import FloatingBonusField from '@src/pages/HR/formFields/FloatingBonus';
const PracticeForm = (props) => {
    const { orgRecord, form, orgId, isInfiniteType, stickyMobile, disableStaffTypeField, extraFields, typeFilter, initialStaffType, hideCorpEmail, checkMobile, canSelectEmail, initEmailSuffix, fromOfferTwo, } = props;
    const [salaryInterDisabled, updateSalaryInterDisabled] = useState(false);
    const setSalaryDisabled = useCallback((b) => {
        updateSalaryInterDisabled(b);
        if (b) {
            form.setFieldsValue({
                [fieldNames.salary_intern]: 0,
            });
        }
    }, [form]);
    const [perm] = useModel(permission.permission);
    const salaryHiddenFlag = useMemo(() => fromOfferTwo
        && getSafe(orgRecord, 'intern_salary_hidden_flag')
        && !getSafe(perm, `${User.ModuleHRSubAuthKey.function_hr_offer_intern_salary_hidden_manage}`), [orgRecord, perm, fromOfferTwo]);
    return (<>
      {extraFields}
      <Row>
        <Col span={12}>
          <StaffTypeField initialValue={initialStaffType} typeFilter={typeFilter} disabled={disableStaffTypeField} name={fieldNames.staffType} rules={getRules('请选择')}/>
        </Col>
      </Row>
      <header>
        个人信息
      </header>
      <Row>
        <Col span={12}>
          <StaffNameField rules={getRules('请输入')} name={fieldNames.staffName}/>
        </Col>
        <Col span={12}>
          <MobileField needCheck={checkMobile} stickyMobile={stickyMobile} rules={getRules('请输入')} name={fieldNames.staffMobile}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <PersonalEmailField rules={getRules('请输入')} name={fieldNames.personal_email}/>
        </Col>
        {!hideCorpEmail && (<Col span={12}>
              <EnterpriseEmailField canSelectEmail={canSelectEmail} initEmailSuffix={initEmailSuffix} name={fieldNames.corpEmail}/>
            </Col>)}
      </Row>
      <header>
        岗位信息
      </header>
      <Row>
        <Col span={12}>
          <EntryTimeField name={fieldNames.entry_time_ut} rules={getRules('请选择')}/>
        </Col>
        <Col span={12}>
          <JobNameField rules={getRules('请输入')} name={fieldNames.job_id}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <OrganizationField rules={getRules('请选择')} disableTop initialValue={orgId} name={fieldNames.teamId}/>
        </Col>
        <Col span={12}>
          <WorkPlaceField rules={getRules('请选择')} name={fieldNames.work_place}/>
        </Col>
      </Row>
      <header>
        合同信息
      </header>
      <Row>
        <Col span={12}>
          <CompanyField rules={getRules('请选择')} name={fieldNames.company_id}/>
        </Col>
        <Col span={12}>
          <ContractTypeField rules={getRules('请选择')} name={fieldNames.contract_type}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            <Col span={16}>

              <SalaryField label='实习薪资' name={fieldNames.salary_intern} disabled={salaryHiddenFlag || !!orgRecord} labelCol={{
            sm: { span: 9 },
            xs: { span: 24 },
        }} wrapperCol={{
            sm: {
                span: 15,
            },
            xs: {
                span: 24,
            },
        }} rules={getRules('请输入')}/>
            </Col>
            <Col span={8}>
              <SalaryTypeField setSalaryDisabled={setSalaryDisabled} rules={getRules('请选择')} disabled={salaryHiddenFlag || !!orgRecord} isIntern name={fieldNames.salary_intern_type}/>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <FloatingBonusField label='浮动奖金' name={fieldNames.floatingBonus}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <ContractStartField rules={getRules('请选择')} name={fieldNames.contract_start_ut} endName={fieldNames.contract_end_ut} form={form}/>
        </Col>
        <Col span={12}>
          {isInfiniteType
            ? <Infinite name='合同结束：'/>
            : <ContractEndField rules={[...getRules('请选择'), ...contractEndTimeRules]} name={fieldNames.contract_end_ut} startName={fieldNames.contract_start_ut} form={form}/>}
        </Col>
      </Row>
    </>);
};
PracticeForm.defaultProps = {
    checkMobile: true,
};
export default PracticeForm;
