import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
const TimeStart = (props) => {
    const { form, endName, disabled, errorMsg, disabledDate, showToday, ...restProps } = props;
    const [rules] = useState(() => ([{
            validator: (_rule, value) => {
                if (value) {
                    const contractEndUt = form.getFieldValue(endName);
                    if (contractEndUt) {
                        const maxStartUt = moment(value).endOf('day').valueOf();
                        const minEndUt = moment(contractEndUt).startOf('day').valueOf();
                        if (maxStartUt >= minEndUt) {
                            return Promise.reject(errorMsg);
                        }
                        const nowEnd = moment().endOf('day').valueOf();
                        const endTime = moment(contractEndUt).valueOf();
                        // 如果此时结束时间小于今天，则不去设置结束时间字段的内容
                        // 因为结束那里自己有校验
                        if (endTime <= nowEnd) {
                            return Promise.resolve();
                        }
                        form.setFields([{
                                name: endName,
                                value: contractEndUt,
                                errors: [],
                                validating: false,
                                touched: false,
                            }]);
                    }
                }
                return Promise.resolve();
            },
        }]));
    return (<Form.Item validateFirst {...restProps} rules={restProps.rules ? [...restProps.rules, ...rules] : rules}>
      <DatePicker disabledDate={disabledDate} disabled={disabled} showToday={showToday} placeholder='请选择'/>
    </Form.Item>);
};
TimeStart.defaultProps = {
    label: '合同开始',
    disabled: false,
    errorMsg: '合同开始时间必须小于合同结束时间',
    showToday: true,
};
export default TimeStart;
