import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
const Infinite = (props) => {
    const { name } = props;
    return (<Row style={{ height: '32px', lineHeight: '32px' }}>
      <Col span={6} style={{ textAlign: 'right' }}>
        {name}
      </Col>
      不限
    </Row>);
};
Infinite.Time = moment([2037, 0, 1]);
export default Infinite;
