import React, { useCallback, useEffect, useState, } from 'react';
import { TreeSelect } from 'antd';
import { getSafe } from '@src/tools/util';
import useModel from '@src/hooks/useModel';
import classNames from 'classnames';
import Clamp from '@src/components/Clamp';
import model from './model';
import { fetchOrgTree } from './service';
import style from './style.less';
const { TreeNode } = TreeSelect;
const genTreeNodes = (tree, depth, hideDel, hideLeader, disableTop, disabledIds) => tree.map((treeNode) => {
    const teamName = getSafe(treeNode, 'team_name');
    const teamId = getSafe(treeNode, 'team_id');
    const children = getSafe(treeNode, 'sub_teams') || [];
    let isLeaf = !getSafe(children, 'length');
    const isDel = getSafe(treeNode, 'is_del');
    const disabled = getSafe(treeNode, 'disabled');
    if (hideDel) {
        const hasOne = children.find((d) => !getSafe(d, 'is_del'));
        if (!hasOne) {
            isLeaf = true;
        }
    }
    if (hideDel && isDel)
        return null;
    const props = {};
    if (typeof disableTop === 'boolean') {
        props.disabled = disableTop;
    }
    if ((disabledIds instanceof Array && disabledIds.indexOf(teamId) !== -1) || disabled) {
        (children || []).forEach((d) => {
            d.disabled = true;
        });
        props.disabled = true;
    }
    const leaders = getSafe(treeNode, 'leaders') || [];
    const title = (<span className='title-with-leader'>
      <span className='team'>
        <Clamp>
          {teamName}
        </Clamp>
      </span>
      <span className='placeholder'/>
      {(leaders && leaders.length && !hideLeader) ? (<span className='leader'>
            <Clamp>
              {leaders.map((l) => getSafe(l, 'name')).join(',')}
            </Clamp>
          </span>) : null}
    </span>);
    if (isLeaf) {
        return (<TreeNode {...props} label={teamName} title={title} key={teamId} isLeaf={isLeaf} value={teamId} className={`tree-node-${depth}`}/>);
    }
    return (<TreeNode {...props} label={teamName} title={title} key={teamId} isLeaf={isLeaf} value={teamId} className={`tree-node-${depth}`}>
      {genTreeNodes(children, depth + 1, hideDel, hideLeader, undefined, disabledIds)}
    </TreeNode>);
});
const OrgSelect = (props) => {
    const { dropdownClassName, hideDel, disableTop, disabledIds, hideTop, hideLeader, ...restProps } = props;
    const [tree] = useModel(model.tree);
    const [nodeElements, updateNodeElements] = useState(() => {
        let arr = tree;
        if (hideTop) {
            arr = getSafe(arr, '[0].sub_teams', []);
        }
        return genTreeNodes(arr, 0, hideDel, disableTop, hideLeader, disabledIds);
    });
    useEffect(() => {
        if (tree && tree.length > 0)
            return;
        fetchOrgTree();
    }, []);
    useEffect(() => {
        let arr = tree;
        if (hideTop) {
            arr = getSafe(arr, '[0].sub_teams', []);
        }
        updateNodeElements(genTreeNodes(arr, 0, hideDel, disableTop, hideLeader, disabledIds));
    }, [tree, hideDel, disableTop, disabledIds, hideTop, hideLeader]);
    const [expandedKeys, updateExpandedKeys] = useState(() => (tree || []).map((t) => `${getSafe(t, 'team_id')}`));
    useEffect(() => {
        updateExpandedKeys((tree || []).map((t) => `${getSafe(t, 'team_id')}`));
    }, [tree]);
    const onTreeExpand = useCallback((eks) => {
        updateExpandedKeys(eks);
    }, []);
    const [expandAll, updateExpandAll] = useState(false);
    const onSearch = useCallback((v) => {
        if (v) {
            updateExpandAll(true);
        }
        else {
            updateExpandAll(false);
        }
    }, []);
    const extraProps = {};
    if (!expandAll) {
        extraProps.treeExpandedKeys = expandedKeys;
    }
    return (<TreeSelect showSearch treeDefaultExpandAll={expandAll} onTreeExpand={onTreeExpand} dropdownClassName={classNames(style.orgSelect, dropdownClassName, 'org-tree-select')} placeholder='请选择' treeNodeFilterProp='label' treeNodeLabelProp='label' onSearch={onSearch} {...extraProps} {...restProps} defaultValue={restProps.defaultValue || undefined}>
      {nodeElements}
    </TreeSelect>);
};
OrgSelect.defaultProps = {
    hideDel: false,
    disableTop: false,
};
export default OrgSelect;
