import React from 'react';
import { Empty } from 'antd';
class NoData extends React.Component {
    render() {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...this.props}/>;
    }
}
NoData.defaultProps = {
    description: '无数据',
};
export default NoData;
