import React, { forwardRef, useEffect, useRef, } from 'react';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import parser from 'ua-parser-js';
import { getSafe } from '@src/tools/util';
import './style.less';
let isWindows = false;
const os = new parser.UAParser().getOS().name;
if (os) {
    isWindows = os.toLocaleLowerCase().includes('windows');
}
// 简单事件分发
const event = {
    listenersMap: {},
    on: (key, handle) => {
        if (!(event.listenersMap[key] instanceof Array)) {
            event.listenersMap[key] = [];
        }
        event.listenersMap[key].push(handle);
    },
    emit: (key, data) => {
        const listeners = event.listenersMap[key];
        if (!(listeners instanceof Array)) {
            return;
        }
        listeners.forEach((listener) => {
            if (typeof listener === 'function') {
                listener(data);
            }
        });
    },
    removeListeners: (key) => {
        if (event.listenersMap[key]) {
            delete event.listenersMap[key];
        }
    },
    removeAlListeners: () => {
        Object.keys(event.listenersMap).forEach((key) => {
            delete event.listenersMap[key];
        });
    },
};
const requestAnimationFrameModule = {
    key: 'requestAnimationFrameModule',
    runCounter: 0,
    addCounter: 0,
    isRun: false,
    frameMount: 60,
    add: () => {
        requestAnimationFrameModule.addCounter += 1;
        if (!requestAnimationFrameModule.isRun) {
            requestAnimationFrameModule.run();
        }
        requestAnimationFrameModule.isRun = true;
    },
    minus: () => {
        requestAnimationFrameModule.addCounter -= 1;
        if (requestAnimationFrameModule.addCounter <= 0) {
            requestAnimationFrameModule.stop();
        }
    },
    run: () => {
        if (requestAnimationFrameModule.addCounter <= 0) {
            return;
        }
        requestAnimationFrameModule.runCounter += 1;
        if (requestAnimationFrameModule.runCounter >= requestAnimationFrameModule.frameMount) {
            requestAnimationFrameModule.runCounter = 0;
            event.emit(requestAnimationFrameModule.key);
        }
        window.requestAnimationFrame(requestAnimationFrameModule.run);
    },
    stop: () => {
        requestAnimationFrameModule.addCounter = 0;
        requestAnimationFrameModule.isRun = false;
        event.removeListeners(requestAnimationFrameModule.key);
    },
};
const Scrollbar = forwardRef((props, ref) => {
    const { className, isVerticalResident, isWindowsVerticalResident, isWindowsHorizontalResident, ...rest } = props;
    const clsNames = classNames(className, {
        'is-windows': isWindows,
        'is-vertical-resident': isVerticalResident || !!(isWindows && isWindowsVerticalResident),
        'is-horizontal-resident': !!(isWindows && isWindowsHorizontalResident),
    });
    const scrollbarRef = useRef(null);
    const getScrollBarInstance = (instance) => {
        scrollbarRef.current = instance;
        if (typeof ref === 'function') {
            ref(instance);
        }
        else if (ref) {
            ref.current = instance;
        }
    };
    useEffect(() => {
        if (isWindows && (isWindowsHorizontalResident || isWindowsVerticalResident)) {
            requestAnimationFrameModule.add();
            event.on(requestAnimationFrameModule.key, () => {
                if (typeof getSafe(scrollbarRef.current, 'updateScroll') === 'function') {
                    scrollbarRef.current.updateScroll();
                }
            });
            return () => {
                requestAnimationFrameModule.minus();
            };
        }
        return () => null;
    }, []);
    return (<ReactPerfectScrollbar className={clsNames} {...rest} ref={getScrollBarInstance}>
        {props.children}
      </ReactPerfectScrollbar>);
});
Scrollbar.defaultProps = {
    isVerticalResident: false,
    isWindowsHorizontalResident: true,
    isWindowsVerticalResident: true,
};
export default Scrollbar;
