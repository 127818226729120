/**
 * 该路由配置和road.tsx等同，可互相取代。
 * 用于不同入口进入，展示不同的菜单.
 */
import { ProfileOutlined } from '@ant-design/icons';
import preLoadImg from '@src/pages/HR/Performance/depPerformance/RobotTip/preLoadImg';
import { RoadMapType } from '@src/pages/interface';
import { gluer } from 'femo';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
preLoadImg();
const initState = {
    name: 'HR系统',
    path: '/dep-hr',
    permissions: [],
    component: () => <Redirect to='/dep-hr/perf-staff'/>,
    icon: ProfileOutlined,
    subRoads: [{
            name: '绩效管理',
            path: '/perf-staff',
            permissions: [],
            component: lazy(() => import('./Performance/depPerformance')),
        }, {
            name: '绩效评估',
            path: '/eval-staff',
            permissions: [],
            component: lazy(() => import('./Performance/depEvaluate')),
        }, {
            name: '员工目标反馈追踪',
            path: '/feedback',
            type: RoadMapType.fallen,
            hasHeader: false,
            hasSider: false,
            subRoads: [{
                    name: '',
                    path: '/chase',
                    type: RoadMapType.fallen,
                    component: lazy(() => import('./Performance/LeaderReviewList')),
                }],
        }],
};
export const perfStaffUrl = '/dep-hr/perf-staff';
const road = gluer(initState);
export default road;
