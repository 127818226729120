import React from 'react';
import chatSidePic from '@src/assets/img/chat-side.jpg';
import useDocumentTitle from '@src/hooks/useDocumentTitle';
const ChatSidePage = (_props) => {
    useDocumentTitle('');
    return (<section>
      <section>
        <img style={{ width: '100%', height: 'auto' }} src={chatSidePic}/>
      </section>
    </section>);
};
export default ChatSidePage;
