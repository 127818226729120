import useVisible from '@src/hooks/useVisible';
import { useCallback, useEffect, useRef, useState, } from 'react';
const useDeleteModal = (v = false) => {
    const tmpResult = useVisible(v);
    const [candidateId, updateCandidateId] = useState();
    const flagRef = useRef(false);
    const show = useCallback((cId) => {
        tmpResult.show();
        updateCandidateId(cId);
    }, [tmpResult]);
    const [result, updateResult] = useState(() => ({
        ...tmpResult,
        candidate_id: candidateId,
        show,
    }));
    useEffect(() => {
        if (flagRef.current) {
            updateResult({
                ...tmpResult,
                candidate_id: candidateId,
                show,
            });
        }
        else {
            flagRef.current = true;
        }
    }, [tmpResult, show, candidateId]);
    return result;
};
export default useDeleteModal;
