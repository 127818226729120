import React from 'react';
import { Form } from 'antd';
import SalaryTypeSelect from '@src/pages/components/SalaryTypeSelect';
import { SalaryPaymentKind } from '@src/api/interface';
const SalaryTypeField = (props) => {
    const { disabled, isIntern, setSalaryDisabled, ...restProps } = props;
    return (<Form.Item style={{ marginLeft: '8px' }} {...restProps}>
      <SalaryTypeSelect setSalaryDisabled={setSalaryDisabled} isIntern={isIntern} disabled={disabled} placeholder='请选择'/>
    </Form.Item>);
};
SalaryTypeField.defaultProps = {
    initialValue: SalaryPaymentKind.byDay,
    disabled: false,
    isIntern: false,
};
export default SalaryTypeField;
