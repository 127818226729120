import React, { useEffect, } from 'react';
import { useDerivedState, useModel } from 'femo';
import SelectCheckbox from '@src/pages/components/filter/components/SelectCheckbox';
import companyModel from '../../CompanySelect/model';
import { fetchCompanyList } from '../../CompanySelect/service';
export const getAllCompanyIds = () => companyModel.list().map((t) => t.company_id);
const CompanyCheckbox = (props) => {
    const [teams] = useModel(companyModel.list);
    const [allIds] = useDerivedState(() => teams.map((t) => t.company_id), () => teams.map((t) => t.company_id), [teams]);
    const [data] = useDerivedState(() => teams.map((t) => ({
        ...t,
        id: t.company_id,
        name: t.company_name,
    })), () => teams.map((t) => ({
        ...t,
        id: t.company_id,
        name: t.company_name,
    })), [teams]);
    useEffect(() => {
        fetchCompanyList();
    }, []);
    return (<SelectCheckbox {...props} allIds={allIds} allText='全部主体' data={data}/>);
};
CompanyCheckbox.defaultProps = {
    placeholder: '请选择',
};
export default CompanyCheckbox;
