import React, { useEffect, } from 'react';
import { Select } from 'antd';
import { getSafe } from '@src/tools/util';
import useModel from '@src/hooks/useModel';
import * as service from './service';
import jobModel from './jobListModel';
const InnerJobSelect = (props) => {
    const [list] = useModel(jobModel.jobList);
    useEffect(() => {
        if (list.length === 0) {
            service.getJobList();
        }
    }, []);
    return (<Select placeholder='请选择' optionFilterProp='label' showSearch {...props}>
      {list.map((cat) => {
            const jobs = getSafe(cat, 'jobs') || [];
            return jobs.map((job) => (<Select.Option key={getSafe(job, 'job_id')} value={getSafe(job, 'job_id')} label={getSafe(job, 'job_name')}>
                {getSafe(job, 'job_name')}
              </Select.Option>));
        })}
    </Select>);
};
export default InnerJobSelect;
