import React from 'react';
import { Form } from 'antd';
import StaffTypeSelect from '@src/pages/components/StaffTypeSelect';
import { StaffType } from '@src/api/interface';
const StaffTypeField = (props) => {
    const { typeFilter, disabled, exact, canSelectParent, ...restProps } = props;
    return (<Form.Item label='员工类型' initialValue={StaffType.practice} {...restProps}>
      <StaffTypeSelect canSelectParent={canSelectParent} disabled={disabled} typeFilter={typeFilter} placeholder='请选择' exact={exact}/>
    </Form.Item>);
};
StaffTypeField.defaultProps = {
    exact: true,
    canSelectParent: false,
};
export default StaffTypeField;
