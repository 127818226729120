import React from 'react';
import { Checkbox, Form, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import style from './style.less';
const CustomCheckBox = (props) => (<>
      <Checkbox {...props}/>
      <label htmlFor='need_probation' style={{ marginLeft: '8px', marginRight: '4px' }}>加入试用期流程</label>
      <Popover title={null} arrowPointAtCenter content={<p>
            试用期流程指产研员工正常入职需要经历的评估流程，您可勾选该选项使添加员工加入该流程中。
            （未加入试用期流程的员工，转正时间到达后自动置为转正状态）
          </p>} placement='topRight'>
        <QuestionCircleOutlined className={style.question}/>
      </Popover>
    </>);
const NeedProbation = (props) => (<Form.Item label='' valuePropName='checked' initialValue={false} {...props}>
      <CustomCheckBox />
    </Form.Item>);
export default NeedProbation;
