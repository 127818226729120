import { Redirect } from 'react-router-dom';
import { RobotOutlined } from '@ant-design/icons';
import { gluer } from 'femo';
import { lazy } from 'react';
import { User } from '@src/api/interface';
const initRoad = {
    name: '行政系统',
    path: '/administration',
    permissions: [User.ModuleAuthKey.module_administration],
    component: () => <Redirect to='/administration/board-sync'/>,
    icon: RobotOutlined,
    subRoads: [{
            name: '入职同步',
            path: '/board-sync',
            permissions: [User.ModuleAdministrationSubAuthKey.menu_administration_induction_synchronize],
            component: lazy(() => import('./BoardSync')),
        }, {
            name: '第三方账号',
            path: '/3rd-account',
            permissions: [User.ModuleAdministrationSubAuthKey.menu_administration_thirdparty_account],
            component: lazy(() => import('./ThirdPartyAccount')),
        }],
};
const road = gluer(initRoad);
export default road;
