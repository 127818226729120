import React from 'react';
import LogoSvg from '@src/assets/svg/logo.svg';
import useModel from '@src/hooks/useModel';
import userInfo from '@src/model/user';
import { getSafe } from '@src/tools/util';
import useDocumentTitle from '@src/hooks/useDocumentTitle';
import style from './style.less';
const Welcome = (_props) => {
    const [user] = useModel(userInfo.info);
    const name = getSafe(user, 'user_info.name');
    useDocumentTitle('欢迎', 300);
    return (<section className={style.welcome}>
      <section className='logo'>
        <LogoSvg />
      </section>
      <section className='text'>{name}，欢迎使用MIS系统</section>
    </section>);
};
export default Welcome;
