import { isEmpty } from '@src/tools/util';
export const formatOrderBy = (orderBy) => {
    if (orderBy === 'asc' || orderBy === 'desc')
        return orderBy;
    return 'desc';
};
export const orderByToTableSorter = (orderBy) => {
    if (orderBy === 'asc' || orderBy === 'desc')
        return `${orderBy}end`;
    return 'descend';
};
export const tableSorterToOrderBy = (sorter) => {
    if (sorter === 'ascend' || sorter === 'descend')
        return sorter.replace('end', '');
    return 'desc';
};
export const switchOrderBy = (orderBy) => {
    if (orderBy === 'asc')
        return 'desc';
    if (orderBy === 'desc')
        return 'asc';
    return 'desc';
};
export const arrToString = (arr) => (arr || []).filter((item) => !isEmpty(item)).join(',');
