import React, { useState } from 'react';
import { Select } from 'antd';
var Banks;
(function (Banks) {
    Banks["merchantsBank"] = "\u62DB\u5546\u94F6\u884C";
    Banks["constructionBank"] = "\u5EFA\u8BBE\u94F6\u884C";
    Banks["commercialBank"] = "\u5DE5\u5546\u94F6\u884C";
    Banks["agriculturalBank"] = "\u519C\u4E1A\u94F6\u884C";
    Banks["postalBank"] = "\u90AE\u653F\u50A8\u84C4\u94F6\u884C";
    Banks["communicationsBank"] = "\u4EA4\u901A\u94F6\u884C";
    Banks["chinaBank"] = "\u4E2D\u56FD\u94F6\u884C";
})(Banks || (Banks = {}));
export var BankShortCutToC;
(function (BankShortCutToC) {
    BankShortCutToC["CMB"] = "\u62DB\u5546\u94F6\u884C";
    BankShortCutToC["CCB"] = "\u5EFA\u8BBE\u94F6\u884C";
    BankShortCutToC["ICB"] = "\u5DE5\u5546\u94F6\u884C";
    BankShortCutToC["ABC"] = "\u519C\u4E1A\u94F6\u884C";
    BankShortCutToC["PSB"] = "\u90AE\u653F\u50A8\u84C4\u94F6\u884C";
    BankShortCutToC["COMM"] = "\u4EA4\u901A\u94F6\u884C";
    BankShortCutToC["BOC"] = "\u4E2D\u56FD\u94F6\u884C";
})(BankShortCutToC || (BankShortCutToC = {}));
export var BankShortCut;
(function (BankShortCut) {
    BankShortCut["CMB"] = "CMB";
    BankShortCut["CCB"] = "CCB";
    BankShortCut["ICB"] = "ICB";
    BankShortCut["ABC"] = "ABC";
    BankShortCut["PSB"] = "PSB";
    BankShortCut["COMM"] = "COMM";
    BankShortCut["BOC"] = "BOC";
})(BankShortCut || (BankShortCut = {}));
export const bankShortcut = {
    [Banks.merchantsBank]: BankShortCut.CMB,
    [Banks.constructionBank]: BankShortCut.CCB,
    [Banks.commercialBank]: BankShortCut.ICB,
    [Banks.agriculturalBank]: BankShortCut.ABC,
    [Banks.postalBank]: BankShortCut.PSB,
    [Banks.communicationsBank]: BankShortCut.COMM,
    [Banks.chinaBank]: BankShortCut.BOC,
};
export const bankFull = {
    [BankShortCut.CMB]: Banks.merchantsBank,
    [BankShortCut.CCB]: Banks.constructionBank,
    [BankShortCut.ICB]: Banks.commercialBank,
    [BankShortCut.ABC]: Banks.agriculturalBank,
    [BankShortCut.PSB]: Banks.postalBank,
    [BankShortCut.COMM]: Banks.communicationsBank,
    [BankShortCut.BOC]: Banks.chinaBank,
};
export const banks = [{
        id: Banks.merchantsBank,
        name: Banks.merchantsBank,
    }, {
        id: Banks.constructionBank,
        name: Banks.constructionBank,
    }, {
        id: Banks.commercialBank,
        name: Banks.commercialBank,
    }, {
        id: Banks.agriculturalBank,
        name: Banks.agriculturalBank,
    }, {
        id: Banks.postalBank,
        name: Banks.postalBank,
    }, {
        id: Banks.communicationsBank,
        name: Banks.communicationsBank,
    }, {
        id: Banks.chinaBank,
        name: Banks.chinaBank,
    }];
const BankSelect = (props) => {
    const [options] = useState(() => banks.map((b) => ({
        value: b.id,
        label: b.name,
    })));
    return (<Select {...props} options={options}/>);
};
export default BankSelect;
