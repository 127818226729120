import RouteRenderComponent from '@src/components/Routes/RouteRenderComponent';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { road404 } from '@src/pages/roadMap';
import CrashPage from '@src/components/Crash';
import { useDerivedState } from 'femo';
const Routes = (props) => {
    const { routes, road404: propRoad404 } = props;
    const [curContext] = useState(() => ({
        keyPaths: [],
        routeComponents: [],
    }));
    const getPermittedRoutes = (roads) => {
        roads.map((item) => {
            if (item.component) {
                const obj = {
                    ...item,
                };
                curContext.keyPaths.push(obj);
            }
            if (item.subRoads && item.subRoads.length !== 0) {
                getPermittedRoutes(item.subRoads);
            }
        });
    };
    // 生成最终的路由组件数组
    const renderRoutes = (notFoundRoad) => {
        // fallbackRoad和404作为兜底
        // curContext.keyPaths.push(/* fallbackRoad, */road404);
        const finalRoutes = curContext.keyPaths.map((route) => {
            if (route.access === false) {
                // 默认使用通用的404
                if (!route.fallback) {
                    return null;
                }
                // 否则使用自定义的fallback逻辑
                return (<Route key={route.completePath} exact path={route.completePath} render={(props) => {
                        const result = route.fallback(props);
                        return (<CrashPage>
                  {result || null}
                </CrashPage>);
                    }}/>);
            }
            return <Route key={route.completePath} exact path={route.completePath} render={(props) => <RouteRenderComponent {...props} road={route}/>}/>;
        });
        const final404 = notFoundRoad || road404;
        const route404 = <Route key="404" path={final404.path} component={final404.component}/>;
        curContext.routeComponents = [...finalRoutes, route404];
    };
    useDerivedState(() => {
        curContext.keyPaths = [];
        getPermittedRoutes(routes);
        renderRoutes(propRoad404);
    }, [routes]);
    return <Switch>{curContext.routeComponents}</Switch>;
};
export default Routes;
