import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
const TimeEnd = (props) => {
    const { form, startName, disabled, errorMsg, disabledDate, showToday, ...restProps } = props;
    const [rules] = useState(() => ([{
            validator: (_rule, value) => {
                if (value) {
                    const contractStartUt = form.getFieldValue(startName);
                    if (contractStartUt) {
                        const maxStartUt = moment(contractStartUt).endOf('day').valueOf();
                        const minEndUt = moment(value).startOf('day').valueOf();
                        if (maxStartUt >= minEndUt) {
                            return Promise.reject(errorMsg);
                        }
                        form.setFields([{
                                name: startName,
                                errors: [],
                                touched: false,
                                validating: false,
                                value: contractStartUt,
                            }]);
                    }
                }
                return Promise.resolve();
            },
        }]));
    return (<Form.Item validateFirst {...restProps} rules={restProps.rules ? [...restProps.rules, ...rules] : rules}>
      <DatePicker disabledDate={disabledDate} disabled={disabled} showToday={showToday} placeholder='请选择'/>
    </Form.Item>);
};
TimeEnd.defaultProps = {
    label: '合同结束',
    disabled: false,
    errorMsg: '合同结束时间必须大于合同开始时间',
    showToday: true,
};
export default TimeEnd;
