import NotFound from '@src/components/NotFound';
import { RoadMapType } from '@src/pages/interface';
import { gluer } from 'femo';
import React from 'react';
// 这里的 key 不重要，切记
// 操作的是直接提取值成数组 extractPagesRoadMapAsArray
const roadMap = gluer({});
// 处理flatRoadMap的方法
const completeFn = (roads, path = [], parent = null) => {
    let hasLivingRoadInSubRoads = false;
    const result = roads.map((item) => {
        const tempItem = {
            ...item,
        };
        path.push(tempItem.path);
        const keyPath = path.join('');
        // mutable方式增加parent，便于通过匹配的精确路由回溯
        tempItem.parent = parent;
        const { hasSider, hasHeader, fallback, type, } = tempItem;
        if (typeof hasSider !== 'boolean') {
            tempItem.hasSider = parent?.hasSider ?? true;
        }
        if (typeof hasHeader !== 'boolean') {
            tempItem.hasHeader = parent?.hasHeader ?? true;
        }
        if (typeof fallback !== 'function') {
            tempItem.fallback = parent?.fallback;
        }
        // 设置默认值为 living
        if (type !== RoadMapType.living && type !== RoadMapType.fallen) {
            tempItem.type = parent?.type ?? RoadMapType.living;
        }
        if (!hasLivingRoadInSubRoads && tempItem.type === RoadMapType.living) {
            hasLivingRoadInSubRoads = true;
        }
        tempItem.completePath = keyPath;
        if (tempItem.subRoads && tempItem.subRoads.length !== 0) {
            tempItem.subRoads = completeFn(tempItem.subRoads, path, tempItem);
        }
        path.pop();
        return tempItem;
    });
    if (parent) {
        parent.hasLivingRoadInSubRoads = hasLivingRoadInSubRoads;
    }
    return result;
};
// 扁平的RoadMap，是roadMap的缓存
// 不要直接更新这个flatRoadMap
// 请更新roadMap来达到更新flagRoadMap的目的
export const flatRoadMap = gluer([]);
flatRoadMap.relyOn([roadMap], (result) => completeFn(Object.values(result[0])));
// 作为兜底的路由配置
// 将所有路由重定向到
export const road404 = {
    name: '404',
    path: '*',
    component: () => <NotFound />,
    permissions: [], // 无权限要求
};
export default roadMap;
