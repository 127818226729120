import { getSafe, isEmpty } from '@src/tools/util';
import { message } from 'antd';
export const findNode = (nodes, nodeType) => (nodes || []).find((node) => node.configId === nodeType);
export const findEventData = (nodes, nodeType, eventType, operatorId, operator) => {
    let targetEventData = null;
    const node = findNode(nodes, nodeType);
    if (!node) {
        return null;
    }
    (node.events || []).find((event) => event.configId === eventType && (getSafe(event, 'dataList') || []).find((data) => {
        // 这里目前为止 dataList的owner的角色不会重复
        let flag = false;
        const ownerStaffId = getSafe(data, 'ownerStaffId');
        // 有外部角色传入，则进一步比较id
        if (operator && data.owner === operator) {
            // 如果传入的id不存在，则表明不用比较
            // 如果event data里面的id不存在，也不用比较
            flag = isEmpty(operatorId) || isEmpty(ownerStaffId) || operatorId === ownerStaffId;
        }
        else if (isEmpty(operator) && !isEmpty(operatorId)) {
            // 如果没有外部角色传入，则表明只需要判断id
            flag = operatorId === ownerStaffId;
        }
        if (flag) {
            targetEventData = data;
        }
        return flag;
    }));
    return targetEventData;
};
export const extractOwnerStatus = (stage, nodeType, eventType, ownerStaffId, ownerType) => {
    // const stageStatus: Probation.Status = getSafe(stage, 'status');
    // 判断阶段的状态
    // if (stageStatus !== Probation.Status.START) return stageStatus;
    const nodes = getSafe(stage, 'nodes') || [];
    const targetNode = nodes.find((node) => getSafe(node, 'configId') === nodeType);
    // const nodeStatus: Probation.Status = getSafe(targetNode, 'status');
    // 判断节点的状态
    // if (nodeStatus !== Probation.Status.START) return nodeStatus;
    const events = getSafe(targetNode, 'events') || [];
    const targetEvent = events.find((event) => getSafe(event, 'configId') === eventType);
    const dataList = getSafe(targetEvent, 'dataList') || [];
    if (!(ownerType instanceof Array)) {
        const targetData = dataList.find((data) => {
            const tempStaffId = getSafe(data, 'ownerStaffId');
            const ot = getSafe(data, 'owner');
            // 有外部角色传入，则表明需要判断角色
            if (ownerType && ownerType === ot) {
                // 如果event data里面staff id为空，则表明不需要特定的人，只要角色匹配即可
                return (isEmpty(tempStaffId)
                    || isEmpty(ownerStaffId)
                    || (!isEmpty(ownerStaffId) && tempStaffId === ownerStaffId));
            }
            // 如果没有外部角色传入，则表明只需要判断id
            if (!isEmpty(ownerStaffId) && isEmpty(ownerType)) {
                return (ownerStaffId === tempStaffId);
            }
            return false;
        });
        // 用户的操作对应的是事件
        // 直接返回事件的状态
        return getSafe(targetData, 'status');
    }
    const result = {};
    return dataList.reduce((pre, cur) => {
        const tempStaffId = getSafe(cur, 'ownerStaffId');
        const ot = getSafe(cur, 'owner');
        ownerType.forEach((type) => {
            const flag = type === ot && ((!isEmpty(ownerStaffId) && tempStaffId === ownerStaffId) || isEmpty(tempStaffId));
            if (flag) {
                pre[type] = getSafe(cur, 'status');
            }
        });
        return pre;
    }, result);
};
export const scrollToFirstErrorField = (err, form) => {
    if (form) {
        const errorFields = getSafe(err, 'errorFields') || [];
        const names = getSafe(errorFields, '[0].name');
        message.error('完善表单项');
        form.scrollToField(names);
    }
};
