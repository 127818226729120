import React from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import Scrollbar from '@src/components/Scrollbar';
import style from './style.less';
const detectContent = (containerDom) => {
    if (!containerDom)
        return null;
    const { scrollTop, clientHeight, scrollHeight } = containerDom;
    if (scrollTop !== 0) {
        // 到底了
        if (clientHeight + scrollTop >= scrollHeight) {
            return {
                topActive: true,
                bottomActive: false,
            };
        }
        return {
            topActive: true,
            bottomActive: true,
        };
    }
    if (clientHeight >= scrollHeight) {
        return {
            topActive: false,
            bottomActive: false,
        };
    }
    if (clientHeight < scrollHeight) {
        return {
            topActive: false,
            bottomActive: true,
        };
    }
    return null;
};
class EnhancedModal extends React.Component {
    constructor() {
        super(...arguments);
        this.containerDom = null;
        this.state = {
            loading: false,
            topActive: false,
            bottomActive: false,
        };
        this.handleYScroll = () => {
            this.applyDetectResult();
        };
        this.applyDetectResult = () => {
            const result = detectContent(this.containerDom);
            if (result) {
                this.setState(result);
            }
        };
        this.getContainerDom = (container) => {
            this.containerDom = container;
            if (this.props.getScrollDom) {
                this.props.getScrollDom(container);
            }
            this.applyDetectResult();
            if (this.containerDom) {
                // @ts-ignore
                const MutationObserver = window.MutationObserver || window.webkitMutationObserver || window.MozMutationObserver;
                if (MutationObserver) {
                    this.mutationObserver = new MutationObserver(() => {
                        this.applyDetectResult();
                    });
                    this.mutationObserver.observe(this.containerDom, {
                        childList: true,
                        subtree: true,
                    });
                }
            }
            else if (this.mutationObserver) {
                this.mutationObserver.disconnect();
            }
        };
        this.renderContent = () => (<Scrollbar containerRef={this.getContainerDom} onScrollY={this.handleYScroll}>
      {this.props.children}
    </Scrollbar>);
    }
    render() {
        const { wrapClassName, ...restProps } = this.props;
        const newWrapClassName = classNames(style.enhancedModal, wrapClassName, 'ant-enhanced-modal', {
            'ant-enhanced-modal-top-active': this.state.topActive,
            'ant-enhanced-modal-bottom-active': this.state.bottomActive,
        });
        return (<Modal centered {...restProps} wrapClassName={newWrapClassName}>
        {this.renderContent()}
      </Modal>);
    }
}
EnhancedModal.defaultProps = {
    visible: false,
    maskClosable: false,
};
export default EnhancedModal;
