import React, { useState, } from 'react';
import SelectCheckbox from '@src/pages/components/filter/components/SelectCheckbox';
export var OfferStatus;
(function (OfferStatus) {
    OfferStatus[OfferStatus["todo"] = 100] = "todo";
    OfferStatus[OfferStatus["done"] = 200] = "done";
    OfferStatus[OfferStatus["invalid"] = 300] = "invalid";
})(OfferStatus || (OfferStatus = {}));
export const offerStatusNames = {
    [OfferStatus.todo]: '待入职',
    [OfferStatus.done]: '已入职',
    [OfferStatus.invalid]: '已离职',
};
const offerStatus = [{
        id: OfferStatus.todo,
        name: offerStatusNames[OfferStatus.todo],
    }, {
        id: OfferStatus.done,
        name: offerStatusNames[OfferStatus.done],
    }, {
        id: OfferStatus.invalid,
        name: offerStatusNames[OfferStatus.invalid],
    }];
const StaffStatusCheckbox = (props) => {
    const [allIds] = useState(() => offerStatus.map((t) => t.id));
    return (<SelectCheckbox {...props} allIds={allIds} allText='全部' data={offerStatus}/>);
};
StaffStatusCheckbox.defaultProps = {
    placeholder: '请选择',
};
export default StaffStatusCheckbox;
