import React from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Popover, Space } from 'antd';
import style from './style.less';
const WorkTypeColumnTitle = (_props) => (<span className={style.title}>
      <Space>
        <span>岗位序列</span>
        <Popover placement='topRight' arrowPointAtCenter content={<section style={{ maxWidth: '200px' }}>
              该序列值为临时解决方案，不建议长期使用
            </section>}>
          <QuestionCircleFilled />
        </Popover>
      </Space>
    </span>);
export default WorkTypeColumnTitle;
