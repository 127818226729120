import { currentExactMatchRoad } from '@src/components/Routes/roadMapDerivedModel';
import React, { useCallback, } from 'react';
import { withRouter } from 'react-router-dom';
import { useDerivedState, useModel } from 'femo';
import useDocumentTitle from '@src/hooks/useDocumentTitle';
import { flatArr, getSafe, queryToObject, variablePlaceholderReplace, } from '@src/tools/util';
import { applyListUrl } from '@src/pages/HR/Recruit/urls';
import MisHeader from './index';
const HeaderControl = (props) => {
    const pathName = getSafe(props, 'location.pathname') || '';
    const [currentRoadMap] = useModel(currentExactMatchRoad);
    const [breadcrumbData] = useDerivedState(() => {
        const breadcrumbs = [];
        let current = currentRoadMap;
        let tempPath = '';
        let tempName = '';
        while (current) {
            tempName = current.name;
            tempPath = `${current.realPath || current.completePath}${props.location.search}`;
            if (typeof tempName === 'string') {
                const queryObj = queryToObject(props.location.search, {}, false);
                tempName = variablePlaceholderReplace(tempName, queryObj);
            }
            if (!tempName) {
                current = current.parent;
                continue;
            }
            let index = -1;
            // eslint-disable-next-line no-loop-func
            breadcrumbs.find((bread, i) => {
                const flag = bread.completePath === tempPath;
                if (flag) {
                    index = i;
                }
                return flag;
            });
            if (index !== -1) {
                const value = breadcrumbs[index];
                if (!(value.name instanceof Array)) {
                    value.name = [value.name, tempName];
                }
                else {
                    value.name = [...(value.name), tempName];
                }
            }
            else {
                breadcrumbs.unshift({
                    completePath: tempPath,
                    name: tempName,
                });
            }
            current = current.parent;
        }
        return breadcrumbs;
    }, [currentRoadMap]);
    const getTitle = useCallback(() => {
        // 如果是 我发起的 我审批的 列表则显示简单的 title
        if (pathName.startsWith(applyListUrl(null))) {
            return '招聘申请';
        }
        return flatArr(breadcrumbData.map((b) => b.name)).join('-');
    }, [breadcrumbData, pathName]);
    useDocumentTitle(getTitle);
    return currentRoadMap?.hasHeader ? <MisHeader breadcrumbNameMap={breadcrumbData} {...props}/> : null;
};
export default withRouter(HeaderControl);
