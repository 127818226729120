import { gluer } from 'femo';
const initOfferList = {
    offer_list: [],
    total_count: 0,
};
export const offerList = gluer(initOfferList);
const initQuitList = {
    offer_list: [],
    total_count: 0,
};
export const quitList = gluer(initQuitList);
