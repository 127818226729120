import { useCallback, useEffect, useState } from 'react';
const useNewStaffActions = () => {
    const [visible, updateVisible] = useState(false);
    const [orgId, updateOrgId] = useState();
    const show = useCallback((orId) => {
        updateVisible(true);
        updateOrgId(orId);
    }, []);
    const close = useCallback(() => {
        updateVisible(false);
    }, []);
    const [result, updateResult] = useState(() => ({
        show,
        close,
        visible,
        orgId,
    }));
    useEffect(() => {
        updateResult({
            show,
            close,
            visible,
            orgId,
        });
    }, [visible, show, close, orgId]);
    return result;
};
export default useNewStaffActions;
