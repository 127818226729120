import React, { useState, } from 'react';
import SelectCheckbox from '@src/pages/components/filter/components/SelectCheckbox';
export var PayStatus;
(function (PayStatus) {
    PayStatus[PayStatus["notPay"] = 0] = "notPay";
    PayStatus[PayStatus["paying"] = 100] = "paying";
    PayStatus[PayStatus["payed"] = 200] = "payed";
})(PayStatus || (PayStatus = {}));
export const payStatusNames = {
    [PayStatus.notPay]: '未发放',
    [PayStatus.payed]: '已发放',
    [PayStatus.paying]: '未发放',
};
const issueStatus = [{
        id: PayStatus.notPay,
        name: payStatusNames[PayStatus.notPay],
    }, {
        id: PayStatus.payed,
        name: payStatusNames[PayStatus.payed],
    }];
const IssueStatusCheckbox = (props) => {
    const [allIds] = useState(() => issueStatus.map((item) => item.id));
    return (<SelectCheckbox {...props} allIds={allIds} allText='全部' data={issueStatus}/>);
};
IssueStatusCheckbox.defaultProps = {
    placeholder: '请选择',
};
export default IssueStatusCheckbox;
