import React from 'react';
import { flatRoadMap } from '@src/pages/roadMap';
import { useModel } from 'femo';
import { currentExactMatchRoad } from '@src/components/Routes/roadMapDerivedModel';
import LeftSider from './index';
const SiderControl = () => {
    const [sider] = useModel(flatRoadMap);
    const [currentRoad] = useModel(currentExactMatchRoad);
    return (currentRoad?.hasSider ? (<LeftSider currentRoad={currentRoad} sider={sider}/>) : null);
};
export default SiderControl;
