import React, { useCallback, useEffect, useState, } from 'react';
import { Form, } from 'antd';
import CustomerTypeFilterField from '@src/pages/components/filter/fields/CustomerTypeFilterField';
import fieldNames from '@src/pages/HR/Offer/filter/fieldNames';
import { getSafe, isArrayEmpty } from '@src/tools/util';
import CompanyFilterField from '@src/pages/components/filter/fields/CompanyFilterField';
import StaffStatusFilterField from '@src/pages/components/filter/fields/StaffStatusFilterField';
import ChannelFilterField from '@src/pages/components/filter/fields/ChannelFilterField';
import TimeFilterField from '@src/pages/components/filter/fields/TimeFilterField';
import { StaffType } from '@src/api/interface';
import { getStaffTypes } from '@src/pages/components/StaffTypeSelect';
import { securityCrash } from '@src/components/HOC';
import FilterButtons from '@src/pages/components/filter/FilterButtons';
import CreatedByFilterField from '@src/pages/components/filter/fields/CreatedByFilterField';
import NameFilterField from '@src/pages/components/filter/fields/TextFilterField';
const ListFilterForm = (props) => {
    const { onSubmit, 
    // team,
    staffType, company, staffStatus, channel, createdTime, boardingTime, createdBy, quitTime, listType, from, name, } = props;
    const [form] = Form.useForm();
    const isQuitList = listType === 'quit';
    const filterValues = useCallback((vs) => {
        const result = {};
        const staffType = vs.staff_type.value;
        // const team = vs.team.value;
        const company = vs.company.value;
        const channel = vs.channel.value;
        const createdTime = vs.created_time.value;
        const createdBy = vs.created_by.value;
        const boardingTime = vs.boarding_time.value;
        const name = vs.name.value;
        const quitTime = getSafe(vs, 'quit_time.value');
        let tmpSt = [];
        if (!isArrayEmpty(staffType)) {
            tmpSt = staffType;
        }
        // let tmpTid = [];
        // if (!isArrayEmpty(team)) {
        //   tmpTid = team;
        // }
        let tmpCp = [];
        if (!isArrayEmpty(company)) {
            tmpCp = company;
        }
        let tmpCh = [];
        if (!isArrayEmpty(channel)) {
            tmpCh = channel;
        }
        let tmpCt = [];
        if (!isArrayEmpty(createdTime)) {
            tmpCt = createdTime;
        }
        let tmpCb = [];
        if (!isArrayEmpty(createdBy)) {
            tmpCb = createdBy;
        }
        let tmpQt = [];
        if (!isArrayEmpty(quitTime)) {
            tmpQt = quitTime;
        }
        let tmpBt = [];
        if (!isArrayEmpty(boardingTime)) {
            tmpBt = boardingTime;
        }
        let tmpN = [];
        if (!isArrayEmpty(name)) {
            tmpN = name;
        }
        // 员工类型
        result.s_type = tmpSt;
        result.s_t_cond = vs.staff_type.condition;
        // 入职团队
        // result.t_id = tmpTid;
        //   result.t_id_cond = vs.team.condition;
        // 公司主体
        result.c_id = tmpCp;
        result.c_id_cond = vs.company.condition;
        // 申请渠道
        result.channel = tmpCh;
        result.c_cond = vs.channel.condition;
        // 创建时间
        result.t_crt = tmpCt;
        result.t_c_cond = vs.created_time.condition;
        // 创建人
        result.c_by = tmpCb;
        result.c_by_cond = vs.created_by.condition;
        // 入职时间
        result.b_t = tmpBt;
        result.b_t_cond = vs.boarding_time.condition;
        // 姓名
        result.name = tmpN;
        result.name_cond = vs.name.condition;
        if (isQuitList) {
            // 毁约时间
            result.t_dst = tmpQt;
            result.t_d_cond = vs.quit_time.condition;
        }
        else {
            const staffStatus = vs.staff_status.value;
            let tmpSs = [];
            if (!isArrayEmpty(staffStatus)) {
                tmpSs = staffStatus;
            }
            // 员工状态
            result.s_status = tmpSs;
            result.s_s_cond = vs.staff_status.condition;
        }
        return result;
    }, []);
    const onSubmitClick = useCallback(() => {
        const values = form.getFieldsValue();
        const tmpValues = filterValues({ ...values });
        if (onSubmit) {
            onSubmit(tmpValues);
        }
    }, [onSubmit, filterValues]);
    const deps = [staffType, company, staffStatus, channel, createdTime, createdBy, boardingTime, name];
    if (isQuitList) {
        deps.push(quitTime);
    }
    useEffect(() => {
        const fields = {
            [fieldNames.staff_type]: {
                ...staffType,
                value: isArrayEmpty(staffType.value) ? [] : (staffType.value || []).map((s) => Number(s)),
            },
            // [fieldNames.team]: {
            //   ...team,
            //   value: isArrayEmpty(team.value) ? [] : (team.value || []).map((t) => Number(t)),
            // },
            [fieldNames.company]: {
                ...company,
                value: isArrayEmpty(company.value) ? [] : (company.value || []).map((c) => Number(c)),
            },
            [fieldNames.channel]: {
                ...channel,
                value: isArrayEmpty(channel.value) ? [] : (channel.value || []).map((c) => Number(c)),
            },
            [fieldNames.created_time]: {
                ...createdTime,
                value: isArrayEmpty(createdTime.value) ? [] : createdTime.value,
            },
            [fieldNames.created_by]: {
                ...createdBy,
                value: isArrayEmpty(createdBy.value) ? [] : (createdBy.value || []).map((c) => Number(c)),
            },
            [fieldNames.boarding_time]: {
                ...boardingTime,
                value: isArrayEmpty(boardingTime.value) ? [] : boardingTime.value,
            },
            [fieldNames.name]: {
                ...name,
                value: isArrayEmpty(name.value) ? [] : name.value,
            },
        };
        if (isQuitList) {
            fields[fieldNames.quit_time] = {
                ...quitTime,
                value: isArrayEmpty(quitTime.value) ? [] : quitTime.value,
            };
        }
        else {
            fields[fieldNames.staff_status] = {
                ...staffStatus,
                value: isArrayEmpty(staffStatus.value) ? [] : (staffStatus.value || []).map((c) => Number(c)),
            };
        }
        form.setFieldsValue(fields);
    }, [...deps]);
    const [disabledTypes] = useState(() => {
        const types = [];
        const arr = getStaffTypes();
        const tmpArr = [arr];
        while (tmpArr.length) {
            const cur = tmpArr[0];
            for (let i = 0; i < cur.length; i += 1) {
                const item = cur[i];
                const { id, children } = item;
                if (from === 'offer2') {
                    if (id === StaffType.regular || id === StaffType.regularSummary || id === StaffType.regularPractice) {
                        types.push(id);
                    }
                }
                else if (id !== StaffType.all && id !== StaffType.regularSummary && id !== StaffType.regular && id !== StaffType.regularPractice) {
                    types.push(id);
                }
                if (children && children.length) {
                    tmpArr.push(children);
                }
            }
            tmpArr.shift();
        }
        return types;
    });
    return (<Form form={form} layout='horizontal'>
      <NameFilterField name={fieldNames.name}/>
      <CustomerTypeFilterField spreadAll disabledTypes={disabledTypes} name={fieldNames.staff_type}/>
      <CompanyFilterField name={fieldNames.company}/>
       {/* <TeamFilterField name={fieldNames.team} /> */}
      {isQuitList ? null : <StaffStatusFilterField name={fieldNames.staff_status}/>}
      <ChannelFilterField name={fieldNames.channel}/>
      <CreatedByFilterField name={fieldNames.created_by}/>
      <TimeFilterField label='创建时间' name={fieldNames.created_time}/>
      {isQuitList ? <TimeFilterField label='毁约时间' name={fieldNames.quit_time}/> : null}
      <TimeFilterField label='入职时间' name={fieldNames.boarding_time}/>
      <FilterButtons onSubmit={onSubmitClick}/>
    </Form>);
};
ListFilterForm.defaultProps = {
    listType: 'offer',
    from: 'offer',
};
export default securityCrash(ListFilterForm);
