import React, { useCallback, useEffect, } from 'react';
import EnhancedModal from '@src/components/EnhancedModal';
import { Button, Space, Form, InputNumber, Input, message, } from 'antd';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
import { scrollToFirstErrorField } from '@src/pages/HR/Operation/Probation/commonLogic';
import { offer } from '@src/api/actions';
import { getSafe } from '@src/tools/util';
const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
const fieldNames = {
    offerSalary: 'offer_salary',
    remark: 'remark',
};
const apiError = 'api error';
const CustomerInput = (props) => (<>
      <InputNumber min={1} autoComplete='off' placeholder='请输入' {...props}/><span style={{ marginLeft: '8px' }}>元/月</span>
    </>);
const SetOfferSalaryModal = (props) => {
    const { result, afterSubmit } = props;
    const [form] = Form.useForm();
    const hide = useCallback(() => {
        result.hide();
    }, [result.hide]);
    const submit = useCallback(() => form.validateFields().then((values) => offer.setOfferSalary({
        candidate_id: getSafe(result, 'record.candidate_id'),
        ...values,
    }).then(() => {
        message.success(result.mode === 'edit' ? '编辑成功' : '设置成功');
        hide();
        if (afterSubmit) {
            afterSubmit();
        }
    }).catch(() => apiError)).catch((err) => {
        if (err !== apiError) {
            scrollToFirstErrorField(err, form);
        }
        return err;
    }), [form, result, hide, afterSubmit]);
    const [confirm, loading] = useRequestWithCallback(submit, false, false);
    useEffect(() => {
        if (result.visible) {
            form.setFieldsValue({
                [fieldNames.offerSalary]: getSafe(result, 'record.offer_salary'),
                [fieldNames.remark]: getSafe(result, 'record.offer_salary_remark'),
            });
        }
        else {
            form.resetFields();
        }
    }, [result.visible]);
    const footer = (<Space>
      <Button onClick={hide}>取消</Button>
      <Button type='primary' loading={loading} onClick={confirm}>确定</Button>
    </Space>);
    const title = result.mode === 'edit' ? '编辑offer薪资' : '设置offer薪资';
    return (<EnhancedModal title={title} visible={result.visible} onCancel={hide} footer={footer}>
      <Form form={form} hideRequiredMark {...layout}>
        <Form.Item label='offer薪资' name={fieldNames.offerSalary} rules={[{
                required: true,
                message: '请输入',
            }]}>
          <CustomerInput />
        </Form.Item>
        <Form.Item label='备注' name={fieldNames.remark} rules={[{
                type: 'string',
                max: 500,
                message: '最多不超过500字',
            }]}>
          <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} placeholder='选填'/>
        </Form.Item>
      </Form>
    </EnhancedModal>);
};
export default SetOfferSalaryModal;
