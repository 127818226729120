import React, { useCallback } from 'react';
import { Form } from 'antd';
import ConditionSelect, { conditions_a } from '@src/pages/components/ConditionSelect';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import CompanyCheckbox from '@src/pages/components/filter/CompanyCheckbox';
const CompanyFilterField = (props) => {
    const renderCondition = useCallback((_v, condition, onChange) => (<ConditionSelect conditions={conditions_a} value={condition} onChange={onChange}/>), []);
    const renderValue = useCallback((value, _c, onChange) => (<CompanyCheckbox value={value} onChange={onChange}/>), []);
    return (<Form.Item label='公司主体' {...props} {...fieldLayout}>
      <FilterFieldCompose renderCondition={renderCondition} renderValue={renderValue}/>
    </Form.Item>);
};
export default CompanyFilterField;
