import React from 'react';
import { getSafe } from '@src/tools/util';
import { User } from '@src/api/interface';
import { offerSetInternSalaryHiddenFlagApi } from '@src/pages/HR/Offer2/NotRegular/service';
import { useModel } from 'femo';
import permission from '@src/model/permission';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const SetSalaryHidden = (props) => {
    const { record, replace } = props;
    const [perm] = useModel(permission.permission);
    const isSalaryHidden = getSafe(record, 'intern_salary_hidden_flag');
    const [setSalary, loading] = useRequestWithCallback(offerSetInternSalaryHiddenFlagApi, false, false);
    if (loading)
        return <Spin indicator={<LoadingOutlined spin/>}/>;
    return getSafe(perm, `${User.ModuleHRSubAuthKey.function_hr_offer_intern_salary_hidden_manage}`)
        ? <a onClick={(e) => {
                e.stopPropagation();
                e.cancelable = true;
                Modal.confirm({
                    title: '提示',
                    content: `目前薪资数据处于${isSalaryHidden ? '隐藏' : '展示'}状态，是否要${isSalaryHidden ? '展示' : '隐藏'}薪资数据？`,
                    onOk: () => {
                        setSalary({
                            offer_id: getSafe(record, 'candidate_id'),
                            intern_salary_hidden_flag: !isSalaryHidden,
                        }).then(() => {
                            replace({
                                t: Date.now(),
                                p_index: 1,
                            });
                        });
                    },
                });
            }}>{isSalaryHidden ? '显示' : '隐藏'}</a> : null;
};
export default SetSalaryHidden;
