export default {
    staffType: 'customer_type',
    entryTime: 'boarding_time_ut',
    entryTime2: 'entry_time_ut',
    jobName: 'job_name',
    salary_intern: 'salary_intern',
    staffName: 'name',
    staffMobile: 'mobile',
    teamId: 'team_id',
    corpEmail: 'corp_email',
    personal_email: 'personal_email',
    job_id: 'job_id',
    work_place: 'work_place',
    company_id: 'company_id',
    contract_type: 'contract_type',
    salary_intern_type: 'salary_intern_type',
    turn_regular_time_ut: 'turn_regular_time_ut',
    bank_number: 'bank_number',
    contract_start_ut: 'contract_start_ut',
    contract_end_ut: 'contract_end_ut',
    bank_name: 'bank_name',
    probation_salary: 'salary_probation',
    probation_salary_type: 'salary_probation_type',
    regular_salary: 'salary_regular',
    regular_salary_type: 'salary_regular_type',
    id_number: 'id_number',
    need_probation: 'need_probation',
    candidate_id: 'candidate_id',
};
export { getRules } from '@src/pages/HR/Organization/Struct/formUtils';
