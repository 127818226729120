import React from 'react';
import { Layout, Avatar, Breadcrumb, Dropdown, Menu, } from 'antd';
import userModel from '@src/model/user';
import { useModel } from 'femo';
import { getSafe } from '@src/tools/util';
import { Link } from 'react-router-dom';
import Clamp from '@src/components/Clamp';
import apiUrls from '@src/api/urls';
import style from './style.less';
const { Header } = Layout;
const MisHeader = (props) => {
    const [userInfo] = useModel(userModel.info);
    const breadcrumbs = props.breadcrumbNameMap.map((item) => {
        const names = item.name;
        if (names instanceof Array) {
            return names.map((name) => (<Breadcrumb.Item key={name}>
          <Link to={item.completePath}>
            <Clamp>
              {name}
            </Clamp>
          </Link>
        </Breadcrumb.Item>));
        }
        return (<Breadcrumb.Item key={names}>
        <Link to={item.completePath}>
          <Clamp>
            {names}
          </Clamp>
        </Link>
      </Breadcrumb.Item>);
    });
    const overlay = (<Menu>
      <Menu.Item>
        <a href={apiUrls.logout}>退出</a>
      </Menu.Item>
    </Menu>);
    return (<Header className={style.misHeader}>
      {breadcrumbs.length !== 0 ? (<section className='mis-header-breadcrumb'>
          <Breadcrumb>{breadcrumbs}</Breadcrumb>
        </section>) : null}
      <section className='mis-header-placeholder-section'/>
      <Dropdown overlay={overlay}>
        <section className='mis-header-user-info'>
          <Avatar src={getSafe(userInfo, 'user_info.thumbnail')}/>
          <span className='mis-header-user-name'>{getSafe(userInfo, 'user_info.name')}</span>
        </section>
      </Dropdown>
    </Header>);
};
export default MisHeader;
