import React, { useCallback, } from 'react';
import { Checkbox, Col, Row, Select, } from 'antd';
import { useDerivedState, useDerivedStateToModel, useIndividualModel } from 'femo';
import style from './style.less';
const CategorySelectCheckbox = (props) => {
    const { value: propsValue, onChange, extraRef, disabledIds, spreadAll, allText, itemFilter, allFlag, data, ...restProps } = props;
    const makeSomeDisabled = useCallback((disabledIds = [], arr) => {
        const tmpArr = [arr];
        if (!disabledIds || disabledIds.length === 0) {
            return arr;
        }
        while (tmpArr.length) {
            const cur = tmpArr[0];
            for (let i = 0; i < cur.length; i += 1) {
                const item = cur[i];
                if (disabledIds.includes(item.id)) {
                    item.disabled = true;
                }
                if (item.children && item.children.length) {
                    tmpArr.push(item.children);
                }
            }
            tmpArr.shift();
        }
        return arr;
    }, []);
    const genItems = useCallback((dIds, ds) => {
        const list = makeSomeDisabled(dIds, ds);
        if (itemFilter) {
            return itemFilter(list);
        }
        return list;
    }, [makeSomeDisabled, itemFilter]);
    const [items] = useDerivedState(() => genItems(disabledIds, data), () => genItems(disabledIds, data), [data, disabledIds]);
    // 不获取「全部员工」
    const getAllNamesAndIds = useCallback((ds, af) => {
        const names = [];
        const ids = [];
        for (let i = 0; i < ds.length; i += 1) {
            const t = ds[i];
            if (t.id !== af) {
                let arr = [];
                if (t.children) {
                    arr = t.children;
                }
                else {
                    arr = [t];
                }
                arr.forEach((item) => {
                    names.push({
                        label: item.name,
                        key: item.id,
                        value: item.id,
                    });
                    // 未被禁用的才算有效id
                    if (!item.disabled) {
                        ids.push(item.id);
                    }
                });
            }
        }
        return {
            names,
            ids,
        };
    }, []);
    const [allNames] = useDerivedState(() => getAllNamesAndIds(items, allFlag), () => getAllNamesAndIds(items, allFlag), [allFlag, items]);
    const [, valueModel] = useIndividualModel(propsValue || []);
    const [value] = useDerivedStateToModel(props, valueModel, (nextProps, _prevProps, state) => {
        if ('value' in nextProps) {
            let v = nextProps.value || [];
            if (v.includes(allFlag)) {
                v = allNames.ids;
                if (v.length === 0) {
                    v = [allFlag];
                }
                else if (onChange) {
                    // 如果不为空数组，则需要通知给外部真实的数据
                    onChange(v);
                }
            }
            return v;
        }
        return state;
    });
    const isAllChecked = useCallback((selectedIds) => selectedIds.includes(allFlag) || (allNames.ids.length === selectedIds.length && selectedIds.length !== 0 && allNames.ids.every((id) => selectedIds.includes(id))), [allNames, allFlag]);
    const [, allCheckedModel] = useIndividualModel(() => isAllChecked(value));
    const [allChecked] = useDerivedStateToModel(value, allCheckedModel, (nextValue, prevValue, state) => {
        let result = state;
        if (nextValue !== prevValue) {
            result = isAllChecked(nextValue);
        }
        if (extraRef) {
            extraRef.current.all = result;
        }
        return result;
    });
    // 用于回填显示
    const genSelectedValue = useCallback((v) => {
        if (allChecked && !spreadAll) {
            return [{ label: allText, key: allFlag, value: allFlag }];
        }
        let arr = [];
        for (let i = 0; i < items.length; i += 1) {
            const s = items[i];
            let tmpTypes = [];
            if (s.children) {
                tmpTypes = s.children;
            }
            else {
                tmpTypes = [s];
            }
            for (let j = 0; j < tmpTypes.length; j += 1) {
                const tmpS = tmpTypes[j];
                if (v.includes(tmpS.id)) {
                    if (s.id === allFlag) {
                        arr = allNames.names;
                        return arr;
                    }
                    arr.push({
                        key: tmpS.id,
                        label: tmpS.name,
                        value: tmpS.id,
                    });
                }
            }
        }
        return arr;
    }, [allChecked, allText, spreadAll, allNames, allFlag]);
    const [, selectedValueModel] = useIndividualModel(() => genSelectedValue(value));
    const [selectedValue] = useDerivedStateToModel(value, selectedValueModel, (nextValue, prevValue, state) => {
        if (nextValue !== prevValue) {
            return genSelectedValue(nextValue);
        }
        return state;
    });
    const isIndeterminate = useCallback((ids) => !isAllChecked(ids) && ids.length !== 0, [isAllChecked]);
    const [, indeterminateModel] = useIndividualModel(() => isIndeterminate(value));
    const [indeterminate] = useDerivedStateToModel(value, indeterminateModel, (nextValue, prevValue, state) => {
        if (nextValue !== prevValue) {
            return isIndeterminate(nextValue);
        }
        return state;
    });
    const checkboxGroupChange = useCallback((checkedValues) => {
        if (onChange) {
            onChange([...checkedValues]);
        }
        else {
            valueModel([...checkedValues]);
        }
    }, [onChange]);
    const onAllRadioChange = useCallback((evt) => {
        const { checked } = evt.target;
        let types = [];
        if (checked) {
            types = [...allNames.ids];
        }
        checkboxGroupChange(types);
    }, [checkboxGroupChange, allNames]);
    const selectChange = useCallback((value) => {
        checkboxGroupChange((value || []).map((n) => n.value));
    }, [checkboxGroupChange, allNames]);
    const renderPanel = useCallback((selectedTypes) => {
        const eleArr = [];
        let allEle = null;
        for (let i = 0; i < items.length; i += 1) {
            const item = items[i];
            let ele = null;
            if (item.id === allFlag) {
                allEle = (<section key={item.id} className='all'>
            <Checkbox onChange={onAllRadioChange} indeterminate={indeterminate} checked={allChecked}>全选</Checkbox>
          </section>);
            }
            else {
                const types = item.children || [item];
                const span = 8;
                ele = (<section key={item.id} className='type-item'>
            <section className='type-item-name'>{item.name}</section>
            <section className='type-item-checkbox'>
              <Row>
                {types.map((t) => (<Col key={t.id} span={span}>
                      <Checkbox disabled={t.disabled} value={t.id}>{t.name}</Checkbox>
                    </Col>))}
              </Row>
            </section>
          </section>);
            }
            eleArr.push(ele);
        }
        return (<>
        {allEle}
        <Checkbox.Group value={selectedTypes} 
        // @ts-ignore
        onChange={checkboxGroupChange}>
          {eleArr}
        </Checkbox.Group>
      </>);
    }, [allChecked, indeterminate, onAllRadioChange, checkboxGroupChange, allFlag, items]);
    return (<Select allowClear {...restProps} labelInValue dropdownMatchSelectWidth={false} value={selectedValue} mode='multiple' onChange={selectChange} dropdownRender={() => (<section className={style.checkbox}>
          {renderPanel(value)}
        </section>)} className={style.select} dropdownAlign={{
            overflow: {
                adjustX: false,
                adjustY: false,
            },
        }}>

    </Select>);
};
CategorySelectCheckbox.defaultProps = {
    placeholder: '请选择',
    allowClear: false,
    allFlag: Date.now(),
};
export default CategorySelectCheckbox;
