import React, { useState } from 'react';
import { formatRegexString, getSafe, getYMD } from '@src/tools/util';
import { Table, Typography } from 'antd';
import { isSalaryMasked } from '@src/pages/HR/Offer/url';
const SalaryRecordTable = (props) => {
    const { list } = props;
    const [columns] = useState(() => [{
            title: 'offer薪资',
            dataIndex: 'salary',
            width: 150,
            render: (value) => {
                if (isSalaryMasked(value)) {
                    return value;
                }
                return `${value}/月`;
            },
        }, {
            title: '操作人',
            dataIndex: 'operatorCustomerName',
            width: 150,
        }, {
            title: '变动时间',
            dataIndex: 'updatedAt',
            width: 200,
            render: (value, record) => {
                const time = value || getSafe(record, 'createdAt');
                if (time) {
                    return getYMD(time);
                }
                return time;
            },
        }, {
            title: '备注',
            dataIndex: 'remark',
            width: 150,
            render: (value) => (<Typography.Paragraph ellipsis={{
                    rows: 5,
                    expandable: true,
                }}>
          {formatRegexString(value)}
        </Typography.Paragraph>),
        }]);
    return (<Table rowKey='id' columns={columns} dataSource={list} pagination={false}/>);
};
export default SalaryRecordTable;
