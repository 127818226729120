export var ApiCode;
(function (ApiCode) {
    ApiCode[ApiCode["ok"] = 0] = "ok";
    ApiCode[ApiCode["limitValid"] = 10] = "limitValid";
    ApiCode[ApiCode["needLogin"] = 100] = "needLogin";
    ApiCode[ApiCode["noAuth"] = 200] = "noAuth";
    ApiCode["factorSuccess"] = "100";
    ApiCode["httpError"] = "httpErrorCode";
    ApiCode["cancelError"] = "cancelErrorCode";
})(ApiCode || (ApiCode = {}));
export var User;
(function (User) {
    let ModuleAuthKey;
    (function (ModuleAuthKey) {
        ModuleAuthKey["module_auth"] = "module_auth";
        ModuleAuthKey["module_hr"] = "module_hr";
        ModuleAuthKey["module_administration"] = "module_administration";
        ModuleAuthKey["module_mine_home"] = "module_mine_home";
        ModuleAuthKey["module_account"] = "module_law";
        ModuleAuthKey["module_dengbao"] = "module_audit_log";
    })(ModuleAuthKey = User.ModuleAuthKey || (User.ModuleAuthKey = {}));
    let ModuleHRSubAuthKey;
    (function (ModuleHRSubAuthKey) {
        ModuleHRSubAuthKey["menu_hr_employ_official"] = "menu_hr_employ_official";
        ModuleHRSubAuthKey["menu_hr_probation"] = "menu_hr_probation";
        ModuleHRSubAuthKey["menu_hr_org"] = "menu_hr_organization";
        ModuleHRSubAuthKey["menu_hr_performance"] = "menu_hr_kpi_manage";
        ModuleHRSubAuthKey["menu_hr_tool_box"] = "menu_hr_tool_box";
        ModuleHRSubAuthKey["menu_hr_offer"] = "menu_hr_offer_official_manage";
        ModuleHRSubAuthKey["menu_hr_offer2"] = "menu_hr_offer_none_official_manage";
        ModuleHRSubAuthKey["button_offer_salary"] = "function_hr_offer_salary_hidden_manage";
        ModuleHRSubAuthKey["menu_hr_financial_tool_box"] = "menu_hr_financial_tool_box";
        ModuleHRSubAuthKey["menu_hr_customer_manage"] = "menu_hr_customer_manage";
        ModuleHRSubAuthKey["function_hr_delete_customer_info"] = "function_hr_delete_customer_info";
        ModuleHRSubAuthKey["menu_salary_management"] = "menu_salary_management";
        ModuleHRSubAuthKey["function_hr_attendance_monthly_update_one"] = "function_hr_attendance_monthly_update_one";
        ModuleHRSubAuthKey["function_hr_attendance_monthly_update_two"] = "function_hr_attendance_monthly_update_two";
        ModuleHRSubAuthKey["function_hr_offer_intern_salary_hidden_manage"] = "function_hr_offer_intern_salary_hidden_manage";
    })(ModuleHRSubAuthKey = User.ModuleHRSubAuthKey || (User.ModuleHRSubAuthKey = {}));
    let ModuleAdministrationSubAuthKey;
    (function (ModuleAdministrationSubAuthKey) {
        ModuleAdministrationSubAuthKey["menu_administration_induction_synchronize"] = "menu_administration_induction_synchronize";
        ModuleAdministrationSubAuthKey["menu_administration_thirdparty_account"] = "menu_administration_thirdparty_account";
    })(ModuleAdministrationSubAuthKey = User.ModuleAdministrationSubAuthKey || (User.ModuleAdministrationSubAuthKey = {}));
    let ModuleAccountManageSubAuthKey;
    (function (ModuleAccountManageSubAuthKey) {
        ModuleAccountManageSubAuthKey["menu_account_platform_list"] = "menu_law_account_platforms";
        ModuleAccountManageSubAuthKey["menu_account_approval_list"] = "menu_law_account_approval_list";
        ModuleAccountManageSubAuthKey["menu_account_transfer_list"] = "menu_law_account_transfer_list";
    })(ModuleAccountManageSubAuthKey = User.ModuleAccountManageSubAuthKey || (User.ModuleAccountManageSubAuthKey = {}));
    let HrManageSubAuthKey;
    (function (HrManageSubAuthKey) {
        HrManageSubAuthKey["hr_probation_manage"] = "probation_manage";
        HrManageSubAuthKey["hr_offical_employ_manage"] = "employ_manage";
        HrManageSubAuthKey["hr_offer_manage"] = "offer_manage";
        HrManageSubAuthKey["hr_kpi_manage"] = "kpi_manage";
    })(HrManageSubAuthKey = User.HrManageSubAuthKey || (User.HrManageSubAuthKey = {}));
    let LawAuthKey;
    (function (LawAuthKey) {
        LawAuthKey["menu_invisible_assets_management"] = "menu_invisible_assets_management";
        LawAuthKey["module_invisible_assets"] = "module_invisible_assets";
    })(LawAuthKey = User.LawAuthKey || (User.LawAuthKey = {}));
    let EmploymentPlanAuthKey;
    (function (EmploymentPlanAuthKey) {
        EmploymentPlanAuthKey["module_employment_plan"] = "module_employment_plan";
        EmploymentPlanAuthKey["menu_employment_plan_kanban"] = "menu_employment_plan_kanban";
        EmploymentPlanAuthKey["button_employment_plan_kanban_teams_number_of_people_info_edit"] = "button_employment_plan_kanban_teams_number_of_people_info_edit";
    })(EmploymentPlanAuthKey = User.EmploymentPlanAuthKey || (User.EmploymentPlanAuthKey = {}));
    let Attendance;
    (function (Attendance) {
        Attendance["module_attendance"] = "module_attendance";
        Attendance["menu_attendance_management"] = "menu_attendance_management";
        Attendance["hr_attendance_manage"] = "hr_attendance_manage";
    })(Attendance = User.Attendance || (User.Attendance = {}));
})(User || (User = {}));
export var StaffType;
(function (StaffType) {
    StaffType[StaffType["all"] = 0] = "all";
    StaffType[StaffType["regularSummary"] = 99] = "regularSummary";
    StaffType[StaffType["regular"] = 100] = "regular";
    StaffType[StaffType["regularPractice"] = 101] = "regularPractice";
    StaffType[StaffType["dispatchOutSouring"] = 1200] = "dispatchOutSouring";
    // specialStaff = 1300, // 特殊员工
    StaffType[StaffType["practiceSummary"] = 199] = "practiceSummary";
    StaffType[StaffType["practice"] = 200] = "practice";
    StaffType[StaffType["practiceOutsourcing"] = 201] = "practiceOutsourcing";
    StaffType[StaffType["practiceSummer"] = 202] = "practiceSummer";
    StaffType[StaffType["largeScaleSummary"] = 699] = "largeScaleSummary";
    StaffType[StaffType["largeScaleRegular"] = 700] = "largeScaleRegular";
    StaffType[StaffType["largeScaleRegularPractice"] = 701] = "largeScaleRegularPractice";
    StaffType[StaffType["largeScaleOutsourcing"] = 702] = "largeScaleOutsourcing";
    StaffType[StaffType["outsourcing"] = 300] = "outsourcing";
    StaffType[StaffType["cloud"] = 800] = "cloud";
    StaffType[StaffType["otherSummary"] = 399] = "otherSummary";
    StaffType[StaffType["affiliated"] = 400] = "affiliated";
    StaffType[StaffType["public"] = 500] = "public";
    StaffType[StaffType["other"] = 600] = "other";
})(StaffType || (StaffType = {}));
export const staffTypes = {
    [StaffType.all]: '全部',
    [StaffType.regularSummary]: '产研员工',
    [StaffType.regular]: '正式员工（产研）',
    [StaffType.regularPractice]: '正式员工（产研实习）',
    [StaffType.practiceSummary]: '实习生类型',
    [StaffType.practice]: '实习生',
    [StaffType.practiceOutsourcing]: '实习生（外包）',
    [StaffType.practiceSummer]: '暑期实习生',
    [StaffType.dispatchOutSouring]: '外包员工',
    [StaffType.largeScaleSummary]: '大规模用工类型',
    [StaffType.largeScaleRegular]: '正式员工（销服）',
    [StaffType.largeScaleRegularPractice]: '正式员工（销服实习）',
    [StaffType.largeScaleOutsourcing]: '外包（销服）',
    // [StaffType.specialStaff]: '特殊员工',
    [StaffType.outsourcing]: '劳务派遣',
    [StaffType.cloud]: '云账户类型',
    [StaffType.otherSummary]: '其他',
    [StaffType.affiliated]: '挂靠员工',
    [StaffType.public]: '公共账号',
    [StaffType.other]: '其他', // 不可转正式
};
export var StaffStatus;
(function (StaffStatus) {
    StaffStatus["all"] = "0";
    StaffStatus["quit"] = "3";
    StaffStatus["regular"] = "1000";
    StaffStatus["probation"] = "1100";
    // toBeConverted = '1200', // 待转正式 @deprecated
    StaffStatus["convertFailed"] = "1300";
    StaffStatus["contractExpired"] = "1400";
    StaffStatus["waitBoarding"] = "1500";
    StaffStatus["breakContract"] = "1600"; // 毁约
})(StaffStatus || (StaffStatus = {}));
export const staffStatus = {
    [StaffStatus.all]: '',
    [StaffStatus.quit]: '离职',
    [StaffStatus.regular]: '在职',
    [StaffStatus.probation]: '试用期',
    // [StaffStatus.toBeConverted]: '待转正式员工',
    [StaffStatus.convertFailed]: '转正失败',
    [StaffStatus.contractExpired]: '合同过期',
    [StaffStatus.waitBoarding]: '待入职员工',
    [StaffStatus.breakContract]: '毁约员工',
};
export const staffStatusColor = {
    [StaffStatus.all]: '',
    [StaffStatus.quit]: 'default',
    [StaffStatus.regular]: 'green',
    [StaffStatus.probation]: 'blue',
    // [StaffStatus.toBeConverted]: 'orange',
    [StaffStatus.convertFailed]: 'red',
    [StaffStatus.contractExpired]: 'red',
    [StaffStatus.waitBoarding]: 'blue',
    [StaffStatus.breakContract]: 'red',
};
export var SalaryPaymentKind;
(function (SalaryPaymentKind) {
    SalaryPaymentKind[SalaryPaymentKind["byDay"] = 100] = "byDay";
    SalaryPaymentKind[SalaryPaymentKind["byWeek"] = 200] = "byWeek";
    SalaryPaymentKind[SalaryPaymentKind["byMonth"] = 300] = "byMonth";
    SalaryPaymentKind[SalaryPaymentKind["byPieceAndTime"] = 400] = "byPieceAndTime";
    SalaryPaymentKind[SalaryPaymentKind["byDayWithoutCheckIn"] = 500] = "byDayWithoutCheckIn";
})(SalaryPaymentKind || (SalaryPaymentKind = {}));
export const salaryUnits = {
    [SalaryPaymentKind.byDay]: '天',
    [SalaryPaymentKind.byWeek]: '周',
    [SalaryPaymentKind.byMonth]: '月',
    [SalaryPaymentKind.byPieceAndTime]: '计时计件',
    [SalaryPaymentKind.byDayWithoutCheckIn]: '按天免打卡',
};
export var WorkType;
(function (WorkType) {
    WorkType[WorkType["other"] = 0] = "other";
    WorkType[WorkType["seller"] = 100] = "seller";
    WorkType[WorkType["customerService"] = 200] = "customerService";
    WorkType[WorkType["teacher"] = 300] = "teacher";
    WorkType[WorkType["commercial"] = 400] = "commercial";
    WorkType[WorkType["privateTraffic"] = 500] = "privateTraffic";
    WorkType[WorkType["massPro"] = 600] = "massPro";
    WorkType[WorkType["convent"] = 700] = "convent";
})(WorkType || (WorkType = {}));
export const workTypeNames = {
    [WorkType.other]: '其他',
    [WorkType.seller]: '销售',
    [WorkType.customerService]: '客服',
    [WorkType.teacher]: '老师',
    [WorkType.commercial]: '商务媒介',
    [WorkType.privateTraffic]: '私域流量运营',
    [WorkType.massPro]: '量产',
    [WorkType.convent]: '常规',
};
export var Workplace;
(function (Workplace) {
    Workplace["chengdu"] = "\u6210\u90FD";
    Workplace["beijing"] = "\u5317\u4EAC";
    Workplace["remote"] = "\u8FDC\u7A0B";
})(Workplace || (Workplace = {}));
