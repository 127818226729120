import { common, organization } from '@src/api/actions';
import { getSafe } from '@src/tools/util';
import { genRaceQueue } from 'femo';
import model from './model';
const treeRaceQueue = genRaceQueue();
const transferOrgToTreeData = (org) => {
    const children = getSafe(org, 'sub_teams') || [];
    const tempTree = {
        ...org,
        title: getSafe(org, 'team_name'),
        key: getSafe(org, 'team_id'),
        children,
    };
    if (children.length) {
        tempTree.children = children.map((child) => transferOrgToTreeData(child));
    }
    return tempTree;
};
export const sortTree = (tree) => {
    tree.sort((a, b) => b.sort_order - a.sort_order);
    for (let i = 0; i < tree.length; i += 1) {
        const t = tree[i];
        if (t.is_del) {
            tree.splice(i, 1);
            i -= 1;
            continue;
        }
        const subs = getSafe(t, 'sub_teams') || [];
        sortTree(subs);
    }
};
export const fetchOrgTree = () => {
    const p = model.tree(() => common.getOrgStruct().then((res) => {
        const org = getSafe(res, 'data.team_tree');
        const tree = [org];
        sortTree(tree);
        // const subs: TreeData[] = getSafe(org, 'sub_teams') || [];
        // subs.sort((a, b) => b.sort_order - a.sort_order);
        // const delArr = [];
        // for (let i = 0; i < subs.length; i += 1) {
        //   const node = subs[i];
        //   const isDel = getSafe(node, 'is_del');
        //   if (isDel) {
        //     subs.splice(i, 1);
        //     i -= 1;
        //     delArr.push(node);
        //   }
        // }
        // org.sub_teams = subs.concat(delArr);
        return tree;
    }));
    treeRaceQueue.push(p);
    return p;
};
export const newOrg = (data) => organization.addSubOrg(data);
export const updateOrg = (data) => organization.editOrg(data);
export const delOrg = (data) => organization.delOrg(data);
