import React, { useCallback, useState, } from 'react';
import { defaultOfferUrlQuery, isSalaryMasked, offerExportUrl, offerUrl, } from '@src/pages/HR/Offer/url';
import { getSafe, getYMD, isEmpty } from '@src/tools/util';
import { Offer } from '@src/pages/HR/Offer/interface';
import { fetchQuitList } from '@src/pages/HR/Offer/Regular/service';
import useTableYScroll from '@src/hooks/useTableYScroll';
import columnDataIndex from '@src/pages/HR/Offer/Regular/columnDataIndex';
import Placeholder from '@src/components/Placeholder';
import { Table } from 'antd';
import ListFilterForm from '@src/pages/HR/Offer/filter/ListFilterForm';
import FilterSearch from '@src/pages/components/FilterSearch';
import Spinner from '@src/components/Spinner';
import useQueryObjStrict from '@src/hooks/useQueryObjStrict';
import { Condition } from '@src/pages/components/ConditionSelect';
import { useBatchDerivedModel, useDerivedState, useModel } from 'femo';
import urlQueryToApiQuery, { orderByToTableSorter, switchOrderBy } from '@src/pages/HR/Offer/Regular/urlQueryToApiQuery';
import { quitList } from '@src/pages/HR/Offer/Regular/model';
import NewSvg from '@src/assets/svg/new.svg';
import LeaderWarning from '@src/pages/HR/Operation/BoardingManagement/OnBoardingList/List/LeaderWarning';
import { staffTypes, workTypeNames, } from '@src/api/interface';
import { securityCrash } from '@src/components/HOC';
import SalaryRecordTable from '@src/pages/HR/Offer/Regular/SalaryRecordTable';
import ListTotal from '@src/pages/components/ListTotal';
import { Channel } from '@src/pages/components/filter/ChannelCheckbox';
import ExportList from '@src/pages/components/ExportList';
import WorkTypeColumnTitle from '@src/pages/HR/Offer/WorkTypeColumnTitle';
import style from './style.less';
export const defaultOfferQuitListQuery = {
    ...defaultOfferUrlQuery,
    order_by: 'desc',
    sub_key: Offer.SubKeys.regular_quit_list,
    p_index: 1,
    p_size: 20,
    s_type: [],
    s_t_cond: [Condition.equal],
    c_id: [],
    c_id_cond: [Condition.equal],
    t_id: [],
    t_id_cond: [Condition.equal],
    channel: [],
    c_cond: [Condition.equal],
    c_by: [],
    c_by_cond: [Condition.equal],
    t_crt: [],
    t_c_cond: [Condition.equal],
    b_t: [],
    b_t_cond: [Condition.equal],
    t_dst: [],
    t_d_cond: [Condition.equal],
    name: [],
    name_cond: [Condition.include],
};
// 毁约offer列表初始的search
const initSearch = `?tab_key=${Offer.TabKeys.regular}&sub_key=${Offer.SubKeys.regular_quit_list}`;
const QuitList = (props) => {
    const { history, location, control, } = props;
    const search = getSafe(location, 'search');
    const [initQuery] = useState(() => ({
        ...defaultOfferQuitListQuery,
    }));
    const [query] = useQueryObjStrict(search, initQuery);
    const [staffTypeFilter] = useBatchDerivedModel(() => ({
        value: query.s_type,
        condition: query.s_t_cond,
    }), {
        source: query.s_type,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.s_t_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [teamFilter] = useBatchDerivedModel(() => ({
        value: query.t_id,
        condition: query.t_id_cond,
    }), {
        source: query.t_id,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.t_id_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [companyFilter] = useBatchDerivedModel(() => ({
        value: query.c_id,
        condition: query.c_id_cond,
    }), {
        source: query.c_id,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: query.c_id,
                };
            }
            return state;
        },
    }, {
        source: query.c_id_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: query.c_id_cond,
                };
            }
            return state;
        },
    });
    const [channelFilter] = useBatchDerivedModel(() => ({
        value: query.channel,
        condition: query.c_cond,
    }), {
        source: query.channel,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.c_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [createdByFilter] = useDerivedState(() => ({
        value: query.c_by,
        condition: query.c_by_cond,
    }), [query.c_by, query.c_by_cond]);
    const [createdTimeFilter] = useBatchDerivedModel(() => ({
        value: query.t_crt,
        condition: query.t_c_cond,
    }), {
        source: query.t_crt,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.t_c_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [quitTimeFilter] = useBatchDerivedModel(() => ({
        value: query.t_dst,
        condition: query.t_d_cond,
    }), {
        source: query.t_dst,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.t_d_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [boardingTimeFilter] = useBatchDerivedModel(() => ({
        value: query.b_t,
        condition: query.b_t_cond,
    }), {
        source: query.b_t,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.b_t_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [nameFilter] = useBatchDerivedModel(() => ({
        value: query.name,
        condition: query.name_cond,
    }), {
        source: query.name,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.name_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const push = useCallback((q) => {
        history.push(offerUrl({
            ...query,
            ...q,
        }));
    }, [query]);
    // const replace = useCallback((q: Partial<OfferUrlQuery>) => {
    //   history.push(offerUrl({
    //     ...query,
    //     ...q,
    //   }));
    // }, [query]);
    const request = useCallback((state) => {
        const tab = getSafe(query, 'tab_key');
        const subTab = getSafe(query, 'sub_key');
        if (tab === Offer.TabKeys.regular && subTab === Offer.SubKeys.regular_quit_list) {
            return fetchQuitList(urlQueryToApiQuery(query, Offer.ListType.break_contract, Offer.OfferManagerSource.regularOffer));
        }
        return state;
    }, [query]);
    const [list, , { loading }] = useModel(quitList, [request], {
        control: control().key === Offer.SubKeys.regular_quit_list ? control : undefined,
    });
    const y = useTableYScroll(450);
    const submit = useCallback((v) => {
        push({
            ...v,
            p_index: 1,
            p_size: 20,
            t: Date.now(),
        });
    }, [push]);
    const onTableChange = useCallback((pagination, _filters, _sorter, extra) => {
        const q = {};
        if (extra.action === 'paginate') {
            q.p_index = pagination.current;
        }
        else if (extra.action === 'sort') {
            q.order_by = switchOrderBy(query.order_by);
        }
        push(q);
    }, [push, query.order_by]);
    const genColumns = useCallback((orderBy) => [{
            title: '姓名',
            dataIndex: columnDataIndex.name,
            width: 100,
            fixed: 'left',
            render: (value, record) => {
                const now = Date.now();
                const createUt = (getSafe(record, columnDataIndex.createdTime) || 0) * 1000;
                const benchmark = 24 * 60 * 60 * 1000;
                const newFlag = Math.abs(now - createUt) < benchmark;
                if (newFlag) {
                    return (<span className='name'>
            <span className='new-svg'><NewSvg /></span>
            {value}
          </span>);
                }
                return value;
            },
        }, {
            title: '入职岗位',
            dataIndex: columnDataIndex.jobName,
            width: 150,
        }, {
            title: (<WorkTypeColumnTitle />),
            dataIndex: columnDataIndex.work_type,
            width: 150,
            render: (v) => getSafe(workTypeNames, `${v}`),
        }, 
        // {
        //   title: '入职团队',
        //   dataIndex: columnDataIndex.rootTeamName,
        //   width: 150,
        //   render: (value: string) => value || <Placeholder text='--' />,
        // },
        {
            title: '入职部门',
            dataIndex: columnDataIndex.teamName,
            width: 150,
        }, {
            title: '入职地点',
            dataIndex: columnDataIndex.boardPlace,
            width: 150,
        }, {
            title: 'Leader',
            dataIndex: columnDataIndex.leaderName,
            width: 100,
            render: (_v, record) => {
                const lid = getSafe(record, 'leader_customer_id');
                const name = getSafe(record, columnDataIndex.leaderName);
                if (lid === 0) {
                    return <LeaderWarning hideTodo name={name}/>;
                }
                return name;
            },
        }, {
            title: '员工类型',
            dataIndex: columnDataIndex.staffType,
            width: 100,
            render: (v) => staffTypes[v],
        }, {
            title: '手机',
            dataIndex: columnDataIndex.mobile,
            width: 150,
        }, {
            title: '邮箱',
            dataIndex: columnDataIndex.email,
            width: 200,
        }, {
            title: '公司主体',
            dataIndex: columnDataIndex.companyName,
            width: 150,
            render: (value) => value || <Placeholder text='--'/>,
        }, {
            title: '工作地点',
            dataIndex: columnDataIndex.workplace,
            width: 100,
        }, {
            title: '申请渠道',
            children: [{
                    title: '一级渠道',
                    dataIndex: columnDataIndex.firstChannel,
                    width: 150,
                    render: (value, record) => {
                        const channel = getSafe(record, 'request_channel_type');
                        if (channel === Channel.other) {
                            return '其他';
                        }
                        return value || <Placeholder text='--'/>;
                    },
                }, {
                    title: '二级渠道',
                    dataIndex: columnDataIndex.secondChannel,
                    width: 150,
                    render: (value, record) => {
                        const channel = getSafe(record, 'request_channel_type');
                        if (channel === Channel.other) {
                            return '其他';
                        }
                        return value || <Placeholder text='--'/>;
                    },
                }, {
                    title: '三级渠道',
                    dataIndex: columnDataIndex.thirdChannel,
                    width: 150,
                    render: (value, record) => {
                        const channel = getSafe(record, 'request_channel_type');
                        if (channel === Channel.other) {
                            return '其他';
                        }
                        return value || <Placeholder text='--'/>;
                    },
                }],
        }, {
            title: 'offer类型',
            dataIndex: columnDataIndex.offerType,
            width: 200,
        }, {
            title: '创建时间',
            dataIndex: columnDataIndex.createdTime,
            width: 200,
            render: (value) => {
                if (typeof value === 'number' && value !== 0) {
                    return getYMD(value * 1000);
                }
                return value;
            },
        }, {
            title: '创建人',
            dataIndex: columnDataIndex.creator,
            width: 150,
            render: (v, r) => {
                const inputType = getSafe(r, 'input_type');
                const createdBy = getSafe(r, 'created_by');
                if (!createdBy && inputType === Offer.InputType.moka) {
                    return <LeaderWarning name={v} hidePopover/>;
                }
                return v;
            },
        }, {
            title: '入职时间',
            width: 200,
            dataIndex: columnDataIndex.boardingTime,
            render: (value, _record) => {
                if (typeof value === 'number' && value !== 0) {
                    return getYMD(value * 1000);
                }
                return value || <Placeholder text='未设置'/>;
            },
        }, {
            title: 'offer薪资',
            width: 100,
            dataIndex: columnDataIndex.salary,
            render: (value, _record) => {
                if (isSalaryMasked(value)) {
                    return value;
                }
                if (isEmpty(value)) {
                    return <Placeholder text='未设置'/>;
                }
                return `${value}/月`;
            },
        }, {
            title: '毁约时间',
            width: 200,
            sorter: true,
            sortOrder: orderByToTableSorter(orderBy),
            dataIndex: columnDataIndex.breakContractTime,
            render: (value, _r) => {
                if (typeof value === 'number' && value !== 0) {
                    return getYMD(value * 1000);
                }
                return value || <Placeholder text='--'/>;
            },
        }], []);
    const [columns] = useDerivedState(() => genColumns(query.order_by), [query.order_by]);
    return (<section className={style.quitList}>
      <FilterSearch title={<Placeholder text='列表存放所有历史毁约的offer信息'/>}>
        <ListFilterForm listType='quit' staffType={staffTypeFilter} team={teamFilter} company={companyFilter} channel={channelFilter} createdTime={createdTimeFilter} createdBy={createdByFilter} quitTime={quitTimeFilter} boardingTime={boardingTimeFilter} name={nameFilter} onSubmit={submit}/>
      </FilterSearch>
      <ListTotal widgets={<ExportList url={offerExportUrl(urlQueryToApiQuery(query, Offer.ListType.break_contract, Offer.OfferManagerSource.regularOffer))}/>} count={getSafe(list, 'total_count', 0)}/>
      <Table onChange={onTableChange} rowKey='candidate_id' dataSource={list.offer_list} 
    // @ts-ignore
    columns={columns} expandable={{
            expandedRowRender: (record) => (<SalaryRecordTable list={getSafe(record, 'offer_salary_list')}/>),
            rowExpandable: (record) => !!getSafe(record, 'offer_salary_list.length'),
        }} pagination={{
            pageSize: query.p_size,
            current: query.p_index,
            hideOnSinglePage: true,
            total: list.total_count,
            showSizeChanger: false,
        }} scroll={{
            y,
            x: 1650,
        }} loading={{
            spinning: loading,
            indicator: <Spinner type='small'/>,
        }}/>
    </section>);
};
// withRouter不单单是注入属性，其会引起组件刷新
export default React.memo(securityCrash(QuitList), (_prevProps, nextProps) => nextProps.activeSubKey !== Offer.SubKeys.regular_quit_list || nextProps.location.search === initSearch);
