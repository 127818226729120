import React from 'react';
import { Form, Input } from 'antd';
import { getSafe, isEmpty, regex } from '@src/tools/util';
import { organization } from '@src/api/actions';
const MobileField = (props) => {
    const { needCheck, isAdd, stickyMobile, ...restProps } = props;
    const rules = [{
            validator: (_rule, value) => {
                if (!isEmpty(value) && !regex.mobile(value)) {
                    return Promise.reject('格式不正确');
                }
                return Promise.resolve();
            },
        }, needCheck ? {
            validator: (_rule, value) => {
                if (regex.mobile(value) && stickyMobile !== value) {
                    return organization.checkDupMobile({
                        mobile: value,
                    }).catch(() => Promise.resolve()).then((result) => {
                        const duplicated = getSafe(result, 'data.duplicated');
                        const customerId = getSafe(result, 'data.customer_id');
                        if (duplicated) {
                            if (isAdd) {
                                return Promise.reject(<span>手机号重复，请联系HR处理</span>);
                            }
                            return Promise.reject(<span>手机号重复，点击<a href={`/hr/org/staff/detail?id=${customerId}`} target='_blank' rel='noreferrer noopener nofollow'>查看</a></span>);
                        }
                        return Promise.resolve();
                    });
                }
                return Promise.resolve();
            },
            validateTrigger: ['onChange', 'onSubmit'],
        } : {}];
    return (<Form.Item validateFirst {...restProps} rules={props.rules ? [...props.rules, ...rules] : rules}>
      <Input autoComplete='off' placeholder='请输入'/>
    </Form.Item>);
};
MobileField.defaultProps = {
    label: '手机',
    needCheck: true,
    isAdd: false,
};
export default MobileField;
