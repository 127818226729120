import { del, get, patch, post, put, requestFileByPost, } from '@src/tools/request';
import { getCommonCompanyListCached, getCommonJobListCached, getJobListCached } from '@src/api/cachedAction';
import { isEmpty } from '@src/tools/util';
import apiUrls from './urls';
export const common = {
    // 获取key-value标识
    getFlags: () => get(apiUrls.getFlags),
    // 保存key-value标识
    saveFlags: (data) => post(apiUrls.saveFlags, data),
    // 根据部门id获取获取该部门下所有在职员工
    getStaffUserListByTeamId: (teamId) => get(apiUrls.getStaffListByTeamId, { team_id: teamId }),
    // 更新token
    updateToken: (id) => get(apiUrls.buryToken, { id }),
    // 获取用户信息
    getUserInfo: () => get(apiUrls.userInfo, {}, { showBizError: false, showHttpError: false }),
    // 搜索用户
    // 0   所有员工(默认)
    // 10  所有在职员工
    // 11  所有在职产研员工
    // 20  所有离职员工
    // 21  所有离职产研员工
    // 搜索用户
    searchUser: (params) => get(apiUrls.searchUser, params),
    getAllUsers: (params) => get(apiUrls.searchAllUser, params),
    // 所有项目组
    allTeams: () => get(apiUrls.allTeam, {}, { showBizError: false }),
    // 获取公司组织架构树
    getOrgStruct: () => get(apiUrls.orgStruct, {}),
    // 获取公司岗位列表 （招聘需求里面也有一个对外的，当前这个是对内的）
    getCommonJobList: () => getCommonJobListCached(apiUrls.commonJobList),
    // 获取所有公司列表
    getAllCompanyList: () => getCommonCompanyListCached(apiUrls.allCompanyList),
    // 上传文件
    uploadFile: (data) => post(apiUrls.uploadFile, data, { headers: { contentType: 'multipart/form-data' } }),
    // 根据customerId获取用户信息
    getUserInfoById: (customerId) => get(apiUrls.userInfoById, { customer_id: customerId }),
    // 两要素、三要素验证
    // factorCheck: (data: {
    //   check_type: 'three-factor' | 'two-factor';
    //   name: string;
    //   id_number: string;
    //   bank_number?: string; // 三要素必填
    // }): Promise<ApiResult<any>> => post(apiUrls.factorCheck, data, {
    //   showBizError: false,
    // }),
    factorCheck: (data) => Promise.resolve({ code: 1, msg: '成功', data }),
    // 等保审计日志列表
    getLogAuditList: (query) => get(apiUrls.dengbaoLogList, query),
    // 未确认列表
    getNotConfirmedList: (query) => get(apiUrls.notConfirmedList, query),
    // 检查hr销服《员工手册》消息是否已确认
    staffPrincipleConfirmCheck: () => get(apiUrls.staffPrincipleConfirmCheck),
    // 员工手册》消息确认
    staffPrincipleConfirm: () => post(apiUrls.staffPrincipleConfirm),
};
export const probation = {
    // 试用期管理：试用期员工信息
    getProbationUserInfo: (staffId, stageId) => get(apiUrls.probationUserInfo(staffId, stageId)),
    // 试用期管理：查询所有阶段
    getProbationStageAll: () => get(apiUrls.probationStageAll),
    // 试用期管理：根据stageId查询用户列表
    getProbationStageDetail: (stageId, query) => get(apiUrls.probationStageDetail(stageId), query),
    // 试用期管理：操作的接口(比如 催一下)
    postProbationOperation: (data, config) => post(apiUrls.probationOperations, data, config),
    // 试用期管理：驳回或确认试用期目标
    postProbationPurposeConfirm: (data) => post(apiUrls.probationPurposeConfirm(data.staffId, data.operation)),
    // 试用期管理：转正审批leader
    postProbationResultConfirmLeader: (data) => post(apiUrls.probationResultConfirmLeader(data.staffId, data.operation)),
    // 试用期管理：转正审批manager
    postProbationResultConfirmManager: (data) => post(apiUrls.probationResultConfirmManager(data.staffId, data.operation)),
    // 试用期管理：查看操作日志
    getProbationOperation: (params) => get(apiUrls.probationOperations, params),
    // 试用期管理：提交试用期目标
    postProbationPurpose: (data) => post(apiUrls.probationPurposeAdd, data),
    // 试用期管理：查看试用期目标
    getProbationPurpose: (staffId) => get(apiUrls.probationPurposeGet(staffId)),
    // 试用期管理：查看员工试用期目标完成情况
    getProbationPurposeFinish: (staffId) => get(apiUrls.probationPurposeFinishGet(staffId)),
    // 试用期管理: 评估试用期目标完成情况
    evaluateProbationPurpose: (data, staffId) => post(apiUrls.probationPurposeEvaluate(staffId), data),
    // 查看员工合作者名单
    getProbationCooperator: (staffId, stageId) => get(apiUrls.probationCooperatorGet(staffId, stageId)),
    // 提交员工合作者名单
    postProbationCooperator: (data, staffId, stageId) => post(apiUrls.probationCooperatorAdd(staffId, stageId), data),
    // 一阶段问卷提交
    postProbationFirstStageQuestion: (data) => post(apiUrls.probationFirstStageQuestionPost, data),
    // 二阶段问卷提交
    postProbationSecondStageQuestion: (data) => post(apiUrls.probationSecondStageQuestionPost, data),
    // 一阶段问卷结果查询
    getProbationFirstStageAnswer: (staffId, submitStaffId) => get(apiUrls.probationFirstStageAnswerGet(staffId, submitStaffId)),
    // 二阶段问卷结果查询
    getProbationSecondStageAnswer: (staffId, submitStaffId) => get(apiUrls.probationSecondStageAnswerGet(staffId, submitStaffId)),
    // 提交员工反思与总结
    postProbationRethink: (data) => post(apiUrls.probationRethinkPost, data),
    // 提交员工反思与总结文档答案
    postProbationRethinkDoc: (data, staffId, config) => post(apiUrls.probationRethinkDoc(staffId), data, config),
    // 查看员工反思与总结
    getProbationRethink: (staffId, stageId) => get(apiUrls.probationRethinkGet(staffId, stageId)),
    // 提交1 on 1反馈
    postProbation1On1: (stageId, data) => post(apiUrls.probation1On1Post(stageId), data),
    // 查询1 on 1反馈
    getProbation1On1: (staffId, stageId) => get(apiUrls.probation1On1Get(staffId, stageId)),
};
export const auth = {
    // 权限：获取所有项目列表
    getAuthDepartList: () => get(apiUrls.authDepartList),
    // 权限：获取服务列表
    getAuthServiceInDepart: (params) => get(apiUrls.authServiceInDepart, params),
    // 权限：新建服务
    postAuthServiceCreate: (data) => post(apiUrls.authServiceCreate, data),
    // 权限：删除服务
    postAuthServiceDelete: (data) => post(apiUrls.authServiceDelete, data),
    // 权限：更新服务
    postAuthServiceUpdate: (data) => post(apiUrls.authServiceUpdate, data),
    // 权限：获取权限组列表
    getAuthGroupList: (params) => get(apiUrls.authGroupList, params),
    // 权限：获取全量的权限组列表
    getAuthGroupListAll: (params) => get(apiUrls.authGroupListAll, params),
    // 权限：新建权限组
    postAuthGroupCreate: (data) => post(apiUrls.authGroupCreate, data),
    // 权限：删除权限组
    postAuthGroupDelete: (data) => post(apiUrls.authGroupDelete, data),
    // 权限：更新权限组
    postAuthGroupUpdate: (data) => post(apiUrls.authGroupUpdate, data),
    // 权限：获取权限组内权限列表
    getAuthGroupInnerResList: (params) => get(apiUrls.authGroupInnerResList, params),
    // 权限：获取权限组内所有权限
    getAuthGroupInnerResListAll: (params) => get(apiUrls.authGroupInnerResListAll, params),
    // 权限：移除权限组内的一个权限
    postAuthGroupInnerResDelete: (data) => post(apiUrls.authGroupInnerResDelete, data),
    // 权限：获取组内还没有的权限
    getAuthGroupInnerResUninclude: (params) => get(apiUrls.authGroupInnerResUninclude, params),
    // 权限：获取组内所有没有的权限
    getAuthGroupInnerResUnincludeAll: (params) => get(apiUrls.authGroupInnerResUnincludeAll, params),
    // 权限：新增一批权限到组
    postAuthGroupInnerResAdd: (data) => post(apiUrls.authGroupInnerResAdd, data),
    // 权限：获取组内用户列表
    getAuthGroupInnerUserList: (params) => get(apiUrls.authGroupInnerUserList, params),
    // 权限: 获取组内所有用户
    getAuthGroupInnerUserListAll: (params) => get(apiUrls.authGroupInnerUserListAll, params),
    // 权限：移除组内某个用户
    postAuthGroupInnerUserDelete: (data) => post(apiUrls.authGroupInnerUserDelete, data),
    // 权限：添加新用户到组
    postAuthGroupInnerUserAdd: (data) => post(apiUrls.authGroupInnerUserAdd, data),
    // 权限：获取服务内权限
    getAuthServiceInnerResList: (params) => get(apiUrls.authServiceInnerResList, params),
    // 权限：获取服务内所有权限
    getAuthServiceInnerResListAll: (params) => get(apiUrls.authServiceInnerResListAll, params),
    // 权限：新建一个服务的权限
    postAuthResCreate: (data) => post(apiUrls.authResCreate, data),
    // 权限：更新一个服务的权限
    postAuthResUpdate: (data) => post(apiUrls.authResUpdate, data),
    // 权限：删除一个服务的权限
    postAuthResDelete: (data) => post(apiUrls.authResDelete, data),
    // 权限：获取权限内用户列表
    getAuthResInnerUserList: (params) => get(apiUrls.authResInnerUserList, params),
    // 权限：获取权限内所有用户列表
    getAuthResInnerUserListAll: (params) => get(apiUrls.authResInnerUserListAll, params),
    // 权限：删除权限内某个用户
    postAuthResInnerUserDelete: (data) => post(apiUrls.authResInnerUserDelete, data),
    // 权限：给权限添加一个用户
    postAuthResInnerUserAdd: (data) => post(apiUrls.authResInnerUserAdd, data),
    // 权限：获取被引用的组的列表
    getAuthResQuoteGrpList: (params) => get(apiUrls.authResQuoteGrpList, params),
    // 权限：获取所有被引用的组的列表
    getAuthResQuoteGrpListAll: (params) => get(apiUrls.authResQuoteGrpListAll, params),
    // 权限：将权限添加到组中
    postAuthResQuoteGrpIncludeNew: (data) => post(apiUrls.authResQuoteGrpIncludeNew, data),
    // 权限：将权限从选中的组中删除
    postAuthResQuoteGrpExcludeOld: (data) => post(apiUrls.authResQuoteGrpExcludeOld, data),
    // 权限: 操作记录列表
    getAuthServiceHistoryList: (params) => get(apiUrls.authServiceHistoryList, params),
    // 权限：批量移除组或者权限内某些用户
    postAuthResOrGrpInnerUserBatchDelete: (data) => post(apiUrls.authResOrGrpInnerUserBatchDelete, data),
    // 权限：批量添加用户到组或者权限
    postAuthResOrGrpInnerUserBatchAdd: (data) => post(apiUrls.authResOrGrpInnerUserBatchAdd, data),
    // 查找leader
    getStaffInfoGetLeaderByCustomerId: (params) => get(apiUrls.staffInfoGetLeaderByCustomerId, params),
};
export const recruit = {
    // 招聘管理-招聘需求：获取状态分类
    getRecruitDemandStatusCategoryList: () => get(apiUrls.recruitDemandStatusCategoryList),
    // 招聘管理-招聘需求：搜索需求
    getRecruitDemandSearchList: (params) => get(apiUrls.recruitDemandSearchList, params),
    // 招聘管理-招聘需求: 批量设置已经完成
    postRecruitDemandBatchSetDone: (data) => post(apiUrls.recruitDemandBatchSetDone, data),
    // 招聘管理-招聘需求: 获取某个招聘的审批流程
    getRecruitDemandProgress: (params) => get(apiUrls.recruitDemandProgress, params),
    // 招聘管理-招聘需求: 同意还是驳回一个请求
    postRecruitDemandAnswer: (data) => post(apiUrls.recruitDemandAnswer, data),
    // 招聘管理-招聘需求: 获取所有招聘岗位分类
    getRecruitDemandJobList: () => getJobListCached(apiUrls.recruitDemandJobList, {}, { showBizError: false, showHttpError: false }),
    // 招聘管理-招聘需求: 提交一个招聘申请
    postRecruitDemandApply: (data) => post(apiUrls.recruitDemandApply, data),
    // 招聘管理-招聘需求: 我发起的列表
    getRecruitDemandDispatchByMe: (params) => get(apiUrls.recruitDemandDispatchByMe, params),
    // 招聘管理-招聘需求: 获取所有待处理的审批
    getRecruitDemandToDoByMe: (params) => get(apiUrls.recruitDemandToDoByMe, params),
    // 招聘管理-招聘需求: 获取所有已处理的审批
    getRecruitDemandDoneByMe: (params) => get(apiUrls.recruitDemandDoneByMe, params),
    // 招聘管理-招聘需求: 获取某个申请的详情
    getRecruitDemandDetail: (params) => get(apiUrls.recruitDemandDetail, params),
    // 招聘管理-招聘需求: 撤销一个请求
    postRecruitDemandCancel: (data) => post(apiUrls.recruitDemandCancel, data),
    // 获取招聘岗位的职责和要求
    getRecruitOnboardJobTemplate: (query) => get(apiUrls.recruitOnboardJobTemplate, query),
    // 获取moka所有产研员工在招职位列表
    getRecruitMokaJobList: () => get(apiUrls.recruitMokaJobList),
    // 关联或者解除moka职位和招聘需求
    postRecruitBindMokaJob: (data) => post(apiUrls.recruitBindMokaJob, data),
    // 获取待入职或毁约列表
    getRecruitBoardingList: (query) => get(apiUrls.recruitBoardingList, query),
    // 添加待入职
    postRecruitBoardingNewStaff: (data) => post(apiUrls.recruitBoardingNewStaff, data),
    // 添加再入职
    postRecruitBoardingStaffAgain: (data) => post(apiUrls.recruitBoardingStaffAgain, data),
    // 设置入职时间
    postRecruitBoardingSetBoardingTime: (data) => post(apiUrls.recruitBoardingSetBoardingTime, data),
    // 入职
    postRecruitBoardingTurnRegular: (data) => post(apiUrls.recruitBoardingTurnRegular, data),
    // 毁约
    postRecruitBreachOffer: (data) => post(apiUrls.recruitBreachOffer, data),
    // 毁约但不发消息
    postRecruitBoardingBreachOffer: (data) => post(apiUrls.recruitBoardingBreachOffer, data),
    // 获取今日和明日即将入职的人
    getRecruitBoardingTodayAndTomorrow: () => get(apiUrls.recruitBoardingTodayAndTomorrow),
    // 获取待入职或者毁约列表的员工数量
    getRecruitBoardingCountList: () => get(apiUrls.recruitBoardingCountList),
};
export const organization = {
    // 获取组织结构下的员工列表
    getOrgStaffList: (params) => get(apiUrls.orgStaffList, params),
    // 获取离职员工列表
    getResignedStaffList: (params) => get(apiUrls.orgResignedStaffList, params),
    // 设置员工所在部门
    setStaffOrg: (data) => post(apiUrls.orgStaffOrgSet, data),
    // 删除一个部门
    delOrg: (data) => post(apiUrls.orgDel, data),
    // 添加子部门
    addSubOrg: (data) => post(apiUrls.orgSubOrgAdd, data),
    // 编辑一个部门
    editOrg: (data) => post(apiUrls.orgEdit, data),
    // 设置部门的金蝶系统 id、财务 id 以及是否为虚拟组织
    setOrgId: (data) => post(apiUrls.orgIdSet, data),
    // 上移或者下移一个部门
    setOrgOrder: (data) => post(apiUrls.orgSetOrder, data),
    // 检测手机号是否重复
    checkDupMobile: (params) => get(apiUrls.orgCheckDupMobile, params),
    // 检测企业邮箱是否重复
    checkDupEmail: (params) => get(apiUrls.orgCheckDupEmail, params),
    // 创建企业邮箱
    createCorpEmail: (data) => post(apiUrls.orgCreateCorpEmail, data),
    // 录入新员工
    addNewStaff: (data) => post(apiUrls.orgAddNewStaff, data),
    // 录入特殊员工
    addNewSpecialStaff: (data) => post(apiUrls.orgAddNewSpecialStaff, data),
    // 实习生转正
    setStaffPracticeConverted: (staffId, data) => post(apiUrls.staffPracticeConverted(staffId), data),
    // 删除未开履约的合同
    delStaffContract: (contractId) => del(apiUrls.staffDelContract(contractId)),
    // 修改合同
    patchStaffContract: (contractId, data) => patch(apiUrls.staffUpdateContract(contractId), data),
    // 新增合同
    addNewStaffContract: (staffId, data) => post(apiUrls.staffAddNewContract(staffId), data),
    // 查看合同信息
    getStaffContract: (staffId) => get(apiUrls.staffContractDetail(staffId)),
    // 编辑个人基础信息
    updateStaffBasicInfo: (staffId, data) => patch(apiUrls.staffUpdateBasicInfo(staffId), data),
    // 添加通讯信息
    addStaffContactInfo: (staffId, data) => post(apiUrls.staffAddContactInfo(staffId), data),
    // 编辑通讯信息
    updateStaffContact: (staffId, data) => patch(apiUrls.staffUpdateContactInfo(staffId), data),
    // 查看资料存档
    getStaffDocument: (staffId) => get(apiUrls.staffDocumentDetail(staffId)),
    // 查看岗位信息
    getStaffJobDetail: (staffId) => get(apiUrls.staffJobDetail(staffId)),
    // 查询员工信息
    getStaffPersonalInfo: (staffId) => get(apiUrls.staffPersonalInfo(staffId)),
    // 查询多因素验证数据
    getMfaInfo: (staffId) => get(apiUrls.staffMfaInfo(staffId)),
    // 添加工作经历
    addStaffWorkExperience: (staffId, experience) => post(apiUrls.staffWorkExperienceAdd(staffId), experience),
    // 编辑工作经历
    updateStaffWorkExperience: (staffId, experience) => post(apiUrls.staffWorkExperienceUpdate(staffId), experience),
    // 删除工作经历
    delStaffWorkExperience: (experienceId) => del(apiUrls.staffWorkExperienceDel(experienceId)),
    // 删除教育经历
    delStaffEducation: (educationId) => del(apiUrls.staffEducationDel(educationId)),
    // 添加教育经历
    addStaffEducation: (staffId, educations) => post(apiUrls.staffEducationAdd(staffId), educations),
    // 编辑教育经历
    updateStaffEducation: (staffId, education) => post(apiUrls.staffEducationUpdate(staffId), education),
    // 删除证书
    delStaffCert: (certId) => del(apiUrls.staffCertDel(certId)),
    // 添加证书
    addStaffCert: (staffId, certs) => post(apiUrls.staffCertAdd(staffId), certs),
    // 编辑证书
    updateStaffCert: (staffId, cert) => post(apiUrls.staffCertUpdate(staffId), cert),
    // 添加账户信息
    addStaffAccount: (staffId, account) => post(apiUrls.staffAccountAdd(staffId), account),
    // 编辑账户信息
    updateStaffAccount: (staffId, account) => patch(apiUrls.staffAccountUpdate(staffId), account),
    // 录入新员工
    addNewStaffPersonalInfo: (staffId, personalInfo) => post(apiUrls.staffNew(staffId), personalInfo),
    // 调岗
    changeStaffDocs: (data, candidateId) => {
        if (isEmpty(candidateId)) {
            return post(apiUrls.staffDocsChange, data);
        }
        return post(`${apiUrls.staffDocsChange}?candidateId=${candidateId}`, data);
    },
    // 离职
    quitStaffDoc: (data) => del(apiUrls.staffDocQuit, data),
    // 转正
    convertedStaff: (staffId) => post(apiUrls.staffConverted(staffId), {}),
    // 转员工类型
    changeStaffType: (data, candidateId) => {
        const { staffId, type, ...rest } = data;
        if (isEmpty(candidateId)) {
            return post(apiUrls.staffTypeChange(staffId, type), rest);
        }
        return post(`${apiUrls.staffTypeChange(staffId, type)}?candidateId=${candidateId}`, rest);
    },
};
export const value = {
    // 环评-获取需要设置评估人的被评估人列表
    getEmployeeToBeSet: () => get(apiUrls.valueToBeSetDataEmployee),
    // 环评-提交评估人的数据
    postEvaluatorsData: (data) => post(apiUrls.valueToBeInvolvedEmployee, data),
    // 环评-获取待评估列表
    getToBeEvaluatedList: () => get(apiUrls.valueToBeEvaluatedList),
    // 环评-提交评价
    postResult: (data) => post(apiUrls.valueToSubmit, data),
};
export const performance = {
    // 根据teamId获取用户列表
    getStaffListByTeamIdWithGroupStatus: (teamId) => get(apiUrls.performanceStaffListByTeam(teamId)),
    // 查看所有绩效周期目标
    getAllPerformanceGoals: () => get(apiUrls.performanceAllGoals),
    // 创建绩效周期目标
    createPerformanceNewGoal: (data) => put(apiUrls.performanceNewGoal, data),
    // 检查周期名称是否重复
    checkPerformanceGoalNameDuplicated: (name) => get(apiUrls.performanceCheckDuplicated, { newName: name }),
    // 某个周期下所有绩效目标列表
    getPerformanceGoalsList: (query, id) => get(apiUrls.performanceGoalsList(id), query),
    // 新增考评组
    createPerformanceNewGroup: (data, id) => post(apiUrls.performanceNewGroup(id), data),
    // 周期目标归档
    postPerformanceArchive: (id) => post(apiUrls.performanceArchive(id)),
    // 周期目标整体情况
    getPerformanceGoalsSummary: (periodId) => get(apiUrls.performanceGoalsSummary(periodId)),
    // 周期目标催办
    postPerformanceGoalHurry: (id) => post(apiUrls.performanceGoalHurry(id)),
    // 周期目标规则设置
    postPerformanceGoalSet: (data, id) => post(apiUrls.performanceGoalSet(id), data),
    // 某个周期下所有绩效目标列表-地图模式
    getPerformanceGoalGraph: (id, teamId) => get(apiUrls.performanceGoalGraph(id, teamId)),
    // 查看绩效目标
    getPerformanceGoal: (id) => get(apiUrls.performanceGoal(id)),
    // 待我处理的kpi目标审核流程
    getPerformanceGoalsToBeChecked: (query) => get(apiUrls.performanceGoalsToBeChecked, query),
    // 提交审核
    postPerformanceGoalToReview: (goalId) => post(apiUrls.performanceGoalToReview(goalId)),
    // 修改目标内容，id为detail的id
    postPerformanceGoalUpdate: (data, id) => post(apiUrls.performanceGoalUpdate(id), data),
    // 删除目标
    deletePerformanceGoalDel: (id) => del(apiUrls.performanceGoalDel(id)),
    // 审核目标
    postPerformanceGoalOperation: (data) => post(apiUrls.performanceGoalOperation, data),
    // 查看个人当前所有目标
    getPerformanceGoals: (periodId) => get(apiUrls.performanceGoals(periodId)),
    // 批量添加目标内容，用于复制
    putPerformanceBatchCopyGoals: (data, goalId) => put(apiUrls.performanceBatchCopyGoals(goalId), data),
    // 添加、修改、删除目标内容
    putPerformanceGoalContentNew: (data, goalId) => post(apiUrls.performanceGoalContentNew(goalId), data),
    // 团队目标
    getPerformanceTeamGoal: (periodId, goalId) => get(apiUrls.performanceTeamGoal(periodId, goalId)),
    // 团队目标 地图模式
    getPerformanceTeamGoalMap: (periodId, teamId) => get(apiUrls.performanceTeamGoalMap(periodId, teamId)),
    // 团队目标 列表模式
    getPerformanceTeamGoalList: (periodId, teamId, query) => get(apiUrls.performanceTeamGoalList(periodId, teamId), query),
    // 搜索个人目标
    searchPersonalGoal: (query) => get(apiUrls.performancePersonalGoalSearch, query),
    // 添加指标
    putPerformanceTarget: (data) => put(apiUrls.performanceTarget, data),
    // 修改指标
    postPerformanceTargetEdit: (data, id) => post(apiUrls.performanceTargetEdit(id), data),
    // 删除指标
    delPerformanceTargetDel: (id) => del(apiUrls.performanceTargetDel(id)),
    // 批量删除指标
    delPerformanceTargetBatch: (data) => post(apiUrls.performanceTargetBatchDel, data),
    // 批量移动指标
    movePerformanceTargetBatch: (data) => post(apiUrls.performanceTargetBatchMove, data),
    // 所有指标标签
    getPerformanceTargetLabel: () => get(apiUrls.performanceTargetLabel),
    // 创建标签
    putPerformanceTargetNewLabel: (data) => put(apiUrls.performanceTargetNewLabel, data),
    // 查看指标标签内所有数据
    getPerformanceTargetLabelData: (query) => get(apiUrls.performanceTargetLabelData, query),
    // 查看指定标签下的数据
    getPerformanceTargetLabelDataByIdAndTypeId: (query) => get(apiUrls.performanceTargetLabelDataByIdAndTypeId, query),
    // 修改标签
    postPerformanceTargetLabelUpdate: (data, id) => post(apiUrls.performanceTargetLabelUpdate(id), data),
    // 删除标签
    deletePerformanceTargetLabelDel: (id) => del(apiUrls.performanceTargetLabelDel(id)),
    // 查看所有指标类型
    getPerformanceTargetLabelType: () => get(apiUrls.performanceTargetLabelType),
    // 获取所有考评组
    getPerformanceGroup: () => get(apiUrls.performanceGroup),
    // 创建考评组
    putPerformanceGroup: (data) => put(apiUrls.performanceGroupNew, data),
    // 查看单个考评组
    getPerformanceGroupDetail: (id) => get(apiUrls.performanceGroupDetail(id)),
    // 修改考评组
    postPerformanceGroupUpdate: (data, id) => post(apiUrls.performanceGroupUpdate(id), data),
    // 删除考评组
    delPerformanceGroupDelete: (id) => del(apiUrls.performanceGroupDelete(id)),
    // 获取对应周期的组织结构
    getPeriodOrgs: (period) => get(apiUrls.performanceArchiveOrg(period)),
    // 获取对应周期的组织结构
    getPeriodOrgsAll: (period) => get(apiUrls.performanceArchiveOrgAll(period)),
    // 搜索用户
    searchUser: (params) => get(apiUrls.getUsersInSearch, params),
    // 考核中的周期
    getPerformanceEvaluateList: () => get(apiUrls.performanceEvaluateList),
    // 周期考核发起
    putPerformanceEvaluateStart: (data) => put(apiUrls.performanceEvaluateStart, data),
    // 周期考核催办
    postPerformanceEvaluatePush: (periodId) => post(apiUrls.performanceEvaluatePush(periodId)),
    // 周期考核更新
    patchPerformanceEvaluateUpdate: (data) => patch(apiUrls.performanceEvaluateUpdate, data),
    // 周期考核归档
    postPerformanceEvaluateArchive: (periodId) => post(apiUrls.performanceEvaluateArchive(periodId)),
    // 周期考核列表
    getPerformanceEvaluateNormalList: (query, periodId) => get(apiUrls.performanceEvaluateNormalList(periodId), query),
    // 修改员工绩效
    postPerformanceEvaluateKpiLevel: (data) => post(apiUrls.performanceEvaluateKpiLevel, data),
    // 员工自评
    postPerformanceEvaluateSelfEvaluate: (data) => post(apiUrls.performanceEvaluateSelfEvaluate, data),
    // 上级评价
    postPerformanceEvaluateSuperiorEvaluate: (data) => post(apiUrls.performanceEvaluateSuperiorEvaluate, data),
    // 待我处理的绩效评定
    getPerformanceEvaluateListOnMe: () => get(apiUrls.performanceEvaluateListOnMe),
    // 上级设置kpi等级
    setPerformanceEvaluateSetKpiLevels: (data) => post(apiUrls.performanceEvaluateSetKpiLevels, data),
    // 评估结果确认
    postPerformanceEvaluateKpiConfirm: (data) => post(apiUrls.performanceEvaluateKpiConfirm, data),
    // 获取员工反馈链接列表
    getReviewList: (periodId) => get(apiUrls.performanceReviewList(periodId)),
    // 生成反馈链接
    generateReviewUrl: (goalId) => post(apiUrls.performanceReviewGenerate(goalId), null),
    // 保存行为项评估数据
    putPerformanceEvaluateBehaviorSave: (data) => put(apiUrls.performanceEvaluateBehaviorSave, data),
    // 保存leader考评分数
    putPerformanceEvaluateLeaderSave: (data) => put(apiUrls.performanceEvaluateLeaderSave(data.goalId), data),
    // 团队看板列表
    getPerformanceEvaluateTeamList: (teamId, query) => get(apiUrls.performanceEvaluateTeamList(teamId), query),
    // 待我处理列表
    getPerformanceEvaluateWaitingForMe: (teamId, query) => get(apiUrls.performanceEvaluateWaitingForMe(teamId), query),
    // 绩效自评列表
    getPerformanceEvaluateSelfList: () => get(apiUrls.performanceEvaluateSelfList),
    // 保存自评分数
    putPerformanceEvaluateSelfSave: (data) => put(apiUrls.performanceEvaluateSelfSave(data.goalId), data),
    // 获取kpi result
    getPerformanceEvaluateKpiResult: (resultId) => get(apiUrls.performanceEvaluateKpiResult(resultId)),
    // 获取归档组织架构
    getPerformanceArchiveOrg: (params) => get(apiUrls.performanceEvaluateArchiveOrg, params),
    // 保存最终等级
    putPerformanceEvaluateFinalGradeSave: (data) => put(apiUrls.performanceEvaluateFinalGradeSave, data),
    // 获取绩效详情（改版的）
    getPerformanceGoalDetail: (goadId) => get(apiUrls.performanceEvaluateGoalDetail(goadId)),
    // 保存非绩效评分
    savePerformanceNonKpiScore: (data) => put(apiUrls.performanceEvaluateNonKpiScoreSave, data),
    // 是否还能进行绩效自评
    getPerformanceEvaluateDisable: () => get(apiUrls.performanceEvaluateDisable),
};
export const quit = {
    getQuitStaffSummaryCount: () => get(apiUrls.quitStaffSummaryCount),
    getQuitStaffList: (query) => get(apiUrls.quitStaffList, query),
    setQuitTime: (data) => post(apiUrls.quitTime, data),
    delQuitRecord: (quit_id) => post(apiUrls.quitRecord, { quit_id }),
};
export const kpiDashboard = {
    getKpiDashboardList: (query) => get(apiUrls.kpiDashboardList, query),
    postKpiDashboardScore: (data) => post(apiUrls.kpiDashboardScore, data),
};
export const plan = {
    getPlans: (query) => get(apiUrls.plan, query),
    getPlan: (id) => get(apiUrls.planDetail(id), null, {
        showBizError: false,
        showHttpError: false,
    }),
    updateLink: (data) => post(apiUrls.plan, data),
};
export const hrTools = {
    // 添加一个岗位
    postHrToolAddJob: (data) => post(apiUrls.hrToolAddJob, data),
    // 下载所有人汇报关系
    getDownloadLeaderRel: () => get(apiUrls.hrToolDownloadRel),
    // 下载指定类型用户信息
    getDownloadStaff: (query) => get(apiUrls.hrToolDownloadStaff, query),
    // 同步实习生Leader
    getSyncInternLeader: () => get(apiUrls.hrToolSyncInternLeader),
    // 修改离职实习生的leader
    setQuitInternLeader: (data) => patch(apiUrls.hrToolSetInternLeader, data),
    delHrToolDelCustomer: (data) => del(apiUrls.hrToolDelCustomer, data),
    // 修改实习生的考勤标签
    setTraineeLabel: (data) => post(apiUrls.hrToolSetInternLabel, data),
};
export const financeTools = {
    // 刷新
    getFinanceToolRefreshBeisenSalaryEmployee: () => get(apiUrls.financeToolRefreshBeisenSalaryEmployee),
};
export const offer = {
    // 添加offer
    addNewOffer: (data) => post(apiUrls.offerAdd, data),
    // 获取offer
    getOffer: (offerId) => get(apiUrls.offerGet(offerId)),
    // 编辑offer
    editOffer: (id, data) => patch(apiUrls.offerEdit(id), data),
    // 获取offer列表
    getOfferList: (query) => get(apiUrls.offerList, query),
    // 设置offer薪资
    setOfferSalary: (data) => patch(apiUrls.offerSalarySet, data),
    // 设置offer渠道的主理人
    setOfferHost: (candidateId, referer_id) => patch(apiUrls.offerChannelHostSet, {
        offer_id: candidateId,
        referer_id,
    }),
    // 设置薪资隐藏权限
    setOfferSalaryHidden: (data) => patch(apiUrls.offerSetSalaryHidden, data),
    // 获取申请渠道
    getOfferRequestChannelList: (q) => get(apiUrls.offerGetRequestChannelList, q),
    // 设置申请渠道
    setOfferRequestChannel: (data) => patch(apiUrls.offerSetRequestChannel, data),
    // 设置薪资隐藏权限
    offerSetInternSalaryHiddenFlag: (data) => patch(apiUrls.offerSetInternSalaryHiddenFlag, data),
};
export const cost = {
    // 检测费用状态
    checkCostStatus: (offerId) => get(apiUrls.costCheckFeeStatus(offerId)),
    // 获取费用列表
    getCostList: (query) => get(apiUrls.costList, query),
    // 设置内推总费用
    setIntroducingTotalFee: (data) => patch(apiUrls.costIntroducingSet, data),
    // 获取费用详情列表
    getCostDetailsList: (query) => get(apiUrls.costDetailsList, query),
    // 发放费用
    confirmCostPay: (data) => patch(apiUrls.costPay, data),
    // 取消发放费用
    cancelCostPay: (ids) => patch(apiUrls.costPayCancel, {
        costDetailsIdSet: ids,
    }),
    // 内推费用发放记录
    getIntroducingPayRecordList: (query) => get(apiUrls.introducingPayRecordList, query),
    // 设置猎头付费方案
    setHunterCostPlan: (data) => patch(apiUrls.costSetHunterCostPlan, data),
    // 获取费用规则列表
    getCostRuleList: (query) => get(apiUrls.costRuleList, query),
    // 获取猎头费用发放记录
    getHunterPayRecordList: (q) => get(apiUrls.hunterPayRecordList, q),
    checkHunterFeePayedOrNot: (query) => get(apiUrls.checkHunterFeePayedOrNot, query),
    // 编辑费用规则
    updateCostRule: (pid, data) => patch(apiUrls.editCostRule(pid), data),
    // 添加费用规则
    addCostRULE: (data) => post(apiUrls.addCostRule, data),
    // 检测姓名是否重复
    checkDuplName: (query) => get(apiUrls.checkDuplName, query),
    // 删除方案
    deleteCostRule: (pid) => del(apiUrls.deleteCostRule(pid)),
    // 费用统计
    getSummaryFeeStatistics: (query) => get(apiUrls.summaryFeeStatistics, query),
    // 下载费用统计
    downloadSummaryStatistics: (query) => get(apiUrls.summaryStatisticsDownload, query),
    // 费用趋势
    getSummaryTrendStatistics: (query) => get(apiUrls.summaryTrendStatistics, query),
};
// 入职同步
export const boardSync = {
    getAdministrationOfferList: (query) => get(apiUrls.administrationOfferList, query),
    patchAdministrationDeviceModel: (data) => patch(apiUrls.administrationDeviceModelSet, data),
    patchAdministrationDevicePrepare: (data) => patch(apiUrls.administrationDevicePrepareSet, data),
    patchAdministrationInductionWelfare: (data) => patch(apiUrls.administrationInductionWelfareSet, data),
};
// 三方账号
export const thirdPartyAccount = {
    getCustomerList: (query) => get(apiUrls.administration3AccountCustomerList, query),
    patchWifiAccount: (data) => patch(apiUrls.administrationWifiSet, data),
    patchDidiAccount: (data) => patch(apiUrls.administrationDiDiSet, data),
    patchAccess: (data) => patch(apiUrls.administrationAccessSet, data),
    patchAccountRemark: (data) => patch(apiUrls.administrationAccountRemarkSet, data),
};
export const lawAccount = {
    getCompanyList: () => get(apiUrls.lawAccountCompanyList),
    getLoginMethods: () => get(apiUrls.lawAccountLoginMethods),
    getTeams: () => get(apiUrls.lawAccountTeams),
    getEmployees: () => get(apiUrls.lawAccountEmployees),
    postNewPlatform: (data) => post(apiUrls.lawAccountNewPlatform, data),
    putPlatform: (data) => put(apiUrls.lawAccountUpdatePlatform(data.id), data),
    delPlatform: (id) => del(apiUrls.lawAccountDelPlatform(id)),
    getPlatformAll: () => get(apiUrls.lawAccountPlatformAll),
    getPlatformList: (query) => get(apiUrls.lawAccountPlatformList, query),
    postNewAccount: (data) => post(apiUrls.lawAccountNewAccount, data),
    putAccountBasic: (data) => put(apiUrls.lawAccountUpdateAccountBasic(data.id), data),
    putAccountPwd: (id, data) => put(apiUrls.lawAccountUpdateAccountPwd(id), data),
    delAccount: (id) => del(apiUrls.lawAccountDelAccount(id)),
    getAccountPwd: (id, query) => get(apiUrls.lawAccountLookPassword(id), query),
    getAccountHistoryPwd: (id, query) => get(apiUrls.lawAccountHistoryPwd(id), query),
    getAccountHistoryPwdBatch: (data) => post(apiUrls.lawAccountHistoryPwdBatch(data.accountId), data),
    getAccountBasic: (id) => get(apiUrls.lawAccountDetailBasic(id)),
    getAccountUsageList: (id, query) => get(apiUrls.lawAccountUsageList(id), query),
    getAccountHistoryList: (id, query) => get(apiUrls.lawAccountHistoryList(id), query),
    getOwnedAccountList: (query) => get(apiUrls.lawOwnedAccountList, query),
    getAppliedAccountList: (query) => get(apiUrls.lawAppliedAccountList, query),
    putAccountStatus: (data) => put(apiUrls.lawAccountStatusChange(data.id), data),
    putAccountApplyStatus: (id, data) => put(apiUrls.lawAccountAppliedStatus(id, data.appliedId), data),
    postAccountApply: (id, data) => post(apiUrls.lawAccountApply(id), data),
    getAccountApplyList: (query) => get(apiUrls.lawAccountApplyList, query),
    getAccountApplyHistoryList: (query) => get(apiUrls.lawAccountApplyHistoryList, query),
    putAccountTransfer: (data) => put(apiUrls.lawAccountTransfer(data.accountId), data),
    putAccountTransferStatus: (id, data) => put(apiUrls.lawAccountChangeTransferStatus(id, data.transferId), data),
    getAccountTransferList: (query) => get(apiUrls.lawAccountTransferList, query),
    getAccountTransferHistoryList: (query) => get(apiUrls.lawAccountTransferHistoryList, query),
    getAccountAppliedProcessPendingList: (query) => get(apiUrls.lawAccountAppliedProcessPendingList, query),
    getAccountAppliedProcessHistoryList: (query) => get(apiUrls.lawAccountAppliedProcessHistoryList, query),
    getAccountAppliedProcessPendingCount: () => get(apiUrls.lawAccountAppliedProcessPendingCount),
    getAccountTransferCount: () => get(apiUrls.lawAccountTransferCount),
    getLawAccountPublicEditable: (id) => get(apiUrls.lawAccountPublicEditable(id)),
    getLawComplianceConfirmDuplicated: () => get(apiUrls.lawComplianceConfirmDuplicated),
    postLawComplianceConfirm: (data) => post(apiUrls.lawComplianceConfirm, data),
    postAssignAccount: (id, data) => post(apiUrls.lawAccountAssign(id), data),
};
// 团队管理
export const teamManage = {
    getTeamLogo: () => get(apiUrls.teamLogo),
    getTeamInfo: () => get(apiUrls.teamInfo),
    getTeamMemberStatisticsSummary: (teamId) => get(apiUrls.teamStatisticsSummary, { teamId }),
    getTeamOnJobMemberStatistics: (query) => get(apiUrls.teamCustomerStatistics, query),
    getTeamChangeTrend: (teamId) => get(apiUrls.teamChangeTrend, { teamId }),
    getTeamCandidateList: (query) => get(apiUrls.teamCandidateList, query),
    getTeamQuitList: (query) => get(apiUrls.teamQuitingList, query),
};
// 员工管理
export const staffManageActions = {
    // 获取筛选条件
    getConditionList: (q) => get(apiUrls.staffManagementFilteredConditionList, q),
    // 获取员工列表
    postStaffManagementList: (d) => post(apiUrls.staffManagementList, d),
    // 导出结果
    postStaffManagementListExport: (d) => requestFileByPost(apiUrls.staffManagementListExport, d),
    // 设置岗位
    postStaffManagementSetJob: (d) => post(apiUrls.staffManagementSetJob, d),
    // 设置部门
    postStaffManagementSetOrg: (d) => post(apiUrls.staffManagementSetOrg, d),
    // 离职原因
    getStaffManagementQuitReason: () => get(apiUrls.staffManagementQuitReason),
};
export const legal = {
    getAssetsSearchApplyRecord: (params) => get(apiUrls.assetsSearchApplyRecord, params),
    getassetsSearchCertificateManage: (params) => get(apiUrls.assetsSearchCertificateManage, params),
    getAssetsSearchCertificate: (params) => get(apiUrls.assetsSearchCertificate, params),
    postAssetsSearchCertificate: (d) => post(apiUrls.assetsAddCertificate, d),
    getAssetsGetCertificateById: (p) => get(apiUrls.assetsGetCertificateById, p),
    patchAssetsUpdateCertificate: (p) => patch(apiUrls.assetsUpdateCertificate, p),
    postAssetsDeleteCertificate: (p) => post(apiUrls.assetsDeleteCertificate, p),
    postAssetsApplyCertificate: (p) => post(apiUrls.assetsApplyCertificate, p),
    getAssetsCheckApplyRecordAuditFlag: (p) => get(apiUrls.assetsCheckApplyRecordAuditFlag, p),
    postAssetsApplyCertificateAudit: (p) => post(apiUrls.assetsApplyCertificateAudit, p),
};
export const attendance = {
    getAttendanceGetVacationDaily: (params) => get(apiUrls.attendanceGetVacationDaily, params),
    getAttendanceGetVacationType: () => get(apiUrls.attendanceGetVacationType),
    getAttendanceGetVacationMonth: (params) => get(apiUrls.attendanceGetVacationMonth, params),
    patchAttendanceUpdateMonthInfo: (p) => patch(apiUrls.attendanceUpdateMonthInfo, p),
    patchAttendanceUpdateMonthInfoTwo: (p) => patch(apiUrls.attendanceUpdateMonthInfoTwo, p),
    getAttendanceUpdateNotifyMis: () => get(apiUrls.attendanceUpdateNotifyMis),
    // attendanceUpdateNotifyMis
};
//  apiUrls.hrEmploymentPlanSetCellValueOfCompany
export const hrBoard = {
    getHrEmploymentPlanGetTeamsNumberOfPeopleInfo: (p) => get(apiUrls.hrEmploymentPlanGetTeamsNumberOfPeopleInfo, p),
    postHrEmploymentPlanSetExplainCellValueOfCompany: (p) => patch(apiUrls.hrEmploymentPlanSetExplainCellValueOfCompany, p),
    patchHrEmploymentPlanSetCellValueOfCompany: (p) => patch(apiUrls.hrEmploymentPlanSetCellValueOfCompany, p),
    getHrEmploymentPlanGetAllNumberOfPeopleTrendInfo: (p) => get(apiUrls.hrEmploymentPlanGetAllNumberOfPeopleTrendInfo, p),
    patchHrEmploymentPlanSetCellValueOfAllNumberOfPeopleTrend: (p) => patch(apiUrls.hrEmploymentPlanSetCellValueOfAllNumberOfPeopleTrend, p),
};
export const fxList = {
    getBeisenSalaryEmployeeGetSalaryEmployeeList: (p) => get(apiUrls.beisenSalaryEmployeeGetSalaryEmployee, p),
    getBeisenSalaryEmployeeGetSalaryEmployeeInfoByCustomerId: (p) => get(apiUrls.beisenSalaryEmployeeGetSalaryEmployeeInfoByCustomerId, p),
    postBeisenSalaryEmployeeAddSalaryEmployeeId: (p) => post(apiUrls.beisenSalaryEmployeeAddSalaryEmployeeId, p),
    // beisenSalaryEmployeeUpdateSalaryEmployee
    patchBeisenSalaryEmployeeUpdateSalaryEmployee: (p) => patch(apiUrls.beisenSalaryEmployeeUpdateSalaryEmployee, p),
    deletebeisenSalaryEmployeeDeleteSalaryEmployee: (id) => del(`${apiUrls.beisenSalaryEmployeeDeleteSalaryEmployee}?id=${id}`),
};
