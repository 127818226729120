import React from 'react';
import { Button, Col, Row } from 'antd';
const FilterButtons = (props) => {
    const { onSubmit } = props;
    return (<Row>
      <Col style={{ textAlign: 'right' }} span={3}>
        <Button style={{ marginTop: '16px' }} type='primary' onClick={onSubmit}>过滤</Button>
      </Col>
    </Row>);
};
export default FilterButtons;
