import React from 'react';
import style from './style.less';
const ListTotal = (props) => {
    const { count, widgets } = props;
    return (<section className={style.total}>
      <section>
        <span>所有结果</span>
        <span>（{count || 0} 条）</span>
      </section>
      <span className='placeholder'/>
      {widgets ? <section className='widgets'>{widgets}</section> : null}
    </section>);
};
export default ListTotal;
