import React from 'react';
import { Form, Input, Popover } from 'antd';
import { isEmpty, regex } from '@src/tools/util';
import { QuestionCircleOutlined } from '@ant-design/icons';
import style from './style.less';
const rules = [{
        validator: (_rule, value) => {
            if (!isEmpty(value) && !regex.id(value)) {
                return Promise.reject('格式不正确');
            }
            return Promise.resolve();
        },
    }];
const CustomInput = (props) => (<section className={style.id}>
      <Input {...props}/>
      <Popover title={null} arrowPointAtCenter content="无「中华人民共和国居民身份证」的同学，可填写「居住证」或「内地通行证」号码" placement="topRight">
        <QuestionCircleOutlined />
      </Popover>
    </section>);
const IDField = (props) => (<Form.Item validateFirst {...props} rules={props.rules ? [...props.rules, ...rules] : rules}>
      <CustomInput autoComplete='off' placeholder='请输入'/>
    </Form.Item>);
IDField.defaultProps = {
    label: '身份证',
};
export default IDField;
