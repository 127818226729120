import React from 'react';
import Crash from '@src/components/Crash';
import Placeholder from '@src/components/Placeholder';
const defaultFallback = () => <Placeholder text='Oops, something went wrong o(╥﹏╥)o !'/>;
const securityCrash = (TargetComponent, fallback) => class SecurityCrash extends React.Component {
    constructor() {
        super(...arguments);
        this.fallback = (error) => {
            if (fallback) {
                return fallback(error);
            }
            return defaultFallback();
        };
    }
    render() {
        return (<Crash fallback={this.fallback}>
          <TargetComponent {...this.props}/>
        </Crash>);
    }
};
export default securityCrash;
