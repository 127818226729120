import React from 'react';
import { Form, Radio } from 'antd';
const FloatingBonusField = (props) => (<Form.Item {...props}>
      <Radio.Group>
        <Radio value={true}>有</Radio>
        <Radio value={false}>无</Radio>
      </Radio.Group>
    </Form.Item>);
FloatingBonusField.defaultProps = {
    initialValue: false,
    label: '浮动奖金',
};
export default FloatingBonusField;
