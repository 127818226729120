import React, { useCallback, useEffect, useState, } from 'react';
import { Form, Input } from 'antd';
import { getSafe } from '@src/tools/util';
import moment from 'moment';
import { ContractTypes, contractTypesValue } from '@src/pages/components/ContractTypeSelect';
import { recruit } from '@src/api/actions';
import { SalaryPaymentKind, StaffType } from '@src/api/interface';
import pinyin from 'pinyin';
import StaffCommonModal, { getInitEmailSuffix } from '@src/pages/HR/Organization/Struct/modals/StaffCommon';
import { fieldNames } from '@src/pages/HR/Organization/Struct/modals/StaffCommon/fields/config';
import { isOutSourcingType, isPracticeType, isRegularLargeType, isRegularPractice, isLargeScaleRegularPractice, isRegularType, } from '@src/pages/HR/Organization/Struct/formUtils';
const OnBoardingAndEditModal = (props) => {
    const { result, afterSubmit, apiAction, title, saveText, hideCorpEmail, formatValues, mode, canSelectEmail, fromOfferTwo, } = props;
    const isEdit = mode === 'edit';
    const [form] = Form.useForm();
    const [stickyMobile, updateStickyMobile] = useState();
    const staffType = getSafe(result, 'data.staffType');
    const record = getSafe(result, 'record');
    const onClose = useCallback(() => {
        result.hide();
        form.resetFields();
    }, [result.hide, form]);
    useEffect(() => {
        if (result.visible) {
            const name = getSafe(record, 'name');
            const workPlace = getSafe(record, 'work_place');
            const boardingUt = getSafe(record, 'boarding_time_ut');
            const rs = {
                [fieldNames.staffType]: staffType,
                [fieldNames.staffName]: name,
                [fieldNames.staffMobile]: getSafe(record, 'mobile'),
                [fieldNames.personal_email]: getSafe(record, 'personal_email'),
                [fieldNames.job_id]: getSafe(record, 'job_id'),
                [fieldNames.teamId]: getSafe(record, 'staff_team_id') || undefined,
                [fieldNames.work_place]: workPlace ? workPlace.split('/') : [],
                [fieldNames.candidate_id]: getSafe(record, 'candidate_id'),
                [fieldNames.contract_start_ut]: boardingUt ? moment(boardingUt * 1000) : undefined,
                [fieldNames.company_id]: getSafe(record, 'company_id') || undefined,
                [fieldNames.entry_time_ut]: boardingUt ? moment(boardingUt * 1000) : undefined,
            };
            const contractType = getSafe(record, 'contract_type');
            if (isRegularType(staffType)) {
                if (staffType === StaffType.regular || staffType === StaffType.regularPractice || staffType === StaffType.largeScaleRegular) {
                    const pinyinStr = pinyin(name || '', {
                        heteronym: false,
                        segment: false,
                        style: pinyin.STYLE_NORMAL,
                    });
                    rs[fieldNames.corpEmail] = `${(pinyinStr || []).join('')}${getInitEmailSuffix(staffType)}`;
                }
                rs[fieldNames.contract_type] = contractType || contractTypesValue[ContractTypes.solid];
                rs[fieldNames.contract_end_ut] = boardingUt ? moment(rs[fieldNames.contract_start_ut]).add(3, 'years').subtract(1, 'day') : undefined;
                rs[fieldNames.turn_regular_time_ut] = boardingUt ? moment(rs[fieldNames.contract_start_ut]).add(6, 'months') : undefined;
                if (isRegularLargeType(staffType)) {
                    rs[fieldNames.floatingBonus] = getSafe(record, 'float_bonus_flag');
                    rs[fieldNames.probation_salary] = getSafe(record, 'probation_salary') || undefined;
                    rs[fieldNames.regular_salary] = getSafe(record, 'turn_regular_salary') || undefined;
                }
                else if (isRegularPractice(staffType)) {
                    rs[fieldNames.floatingBonus] = getSafe(record, 'float_bonus_flag');
                    rs[fieldNames.salary_intern] = getSafe(record, 'salary') || undefined;
                    rs[fieldNames.salary_intern_type] = getSafe(record, 'salary_type') || SalaryPaymentKind.byDay;
                }
                else if (isLargeScaleRegularPractice(staffType)) {
                    rs[fieldNames.salary_intern] = getSafe(record, 'salary') || undefined;
                }
            }
            if (isOutSourcingType(staffType)) {
                rs[fieldNames.floatingBonus] = getSafe(record, 'float_bonus_flag');
                rs[fieldNames.bank_number] = getSafe(record, 'customer_info.personalInfo.accountInfo.bankNumber');
                rs[fieldNames.bank_name] = getSafe(record, 'customer_info.personalInfo.accountInfo.bank');
                rs[fieldNames.contract_type] = contractType || contractTypesValue[ContractTypes.outsourcing];
                rs[fieldNames.probation_salary] = getSafe(record, 'probation_salary') || undefined;
                rs[fieldNames.regular_salary] = getSafe(record, 'turn_regular_salary') || undefined;
                rs[fieldNames.contract_end_ut] = boardingUt ? moment(rs[fieldNames.contract_start_ut]).add(2, 'years').subtract(1, 'day') : undefined;
                rs[fieldNames.turn_regular_time_ut] = boardingUt ? moment(rs[fieldNames.contract_start_ut]).add(2, 'months') : undefined;
            }
            if (isPracticeType(staffType)) {
                rs[fieldNames.floatingBonus] = getSafe(record, 'float_bonus_flag');
                rs[fieldNames.contract_type] = contractType || contractTypesValue[ContractTypes.practice];
                rs[fieldNames.salary_intern] = getSafe(record, 'salary') || undefined;
                rs[fieldNames.salary_intern_type] = getSafe(record, 'salary_type') || SalaryPaymentKind.byDay;
                const quitTimeUt = getSafe(record, 'quit_time_ut');
                if (quitTimeUt) {
                    rs[fieldNames.contract_end_ut] = moment(quitTimeUt * 1000);
                }
                else if (boardingUt) {
                    rs[fieldNames.contract_end_ut] = moment(rs[fieldNames.contract_start_ut]).add(3, 'months').subtract(1, 'day');
                }
            }
            if (isEdit) {
                const contract_start_ut = getSafe(record, 'contract_start_ut');
                const contract_end_ut = getSafe(record, 'contract_end_ut');
                const turn_regular_time_ut = getSafe(record, 'turn_regular_time_ut');
                rs[fieldNames.contract_start_ut] = contract_start_ut ? moment(contract_start_ut * 1000) : undefined;
                rs[fieldNames.contract_end_ut] = contract_end_ut ? moment(contract_end_ut * 1000) : undefined;
                rs[fieldNames.turn_regular_time_ut] = turn_regular_time_ut ? moment(turn_regular_time_ut * 1000) : undefined;
            }
            updateStickyMobile(rs[fieldNames.staffMobile]);
            form.setFieldsValue(rs);
        }
    }, [result.visible, record]);
    return (<StaffCommonModal fromOfferTwo={fromOfferTwo} record={record} title={title} visible={result.visible} onClose={onClose} onOk={afterSubmit} apiAction={apiAction} hideCorpEmail={hideCorpEmail} orgId={getSafe(record, 'staff_team_id') || undefined} stickyMobile={stickyMobile} formatValues={formatValues} canSelectEmail={canSelectEmail} extraFields={<Form.Item name={fieldNames.candidate_id} style={{ margin: 0, height: 0 }}>
          <Input type='hidden'/>
        </Form.Item>} form={form} staffType={staffType} disableStaffTypeField disableContinueAdd saveText={saveText}/>);
};
OnBoardingAndEditModal.defaultProps = {
    apiAction: recruit.postRecruitBoardingTurnRegular,
    title: '员工入职',
    saveText: '确定',
    mode: 'onBoarding',
};
export default OnBoardingAndEditModal;
