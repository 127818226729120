import { TeamOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { lazy } from 'react';
import { gluer } from 'femo';
import { User } from '@src/api/interface';
const initRoad = {
    name: '法务系统',
    path: '/dep-legal',
    permissions: [User.LawAuthKey.module_invisible_assets],
    ignore_permissions: true,
    icon: TeamOutlined,
    component: () => <Redirect to='/dep-legal/apply'/>,
    subRoads: [
        {
            name: '无形资产申请记录',
            path: '/apply-records',
            component: lazy(() => import('./IntangibleAssets/ApplyRecords/RecordsPerson')),
        },
        {
            name: '无形资产申请',
            path: '/apply',
            component: lazy(() => import('./IntangibleAssets/Manage/Apply')),
        },
        {
            name: '无形资产管理',
            path: '/manage',
            permissions: [User.LawAuthKey.menu_invisible_assets_management],
            //  permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./IntangibleAssets/Manage/index')),
        },
        {
            name: '无形资产申请管理',
            path: '/apply-records-manage',
            permissions: [User.LawAuthKey.menu_invisible_assets_management],
            //  permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./IntangibleAssets/ApplyRecords/RecordsManage')),
        },
        // src/pages/Law/IntangibleAssets/ApplyRecords/RecordsManage.tsx
    ],
};
const road = gluer(initRoad);
export default road;
