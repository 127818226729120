import React from 'react';
import { Form } from 'antd';
import ContractTypeSelect from '@src/pages/components/ContractTypeSelect';
const ContractTypeField = (props) => (<Form.Item {...props}>
      <ContractTypeSelect placeholder='请选择'/>
    </Form.Item>);
ContractTypeField.defaultProps = {
    label: '合同类型',
};
export default ContractTypeField;
