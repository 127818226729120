import React from 'react';
import { Row, Col } from 'antd';
import StaffTypeField from '@src/pages/HR/formFields/StaffType';
import StaffNameField from '@src/pages/HR/formFields/Name';
import MobileField from '@src/pages/HR/formFields/Mobile';
import OrganizationField from '@src/pages/HR/formFields/Organization';
import { fieldNames } from '@src/pages/HR/Organization/Struct/modals/StaffCommon/fields/config';
import { getRules } from '@src/pages/HR/Organization/Struct/formUtils';
const PublicForm = (props) => {
    const { orgId, stickyMobile, disableStaffTypeField, extraFields, typeFilter, initialStaffType, checkMobile, } = props;
    return (<>
      {extraFields}
      <Row>
        <Col span={12}>
          <StaffTypeField initialValue={initialStaffType} typeFilter={typeFilter} disabled={disableStaffTypeField} name={fieldNames.staffType} rules={getRules('请选择')}/>
        </Col>
      </Row>
      <header>个人信息</header>
      <Row>
        <Col span={12}>
          <StaffNameField rules={getRules('请输入')} name={fieldNames.staffName}/>
        </Col>
        <Col span={12}>
          <MobileField needCheck={checkMobile} stickyMobile={stickyMobile} rules={getRules('请输入')} name={fieldNames.staffMobile}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <OrganizationField rules={getRules('请选择')} disableTop initialValue={orgId} name={fieldNames.teamId}/>
        </Col>
      </Row>
    </>);
};
PublicForm.defaultProps = {
    checkMobile: true,
};
export default PublicForm;
