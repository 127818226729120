import { AlertOutlined } from '@ant-design/icons';
import { lazy } from 'react';
import { gluer } from 'femo';
import { User } from '@src/api/interface';
const initRoad = {
    path: '/dengbao',
    name: '日志审计',
    icon: AlertOutlined,
    permissions: [User.ModuleAuthKey.module_dengbao],
    component: lazy(() => import('./index')),
};
const road = gluer(initRoad);
export default road;
