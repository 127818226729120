import { TeamOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { lazy } from 'react';
import { gluer } from 'femo';
const initRoad = {
    name: '我的团队',
    path: '/dep-team',
    icon: TeamOutlined,
    component: () => <Redirect to='/dep-team/detail'/>,
    subRoads: [{
            name: '团队详情',
            path: '/detail',
            component: lazy(() => import('./TeamMange/MyTeam/TeamSummary')),
        }, {
            name: '即将入职',
            path: '/candidate',
            component: lazy(() => import('./TeamMange/MyTeam/Candidate')),
        }, {
            name: '即将离职',
            path: '/quit',
            component: lazy(() => import('./TeamMange/MyTeam/Quiting')),
        }],
};
const road = gluer(initRoad);
export default road;
