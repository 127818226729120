import React from 'react';
import { Layout } from 'antd';
import SiderControl from '@src/components/Sider/SiderControl';
import DispatchRoute from '@src/components/Routes/DispatchRoute';
import HeaderControl from '@src/components/Header/HeaderControl';
// import Scrollbar from '@src/components/Scrollbar';
// import { isMobile } from '@src/tools/util';
import style from './style.less';
const { Content } = Layout;
const App = () => {
    const content = (<Content className={style.mainLayout}>
      <DispatchRoute />
    </Content>);
    return (<Layout hasSider style={{ height: '100%' }}>
      <SiderControl />
      <Layout className={style.contentWrap}>
        <HeaderControl />
        {content}
      </Layout>
    </Layout>);
};
export default App;
