import { common } from '@src/api/actions';
import { getSafe } from '@src/tools/util';
import { genRaceQueue } from 'femo';
import { sortTree } from '@src/pages/HR/Organization/Struct/OrgTree/service';
import model from './model';
const treeRaceQueue = genRaceQueue();
export const fetchOrgTree = () => {
    const p = model.tree(() => common.getOrgStruct().then((res) => {
        const org = getSafe(res, 'data.team_tree');
        const tree = [org];
        sortTree(tree);
        return tree;
    }));
    treeRaceQueue.push(p);
    return p;
};
