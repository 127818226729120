import { SettingOutlined } from '@ant-design/icons';
import { User } from '@src/api/interface';
import { RoadMapType } from '@src/pages/interface';
import { gluer } from 'femo';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
const initialRoad = {
    name: '权限管理',
    path: '/auth',
    icon: SettingOutlined,
    permissions: [User.ModuleAuthKey.module_auth],
    component: () => <Redirect to='/auth/depart/list'/>,
    subRoads: [{
            type: RoadMapType.fallen,
            name: '',
            path: '/depart',
            component: () => <Redirect to='/auth/depart/list'/>,
            subRoads: [{
                    type: RoadMapType.fallen,
                    name: '',
                    path: '/list',
                    component: lazy(() => import('./Depart/List')),
                }, {
                    type: RoadMapType.fallen,
                    name: '{depart_name}',
                    path: '/service',
                    realPath: '/auth/depart/service/list',
                    component: (props) => (<Redirect to={`/auth/depart/service/list${props.location.search}`}/>),
                    subRoads: [{
                            type: RoadMapType.fallen,
                            path: '/list',
                            component: lazy(() => import('./Service/List')),
                        }, {
                            type: RoadMapType.fallen,
                            name: '新建服务',
                            path: '/new',
                            component: lazy(() => import('./Service/New')),
                        }, {
                            type: RoadMapType.fallen,
                            name: '{service_name}',
                            path: '/edit',
                            component: lazy(() => import('./Service/Edit')),
                        }, {
                            type: RoadMapType.fallen,
                            name: '管理权限组({service_name})',
                            path: '/group',
                            realPath: '/auth/depart/service/group/list',
                            subRoads: [{
                                    type: RoadMapType.fallen,
                                    name: '',
                                    path: '/list',
                                    component: lazy(() => import('./Group/List')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '新建权限组',
                                    path: '/new',
                                    component: lazy(() => import('./Group/New')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '编辑权限组',
                                    path: '/edit',
                                    component: lazy(() => import('./Group/Edit')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '{group_name}',
                                    path: '/inner',
                                    realPath: '/auth/depart/service/group/list',
                                    subRoads: [{
                                            type: RoadMapType.fallen,
                                            name: '管理组内权限',
                                            path: '/res/list',
                                            component: lazy(() => import('./InnerRes/List')),
                                        }, {
                                            type: RoadMapType.fallen,
                                            name: '添加新权限',
                                            path: '/res/new',
                                            component: lazy(() => import('./InnerRes/New')),
                                        }, {
                                            type: RoadMapType.fallen,
                                            name: '组内用户',
                                            path: '/user/list',
                                            component: lazy(() => import('./InnerUser/List')),
                                        }],
                                }],
                        }, {
                            type: RoadMapType.fallen,
                            name: '{service_name}',
                            path: '/resource',
                            realPath: '/auth/depart/service/resource/list',
                            subRoads: [{
                                    type: RoadMapType.fallen,
                                    name: '',
                                    path: '/list',
                                    component: lazy(() => import('./Resource/List')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '新建权限',
                                    path: '/new',
                                    component: lazy(() => import('./Resource/New')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '编辑权限',
                                    path: '/edit',
                                    component: lazy(() => import('./Resource/Edit')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '被引用组',
                                    path: '/quote',
                                    component: lazy(() => import('./Resource/Quote')),
                                }, {
                                    type: RoadMapType.fallen,
                                    name: '权限用户',
                                    path: '/user/list',
                                    component: lazy(() => import('./Resource/User')),
                                }],
                        }, {
                            type: RoadMapType.fallen,
                            name: '{service_name}',
                            path: '/history',
                            component: lazy(() => import('./History/List')),
                        }],
                }],
        }],
};
const road = gluer(initialRoad);
export default road;
