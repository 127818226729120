export const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
export const fieldNames = {
    candidate_id: 'candidate_id',
    salary_intern: 'salary_intern',
    staffType: 'customer_type',
    staffName: 'name',
    staffMobile: 'mobile',
    teamId: 'team_id',
    corpEmail: 'corp_email',
    contract_start_ut: 'contract_start_ut',
    contract_end_ut: 'contract_end_ut',
    personal_email: 'personal_email',
    job_id: 'job_id',
    work_place: 'work_place',
    company_id: 'company_id',
    contract_type: 'contract_type',
    salary_intern_type: 'salary_intern_type',
    turn_regular_time_ut: 'turn_regular_time_ut',
    bank_number: 'bank_number',
    bank_name: 'bank_name',
    probation_salary: 'salary_probation',
    probation_salary_type: 'salary_probation_type',
    regular_salary: 'salary_regular',
    regular_salary_type: 'salary_regular_type',
    id_number: 'id_number',
    entry_time_ut: 'entry_time_ut',
    need_probation: 'need_probation',
    // floatingBonus: 'floating_salary',
    floatingBonus: 'float_bonus_flag',
};
const offerFieldNames = {};
const fieldNamesMap = {
    default: fieldNames,
    offer: offerFieldNames,
};
export default fieldNamesMap;
