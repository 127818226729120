import React, { useCallback } from 'react';
import { Form } from 'antd';
import ConditionSelect from '@src/pages/components/ConditionSelect';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import ChannelCheckbox from '@src/pages/components/filter/ChannelCheckbox';
const ChannelFilterField = (props) => {
    const { disabled, ...rest } = props;
    const renderCondition = useCallback((_v, condition, onChange) => (<ConditionSelect disabled={disabled} value={condition} onChange={onChange}/>), []);
    const renderValue = useCallback((value, _c, onChange) => (<ChannelCheckbox disabled={disabled} value={value} onChange={onChange}/>), []);
    return (<Form.Item label='申请渠道' {...rest} {...fieldLayout}>
      <FilterFieldCompose renderCondition={renderCondition} renderValue={renderValue}/>
    </Form.Item>);
};
export default ChannelFilterField;
