import React, { useCallback, useEffect, useState, } from 'react';
import { message, DatePicker, } from 'antd';
import { getYMD } from '@src/tools/util';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
import moment from 'moment';
import useSetTime from './useSetTime';
import style from './style.less';
const SetTime = (props) => {
    const { id, time_ut, afterSubmit, request, formatData, allowClear, } = props;
    const result = useSetTime();
    const [value, updateValue] = useState(() => (time_ut ? moment(time_ut * 1000) : undefined));
    useEffect(() => {
        updateValue(time_ut ? moment(time_ut * 1000) : undefined);
    }, [time_ut]);
    const [confirm] = useRequestWithCallback(request, false, false);
    const onChange = useCallback((d) => {
        const value = Math.floor(moment(d).valueOf() / 1000);
        confirm(formatData({
            id,
            time: value || 0,
        })).then(() => {
            if (afterSubmit) {
                afterSubmit();
            }
            updateValue(d);
            message.success('设置成功');
        });
        result.hide();
    }, [confirm, id, afterSubmit, result.hide, formatData]);
    const picker = (<DatePicker showToday={false} onBlur={result.hide} allowClear={allowClear} defaultOpen={result.visible} autoFocus value={value} onChange={onChange}/>);
    return (<section onClick={(e) => {
            e.stopPropagation();
            e.cancelable = true;
        }}>
      {result.visible ? (<section style={{ height: '22px', marginTop: '-5px' }}>
            {picker}
          </section>) : (<section style={{ textAlign: 'center' }} onClick={() => result.show()}>
            {time_ut ? (<span className={style.time}>
                {getYMD(time_ut * 1000)}
                <span className='edit'>修改</span>
              </span>) : <a>设置</a>}
          </section>)}
    </section>);
};
SetTime.defaultProps = {
    allowClear: false,
};
export default SetTime;
