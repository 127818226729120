import React from 'react';
import { Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import style from './style.less';
const LeaderWarning = (props) => {
    const { name, hideTodo, hidePopover } = props;
    const content = (<section className={style.content}>
      <section>该员工leader无法找到，可能原因：</section>
      <p>1、姓名输入错误无法匹配；</p>
      <p>2、存在多个同名leader；</p>
      <p>3、该员工在组织架构中没有负责的部门。</p>
      {hideTodo ? null : <p>你可点击「编辑」修改部门信息进行完善</p>}
    </section>);
    const showContent = (<span className={style.text}>
        <span className='name'>{name}</span>
      {hidePopover ? null : <ExclamationCircleOutlined />}
      </span>);
    if (hidePopover) {
        return showContent;
    }
    return (<Popover content={content} placement='topLeft'>
      {showContent}
    </Popover>);
};
LeaderWarning.defaultProps = {
    hideTodo: false,
};
export default LeaderWarning;
