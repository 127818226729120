import React, { useCallback, useState, } from 'react';
import { Select } from 'antd';
import Spinner from '@src/components/Spinner';
import NoData from '@src/components/NoData';
import { debounce, getSafe } from '@src/tools/util';
import Clamp from '@src/components/Clamp';
import { common } from '@src/api/actions';
import useLoadingDelay from '@src/hooks/useLoadingDelay';
import style from './style.less';
const UserSelect = (props) => {
    const [userList, updateUserList] = useState([]);
    const [searchStr, updateSearchStr] = useState('');
    const [fetching, updateFetching] = useLoadingDelay(false);
    const { range, ...restProps } = props;
    const search = useCallback(debounce((value) => {
        updateSearchStr(value);
        if (!value)
            return;
        updateFetching(true);
        common.searchUser({ search_key: value, range }).finally(() => {
            updateFetching(false);
        }).then((res) => {
            updateUserList(getSafe(res, 'data') || []);
        });
    }, 100), [fetching, range]);
    let notFoundContent = null;
    if (fetching) {
        notFoundContent = <Spinner type='small'/>;
    }
    else if (searchStr) {
        notFoundContent = <NoData />;
    }
    return (<Select dropdownClassName={style.select} placeholder='输入姓名' labelInValue notFoundContent={notFoundContent} onSearch={search} filterOption={false} showSearch optionLabelProp='label' allowClear {...restProps}>
      {userList.map((user) => (<Select.Option disabled={props.filter(user)} value={user.uid} key={user.uid} label={user.name} item={user}>
          <Clamp overlayClassName={style.clamp}>
            <span className='user-name'>{user.name}</span>
            <span className='user-email'>{user.email}</span>
            {props.showTeam && <span className='user-email'>{user.team}</span>}
          </Clamp>
        </Select.Option>))}
    </Select>);
};
UserSelect.defaultProps = {
    range: 10,
    filter: () => false,
};
export default UserSelect;
