import { useEffect } from 'react';
const RoadTrigger = (props) => {
    const { didMount } = props;
    useEffect(() => {
        if (didMount) {
            didMount();
        }
    }, []);
    return null;
};
export default RoadTrigger;
