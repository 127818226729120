import React, { useCallback, useEffect, useRef, useState, useMemo, } from 'react';
import EnhancedModal from '@src/components/EnhancedModal';
import { Button, Form, message, } from 'antd';
import { useModel, useDerivedState, useDerivedStateToModel, useIndividualModel, } from 'femo';
import permission from '@src/model/permission';
import { fieldNames, layout } from '@src/pages/HR/Organization/Struct/modals/StaffCommon/fields/config';
import { getSafe, isEmpty } from '@src/tools/util';
import AffiliatedForm from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/AffiliatedForm';
import OutsourcingForm from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/OutsourcingForm';
import PracticeForm from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/PracticeForm';
import PublicForm from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/PublicForm';
import RegularForm from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/RegularForm';
import { SalaryPaymentKind, StaffType, User } from '@src/api/interface';
import { organization } from '@src/api/actions';
import useLoadingDelay from '@src/hooks/useLoadingDelay';
import { scrollToFirstErrorField } from '@src/pages/HR/Operation/Probation/commonLogic';
import moment from 'moment';
import { wrapSuccessTip } from '@src/tools/request';
// import { corpEmailSuffix } from '@src/pages/HR/formFields/EnterpriseEmail';
import { ContractTypes, contractTypesValue } from '@src/pages/components/ContractTypeSelect';
import pinyin from 'pinyin';
import { FormMode, isOutSourcingType, isPracticeType, isRegularLargeType, isRegularType, setTimeByStartTimeCommon, } from '@src/pages/HR/Organization/Struct/formUtils';
import Infinite from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/Infinite';
import RegularLargeScaleForm from '@src/pages/HR/Organization/Struct/modals/StaffCommon/forms/RegularLargeScaleForm';
import { EmailSuffix } from '@src/pages/components/EmailSuffixSelect';
import style from './style.less';
const { function_hr_offer_intern_salary_hidden_manage } = User.ModuleHRSubAuthKey;
var ClickType;
(function (ClickType) {
    ClickType["save"] = "save";
    ClickType["continue"] = "continue";
})(ClickType || (ClickType = {}));
export const getInitEmailSuffix = (st) => ((st === StaffType.largeScaleRegular || st === StaffType.largeScaleRegularPractice || st === StaffType.largeScaleOutsourcing) ? EmailSuffix.hfzxtech : EmailSuffix.baicizhan);
const NewStaffModal = (props) => {
    const { visible, record, onOk, onClose, orgId, apiAction, title, stickyMobile, extraFields, disableContinueAdd, disableStaffTypeField, staffType, initialStaffType, form: propForm, saveText, formatValues, typeFilter, hideCorpEmail, checkMobile, canSelectEmail, fromOfferTwo, } = props;
    const [f] = Form.useForm();
    const [, formModel] = useIndividualModel(propForm);
    const [form] = useDerivedStateToModel(props, formModel, (nextProps, _prevProps, _state) => {
        if ('form' in nextProps) {
            return nextProps.form;
        }
        return f;
    });
    const clickFlagRef = useRef(ClickType.save);
    const [loading, updateLoading] = useLoadingDelay(false);
    const [perm] = useModel(permission.permission);
    const [staffTypeCustom, staffTypeCustomModel] = useDerivedState(() => staffType, [staffType]);
    const salaryHiddenFlag = useMemo(() => fromOfferTwo && getSafe(record, 'intern_salary_hidden_flag')
        && !getSafe(perm, `${function_hr_offer_intern_salary_hidden_manage}`), [record, perm]);
    const getForm = useCallback((st) => {
        const result = {
            Form: RegularForm,
        };
        if (isRegularLargeType(st)) { // 正式员工（销服）
            result.Form = RegularLargeScaleForm;
        }
        else if (isRegularType(st)) { // 正式员工（销服实习）云账户类型
            result.Form = RegularForm;
        }
        else if (isPracticeType(st)) { // 实习生 实习生（外包）暑期实习生
            result.Form = PracticeForm;
        }
        else if (isOutSourcingType(st)) { // 外包员工 外包（销服）劳务派遣
            result.Form = OutsourcingForm;
        }
        else if (st === StaffType.affiliated) { // 挂靠员工
            result.Form = AffiliatedForm;
        }
        else if (st === StaffType.public) {
            result.Form = PublicForm;
        }
        return result;
    }, [fromOfferTwo]);
    const [isInfiniteType, updateIsInfiniteType] = useState(false);
    const setTimeByStartTime = useCallback(() => {
        setTimeByStartTimeCommon(form, {
            contractEndName: fieldNames.contract_end_ut,
            contractStartName: fieldNames.contract_start_ut,
            contractTypeName: fieldNames.contract_type,
            turnRegularTimeName: fieldNames.turn_regular_time_ut,
        }, staffTypeCustom, FormMode.new);
    }, [form, staffTypeCustom]);
    const setEndTimeByContractType = useCallback(() => {
        const contractType = form.getFieldValue(fieldNames.contract_type);
        updateIsInfiniteType(contractType === contractTypesValue[ContractTypes.random]);
    }, [form]);
    const getInitEmailSuffix = useCallback((st) => ((st === StaffType.largeScaleRegular || st === StaffType.largeScaleRegularPractice || st === StaffType.largeScaleOutsourcing) ? EmailSuffix.hfzxtech : EmailSuffix.baicizhan), []);
    const [initEmailSuffix] = useDerivedState(() => getInitEmailSuffix(staffTypeCustom), [staffTypeCustom]);
    const setCorpEmailByName = useCallback((t, suffix) => {
        if (isRegularType(t) && t !== StaffType.largeScaleRegularPractice) {
            const name = form.getFieldValue(fieldNames.staffName);
            const pinyinStr = pinyin(name || '', {
                heteronym: false,
                segment: false,
                style: pinyin.STYLE_NORMAL,
            });
            form.setFieldsValue({
                [fieldNames.corpEmail]: `${(pinyinStr || []).join('')}${suffix}`,
            });
        }
    }, [form]);
    const [staffForm] = useDerivedState(() => getForm(staffTypeCustom), [staffTypeCustom]);
    const [salaryInterDisabled, updateSalaryInterDisabled] = useState(false);
    const onValuesChange = useCallback((changedValues) => {
        if (fieldNames.staffType in changedValues) {
            const type = getSafe(changedValues, fieldNames.staffType);
            // 如果是产研员工表单
            if (isRegularType(type)) {
                setCorpEmailByName(type, getInitEmailSuffix(type));
            }
            else {
                form.setFieldsValue({
                    [fieldNames.corpEmail]: '',
                });
            }
            staffTypeCustomModel(type);
            // 有或者没有转正时间都不影响，所以统一设置
            setTimeByStartTime();
        }
        if (fieldNames.contract_start_ut in changedValues) {
            const endUt = form.getFieldValue(fieldNames.contract_end_ut);
            if (endUt) {
                form.validateFields([fieldNames.contract_end_ut]);
            }
            // 有或者灭有转正时间都不影响，所以统一设置
            setTimeByStartTime();
        }
        if (fieldNames.contract_end_ut in changedValues) {
            form.validateFields([fieldNames.contract_start_ut]);
            const startUt = form.getFieldValue(fieldNames.contract_start_ut);
            if (startUt) {
                form.validateFields([fieldNames.contract_start_ut]);
            }
        }
        // contractType变化
        if (fieldNames.contract_type in changedValues) {
            setEndTimeByContractType();
        }
        // 姓名变化
        if (fieldNames.staffName in changedValues) {
            setCorpEmailByName(staffTypeCustom, initEmailSuffix);
        }
        if (fieldNames.salary_intern_type in changedValues) {
            if (changedValues[fieldNames.salary_intern_type] === SalaryPaymentKind.byPieceAndTime) {
                form.setFieldsValue({
                    [fieldNames.salary_intern]: 0,
                });
                updateSalaryInterDisabled(true);
            }
            else {
                updateSalaryInterDisabled(false);
            }
        }
    }, [form, setTimeByStartTime, setEndTimeByContractType, setCorpEmailByName, staffTypeCustom, initEmailSuffix]);
    const afterSubmit = useCallback(() => {
        if (onOk) {
            onOk();
        }
        updateIsInfiniteType(false);
    }, [onOk, form]);
    const afterClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
        updateIsInfiniteType(false);
    }, [form, onClose]);
    const continueAdd = useCallback(() => {
        const staffType = staffTypeCustomModel();
        form.resetFields();
        form.setFieldsValue({
            [fieldNames.staffType]: staffType,
        });
    }, [form]);
    const onSubmit = useCallback((clickType) => {
        if (loading)
            return;
        updateLoading(true);
        // @ts-ignore
        form.validateFields(null, {
            triggerName: 'onSubmit',
        }).then((values) => {
            const tmpValues = formatValues ? formatValues({ ...values }) : { ...values };
            Object.keys(tmpValues).forEach((key) => {
                const value = tmpValues[key];
                if (moment.isMoment(value)) {
                    tmpValues[key] = Math.floor(value.valueOf() / 1000);
                }
            });
            if (fieldNames.work_place in tmpValues) {
                tmpValues[fieldNames.work_place] = (values[fieldNames.work_place] || []).join('/');
            }
            if (fieldNames.corpEmail in tmpValues) {
                const v = values[fieldNames.corpEmail];
                tmpValues[fieldNames.corpEmail] = isEmpty(v) ? undefined : v;
            }
            // 如果是无限期合同，则提交一个2038年1月1日年的值
            if (fieldNames.contract_type in tmpValues) {
                const contractType = tmpValues[fieldNames.contract_type];
                if (contractType === contractTypesValue[ContractTypes.random]) {
                    tmpValues.contract_end_ut = Math.floor(Infinite.Time.valueOf() / 1000);
                }
            }
            if (salaryHiddenFlag) {
                tmpValues.probation_salary = undefined;
                tmpValues.turn_regular_salary = undefined;
                tmpValues.salary_intern = undefined;
                tmpValues.regular_salary = undefined;
                tmpValues.salary = undefined;
            }
            apiAction(tmpValues).finally(() => {
                updateLoading(false);
            }).then((res) => {
                wrapSuccessTip(res, () => message.success('成功'));
                afterSubmit();
                if (clickType === ClickType.save) {
                    onClose();
                }
                else if (clickType === ClickType.continue) {
                    continueAdd();
                }
            });
        }).catch((err) => {
            updateLoading(false);
            scrollToFirstErrorField(err, form);
        });
    }, [form, loading, afterSubmit, continueAdd, onClose, apiAction, formatValues]);
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                [fieldNames.teamId]: orgId,
            });
        }
        else {
            form.resetFields();
            staffTypeCustomModel(initialStaffType);
        }
    }, [visible]);
    const clickSave = useCallback(() => {
        clickFlagRef.current = ClickType.save;
        onSubmit(ClickType.save);
    }, [onSubmit]);
    const clickContinue = useCallback(() => {
        clickFlagRef.current = ClickType.continue;
        onSubmit(ClickType.continue);
    }, [onSubmit]);
    const saveLoading = loading && clickFlagRef.current === ClickType.save;
    const continueLoading = loading && clickFlagRef.current === ClickType.continue;
    const footer = (<>
      <Button onClick={afterClose}>取消</Button>
      <Button disabled={continueLoading} type={disableContinueAdd ? 'primary' : 'default'} loading={saveLoading} onClick={clickSave}>{saveText}</Button>
      {!disableContinueAdd && <Button style={{ marginLeft: '8px' }} onClick={clickContinue} type='primary' disabled={saveLoading} loading={continueLoading}>保存&继续添加</Button>}
    </>);
    return (<EnhancedModal className={style.modal} visible={visible} footer={footer} onCancel={afterClose} width={1024} title={title} destroyOnClose>
      {visible ? (<Form {...layout} form={form} onValuesChange={onValuesChange} className={style.newStaffForm} hideRequiredMark>
         <staffForm.Form orgRecord={record} initEmailSuffix={initEmailSuffix} salaryInterDisabled={salaryInterDisabled} canSelectEmail={canSelectEmail} checkMobile={checkMobile} hideCorpEmail={hideCorpEmail} initialStaffType={initialStaffType} typeFilter={typeFilter} disableStaffTypeField={disableStaffTypeField} stickyMobile={stickyMobile} extraFields={extraFields} staffType={staffTypeCustom} isInfiniteType={isInfiniteType} orgId={orgId} form={form} fromOfferTwo={fromOfferTwo}/>
        </Form>) : null}
    </EnhancedModal>);
};
NewStaffModal.defaultProps = {
    apiAction: organization.addNewStaff,
    title: '添加员工',
    saveText: '保存',
    checkMobile: true,
};
export default NewStaffModal;
