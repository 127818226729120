import React from 'react';
import { Checkbox, Form } from 'antd';
import { Workplace } from '@src/api/interface';
const WorkPlaceField = (props) => (<Form.Item {...props}>
      <Checkbox.Group options={[Workplace.chengdu, Workplace.beijing, Workplace.remote]}/>
    </Form.Item>);
WorkPlaceField.defaultProps = {
    label: '工作地点',
};
export default WorkPlaceField;
