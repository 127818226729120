import { useLayoutEffect } from 'react';
const useDocumentTitle = (title, delay) => {
    useLayoutEffect(() => {
        let temp = 'MIS';
        if (typeof title === 'string') {
            temp = title;
        }
        else if (typeof title === 'function') {
            temp = title();
        }
        setTimeout(() => {
            document.title = temp;
        }, delay || 0);
    }, [title]);
};
export default useDocumentTitle;
