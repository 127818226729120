import React from 'react';
import classNames from 'classnames';
import FailedSvg from '@src/assets/svg/failed.svg';
import style from './style.less';
const Failed = (props) => {
    const { className, text } = props;
    return (<section className={classNames(style.failed, className)}>
      <section className='failed-content'>
        <section className='svg-container'>
          <FailedSvg />
        </section>
        <section className='tip-desc'>
          {text}
        </section>
      </section>
    </section>);
};
Failed.defaultProps = {
    text: '系统出现异常，请稍后重试...',
};
export default Failed;
