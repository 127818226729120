import { BankOutlined } from '@ant-design/icons';
import { lazy } from 'react';
import { gluer } from 'femo';
import { Redirect } from 'react-router-dom';
import { User } from '@src/api/interface';
import { approvalCountModel, getApprovalCount, getTransferCount, transferCountModel, } from '@src/pages/Law/AccountManage/count';
import { getSafe } from '@src/tools/util';
import RoadTrigger from '@src/components/RoadTrigger';
const initRoad = {
    name: '账号管理',
    path: '/dep-law',
    icon: BankOutlined,
    permissions: [],
    component: () => <Redirect to='/dep-law/account/list'/>,
    subRoads: [{
            name: '账号列表',
            path: '/account/list',
            component: lazy(() => import('../admin/AccountList')),
        }, {
            name: '申请共享',
            path: '/account/apply/list',
            component: lazy(() => import('../admin/AccountApply')),
        }, {
            name: '账号审核',
            path: '/account/approval/list',
            permissions: [User.ModuleAccountManageSubAuthKey.menu_account_approval_list],
            component: lazy(() => import('../admin/AccountApproval')),
            badge: approvalCountModel(),
            roadTrigger: <RoadTrigger didMount={getApprovalCount}/>,
        }, {
            name: '账号移交',
            path: '/account/transfer/list',
            permissions: [User.ModuleAccountManageSubAuthKey.menu_account_transfer_list],
            component: lazy(() => import('../admin/AccountTransfer')),
            badge: transferCountModel(),
            roadTrigger: <RoadTrigger didMount={getTransferCount}/>,
        }],
};
const road = gluer(initRoad);
road.relyOn([approvalCountModel, transferCountModel], ([approvalAccount, transferAccount], state) => {
    // 账号审核
    const target_1 = getSafe(state, 'subRoads', []).find((item) => item.path === '/account/approval/list');
    target_1.badge = approvalAccount;
    // 账号转移
    const target_2 = getSafe(state, 'subRoads', []).find((item) => item.path === '/account/transfer/list');
    target_2.badge = transferAccount;
    return {
        ...state,
    };
});
export default road;
