import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import { bankFull, BankShortCut } from '@src/pages/components/BankSelect';
import { useDerivedModel } from 'femo';
import { bankDcCardCheck } from '@src/tools/util';
const BankNumberField = (props) => {
    const { bank } = props;
    const genRules = useCallback(() => [{
            validator: (_r, value) => {
                if (value) {
                    if (!bankDcCardCheck(bank, value)) {
                        return Promise.reject(`请输入${bankFull[bank]}卡卡号`);
                    }
                }
                return Promise.resolve();
            },
            validateTrigger: ['onSubmit', 'onChange'],
        }], [bank]);
    const [rules] = useDerivedModel(() => genRules(), bank, (ns, ps, s) => {
        if (ns !== ps) {
            return genRules();
        }
        return s;
    });
    return (<Form.Item {...props} rules={props.rules ? [...props.rules, ...rules] : rules} validateFirst>
      <Input autoComplete='off' placeholder='请输入'/>
    </Form.Item>);
};
BankNumberField.defaultProps = {
    label: '银行卡号',
    bank: BankShortCut.CMB,
};
export default BankNumberField;
