import axios from 'axios';
import { message } from 'antd';
import { ApiCode } from '@src/api/interface';
import { getSafe, objectToQuery } from '@src/tools/util';
const request = axios.create({
    timeout: 3600 * 1000,
    responseType: 'json',
    validateStatus: (status) => (status >= 200 && status < 300) || status === 304,
    headers: {
        'content-type': 'application/json',
    },
});
const login = (data, referer) => {
    console.log('data', data);
    window.location.href = `${getSafe(data, 'login_url')}${encodeURIComponent(referer)}`;
};
const defaultExtraCustomConfig = {
    showHttpError: true,
    showBizError: true,
};
const customRequest = (config) => {
    const referer = window.location.href;
    config.headers = {
        ...config.headers,
        'mis-referer': referer,
        'X-Requested-With': 'XMLHttpRequest',
    };
    // if (config.url.startsWith('/api/hr/employ/offical')) {
    //   config.headers = {
    //     ...config.headers,
    //     'content-type': 'application/x-www-form-urlencoded',
    //   };
    //   config.data = objectToQuery(config.data);
    // }
    const { showBizError, showHttpError, ...restConfig } = config;
    return request({
        ...restConfig,
    }).catch((error) => {
        let code = getSafe(error, 'response.status') || ApiCode.httpError;
        if (axios.isCancel(error)) {
            code = ApiCode.cancelError;
        }
        if (showHttpError) {
            const msg = window.navigator.onLine ? `系统出现问题，请稍后重试(code: ${code})` : `网络连接中断，请检查网络(code: ${code})`;
            message.error(msg);
        }
        return Promise.reject({
            code,
            ...error,
        });
    }).then((res) => {
        const { data } = res;
        const code = getSafe(data, 'code');
        if (code === ApiCode.needLogin) {
            login(getSafe(data, 'data'), referer);
            return Promise.reject({
                code,
                data: null,
                msg: '需要登录',
            });
        }
        if (code !== ApiCode.ok && code !== ApiCode.limitValid) {
            if (showBizError) {
                const msg = getSafe(data, 'msg') || getSafe(data, 'message');
                const dataStr = getSafe(data, 'data');
                let errMsg = msg;
                if (!errMsg && typeof dataStr === 'string') {
                    errMsg = dataStr;
                }
                message.error(errMsg || '接口未知错误');
            }
            return Promise.reject(data);
        }
        return data;
    });
};
const customRequestFile = (config) => {
    const referer = window.location.href;
    config.headers = {
        ...config.headers,
        'mis-referer': referer,
        'X-Requested-With': 'XMLHttpRequest',
    };
    const { showBizError, showHttpError, ...restConfig } = config;
    return request({
        ...restConfig,
    }).catch((error) => {
        let code = getSafe(error, 'response.status') || ApiCode.httpError;
        if (axios.isCancel(error)) {
            code = ApiCode.cancelError;
        }
        if (showHttpError) {
            const msg = window.navigator.onLine ? `系统出现问题，请稍后重试(code: ${code})` : `网络连接中断，请检查网络(code: ${code})`;
            message.error(msg);
        }
        return Promise.reject(error);
    });
};
export const makeRequestCached = (apiAction) => {
    let promise;
    return (url, input, config) => {
        const status = getSafe(promise, 'status');
        if (status === 'done' || status === 'pending') {
            return promise;
        }
        promise = apiAction(url, input, config);
        // @ts-ignore
        promise.status = 'pending';
        // @ts-ignore
        return promise.then((data) => {
            // @ts-ignore
            promise.status = 'done';
            return data;
        }).catch((err) => {
            // @ts-ignore
            promise.status = 'failed';
            return Promise.reject(err);
        });
    };
};
export const wrapSuccessTip = (res, callback) => {
    const code = getSafe(res, 'code');
    if (code === ApiCode.limitValid) {
        message.success(getSafe(res, 'msg'));
    }
    else {
        callback();
    }
};
export const post = (url, data, config) => customRequest({
    url, data, method: 'post', ...defaultExtraCustomConfig, ...config,
});
export const get = (url, params, config) => customRequest({
    url, params, method: 'get', ...defaultExtraCustomConfig, ...config,
});
export const del = (url, data, config) => customRequest({
    url, data, method: 'delete', ...defaultExtraCustomConfig, ...config,
});
export const patch = (url, data, config) => customRequest({
    url, data, method: 'patch', ...defaultExtraCustomConfig, ...config,
});
export const put = (url, data, config) => customRequest({
    url, data, method: 'put', ...defaultExtraCustomConfig, ...config,
});
export const postForm = (url, data, config) => customRequest({
    url, data: objectToQuery(data), method: 'post', headers: { 'content-type': 'application/x-www-form-urlencoded' }, ...defaultExtraCustomConfig, ...config,
});
export const postMultipartForm = (url, data, config) => customRequest({
    url, data, method: 'post', headers: { 'content-type': 'multipart/form-data' }, ...defaultExtraCustomConfig, ...config,
});
export const requestFileByGet = (url, params, config) => customRequestFile({
    url,
    method: 'get',
    params,
    ...defaultExtraCustomConfig,
    responseType: 'blob',
    ...config,
});
export const requestFileByPost = (url, data, config) => customRequestFile({
    url,
    method: 'post',
    data,
    ...defaultExtraCustomConfig,
    responseType: 'blob',
    ...config,
});
