import React from 'react';
import { getSafe } from '@src/tools/util';
import { User } from '@src/api/interface';
import { setSalaryHidden } from '@src/pages/HR/Offer/Regular/service';
import { useModel } from 'femo';
import permission from '@src/model/permission';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const SetSalaryHidden = (props) => {
    const { record, replace } = props;
    const [perm] = useModel(permission.permission);
    const isSalaryHidden = getSafe(record, 'salary_hidden_flag');
    const [setSalary, loading] = useRequestWithCallback(setSalaryHidden, false, false);
    if (loading)
        return <Spin indicator={<LoadingOutlined spin/>}/>;
    return getSafe(perm, `${User.ModuleHRSubAuthKey.button_offer_salary}`)
        ? <a onClick={(e) => {
                e.stopPropagation();
                e.cancelable = true;
                setSalary({
                    offer_id: getSafe(record, 'candidate_id'),
                    salary_hidden_flag: !isSalaryHidden,
                }).then(() => {
                    replace({
                        t: Date.now(),
                    });
                });
            }}>{isSalaryHidden ? '显示' : '隐藏'}</a> : null;
};
export default SetSalaryHidden;
