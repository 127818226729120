import React, { useCallback, } from 'react';
import { Form } from 'antd';
import ConditionSelect, { conditions_c, conditions_d } from '@src/pages/components/ConditionSelect';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import { getSafe } from '@src/tools/util';
import TextInput from '@src/pages/components/filter/fields/TextFilterField/TextInput';
import { useDerivedState } from 'femo';
const TextFilterField = (props) => {
    const { hasNullCondition, ...rest } = props;
    const [conds] = useDerivedState(() => {
        if (hasNullCondition) {
            return conditions_d;
        }
        return conditions_c;
    }, [hasNullCondition]);
    const renderCondition = useCallback((_v, condition, onChange) => (<ConditionSelect conditions={conds} value={condition} onChange={onChange}/>), [conds]);
    const renderValue = useCallback((value, c, onChange) => {
        const inputChange = (e) => {
            onChange([e.target.value || '']);
        };
        return <TextInput condition={getSafe(c, '[0]')} composedValueChange={onChange} placeholder='请输入' autoComplete='off' value={getSafe(value, '[0]', '')} onChange={inputChange}/>;
    }, []);
    return (<Form.Item label='姓名' {...rest} {...fieldLayout}>
      <FilterFieldCompose renderCondition={renderCondition} renderValue={renderValue}/>
    </Form.Item>);
};
export default TextFilterField;
