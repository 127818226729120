import React, { useCallback, useRef, } from 'react';
import { getSafe, queryToObject } from '@src/tools/util';
import useQueryObjStrict from '@src/hooks/useQueryObjStrict';
import { defaultOfferUrlQuery, offerUrl } from '@src/pages/HR/Offer/url';
import { Offer } from '@src/pages/HR/Offer/interface';
import { Tabs } from 'antd';
import OfferList from '@src/pages/HR/Offer/Regular/OfferList';
import QuitList from '@src/pages/HR/Offer/Regular/QuitList';
import { securityCrash } from '@src/components/HOC';
import { useBatchDerivedModel } from 'femo';
import style from './style.less';
const defaultActiveKey = Offer.SubKeys.regular_offer_list;
export const defaultRegularQuery = {
    ...defaultOfferUrlQuery,
    sub_key: defaultActiveKey,
};
const RegularPage = (props) => {
    const { location, history, control } = props;
    const search = getSafe(location, 'search') || '';
    const cacheSubKey = useRef(defaultActiveKey);
    const [query] = useQueryObjStrict(search, {
        ...defaultRegularQuery,
        sub_key: cacheSubKey.current,
    });
    const subKey = getSafe(query, 'sub_key');
    const [activeKey] = useBatchDerivedModel(subKey, {
        source: query.tab_key,
        callback: (ns, ps, s) => {
            if (ns !== ps && ns === Offer.TabKeys.regular) {
                return cacheSubKey.current;
            }
            return s;
        },
    }, {
        source: query.sub_key,
        callback: (ns, ps, s) => {
            if (ns !== ps) {
                cacheSubKey.current = ns;
                return ns;
            }
            return s;
        },
    });
    const onChange = useCallback((key) => {
        history.push(offerUrl({
            tab_key: Offer.TabKeys.regular,
            sub_key: key,
        }));
    }, []);
    return (<Tabs className={style.offer} defaultActiveKey={activeKey} activeKey={activeKey} onChange={onChange}>
      <Tabs.TabPane tab='offer列表' key={Offer.SubKeys.regular_offer_list}>
        <OfferList history={history} location={location} activeSubKey={activeKey} control={control}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab='毁约offer' key={Offer.SubKeys.regular_quit_list}>
        <QuitList history={history} location={location} activeSubKey={activeKey} control={control}/>
      </Tabs.TabPane>
    </Tabs>);
};
export default React.memo(securityCrash(RegularPage), (_p, nextProps) => {
    const nextQuery = queryToObject(getSafe(nextProps, 'location.search', ''), {
        tab_key: '',
    }, true);
    const tab = getSafe(nextQuery, 'tab_key');
    return tab && tab !== Offer.TabKeys.regular;
});
