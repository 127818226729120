import { RoadMapType } from '@src/pages/interface';
import { isArray, isNumber, isSet, isString, } from '@src/tools/util';
export const permissionCheck = (permissions = [], purviews) => {
    // 如果节点权限为undefined或者空数组，则认为该节点不需要权限即可看见
    if (!permissions || (!permissions.length && !permissions.size)) {
        return true;
    }
    const iteratorFn = (per) => {
        if (isString(per) || isNumber(per)) {
            return !!purviews[per];
        }
        // 如果是数组，那么表示内部权限码是'且'的关系，需要全部为真才代表有权限
        if (isArray(per)) {
            return per.every((p) => !!iteratorFn(p));
        }
        // 为了保险起见，增加了特征函数判断
        // 如果是Set，那么表示内部权限码是'或'的关系，只要有一个为真就代表有权限
        // @ts-ignore
        if (isSet(per) || (per && typeof per.has === 'function')) {
            const temp = Array.from(per);
            return temp.some((p) => !!iteratorFn(p));
        }
        // 如果传入了不合法的权限，出于安全考虑一律返回false
        return false;
    };
    return iteratorFn(permissions);
};
// 权限过滤
export const findUserPathRoadMap = (purviews, roadMap, traverse = true) => roadMap.reduce((pre, cur) => {
    // @ts-ignore
    const { permissions, subRoads, ignore_permissions = false, } = cur;
    const curPermissions = permissions || [];
    let temp = { ...cur };
    const flag = permissionCheck(curPermissions, purviews) || ignore_permissions;
    if (!flag) {
        // 将没有权限的页面路由置为不可见，而不是直接从最终生成的pagesRoadMap中删除
        // 因为可能需要无权限的路由承担fallback的职责
        temp.type = RoadMapType.fallen;
        temp.access = false;
    }
    else if (curPermissions && Array.from(curPermissions).length !== 0) {
        temp.type = RoadMapType.living;
        temp.access = true;
    }
    if (traverse) {
        if (!subRoads || subRoads.length === 0) {
            temp = { ...temp, permissions: curPermissions };
        }
        else {
            temp = { ...temp, permissions: curPermissions, subRoads: findUserPathRoadMap(purviews, subRoads) };
        }
    }
    pre.push(temp);
    return pre;
}, []);
