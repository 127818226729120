import React from 'react';
import { render } from 'react-dom';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider, message } from 'antd';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Root from './Root';
import App from './App';
import './global.less';
message.config({
    maxCount: 1,
});
// 非开发环境才开启sentry
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://fba2d63ee3484c31975cf412c39f39a4@sentry2.baicizhan.com/28',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}
if (window.Promise && !window.Promise.prototype.finally) {
    window.Promise.prototype.finally = function (fn) {
        return this.then((data) => {
            fn();
            return Promise.resolve(data);
        }, (err) => {
            fn();
            return Promise.reject(err);
        });
    };
}
const rootEle = document.getElementById('bd');
render(<Root>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Root>, rootEle);
