export default {
    labelCol: {
        sm: 3,
        xs: 3,
    },
    wrapperCol: {
        sm: 16,
        xs: 16,
    },
};
