import React, { useCallback, useEffect, } from 'react';
import EnhancedModal from '@src/components/EnhancedModal';
import { Button, Form, Input, message, Space, } from 'antd';
import BreachReasonSelect from '@src/pages/components/BreachReasonSelect';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
import { recruit } from '@src/api/actions';
import { scrollToFirstErrorField } from '@src/pages/HR/Operation/Probation/commonLogic';
const reason = 'reason';
const remark = 'remark';
const candidateId = 'candidate_id';
const DeleteModal = (props) => {
    const { result, notSendMsg, afterSubmit } = props;
    const [form] = Form.useForm();
    const hide = useCallback(() => {
        result.hide();
        form.resetFields();
    }, [result.hide]);
    useEffect(() => {
        if (result.visible) {
            form.setFieldsValue({
                [reason]: undefined,
                [remark]: undefined,
                [candidateId]: result.candidate_id,
            });
        }
    }, [result.visible]);
    const [request, loading] = useRequestWithCallback(notSendMsg ? recruit.postRecruitBoardingBreachOffer : recruit.postRecruitBreachOffer, false, false);
    const submit = useCallback(() => {
        form.validateFields().then((values) => {
            request(values).then(() => {
                message.success('操作成功');
                if (afterSubmit) {
                    afterSubmit();
                }
                hide();
            });
        }).catch((err) => {
            scrollToFirstErrorField(err, form);
        });
    }, [request, form, afterSubmit, hide]);
    const footer = (<Space>
      <Button onClick={hide}>取消</Button>
      <Button loading={loading} type='primary' onClick={submit}>确定</Button>
    </Space>);
    return (<EnhancedModal title='取消入职' onCancel={hide} visible={result.visible} footer={footer}>
      <section style={{ marginLeft: '8px', marginBottom: '24px' }}>
        确定取消该人员的入职安排吗？
      </section>
      <Form form={form} hideRequiredMark labelCol={{
            xs: { span: 24 },
            sm: { span: 4 },
        }} wrapperCol={{
            xs: { span: 24 },
            sm: { span: 18 },
        }}>
        <Form.Item name={candidateId} style={{ margin: 0, height: 0 }}>
          <Input type='hidden'/>
        </Form.Item>
        <Form.Item name={reason} label='毁约原因' rules={[{
                required: true,
                message: '请选择',
            }]}>
          <BreachReasonSelect />
        </Form.Item>
        <Form.Item name={remark} label='备注' rules={[{
                type: 'string',
                max: 100,
                message: '最多100字',
            }]}>
          <Input.TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder='最多100字（选填）'/>
        </Form.Item>
      </Form>
    </EnhancedModal>);
};
export default DeleteModal;
