import CrashPage from '@src/components/Crash';
import PrepareData from '@src/components/PrepareData';
import { useDerivedState } from 'femo';
import React from 'react';
import { currentExactMatchRoad } from '@src/components/Routes/roadMapDerivedModel';
const RouteRenderComponent = (props) => {
    const { road, ...rest } = props;
    const { component: Component, prepare } = road;
    useDerivedState(() => {
        currentExactMatchRoad(road);
    }, [road]);
    return (<CrashPage>
      <PrepareData {...rest} prepare={prepare}>
        <Component />
      </PrepareData>
    </CrashPage>);
};
export default RouteRenderComponent;
