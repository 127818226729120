import { Offer } from '@src/pages/HR/Offer/interface';
import { formatOrderBy, arrToString, } from '@src/pages/urlTool';
import moment from 'moment';
// todo 目前时间数组：如果只有一个，则是开始时间；如果是两个，则第一个时开始时间，第二个是结束时间；其他的数量暂时没有
export const timeStringToNumber = (arr) => {
    const res = [];
    const start = arr[0];
    if (!start) {
        return res;
    }
    res[0] = moment(start).startOf('day').valueOf() / 1000;
    const end = arr[1];
    if (end) {
        res[1] = Math.floor(moment(end).endOf('day').valueOf() / 1000);
    }
    return res;
};
const urlQueryToApiQuery = (query, list_type, offer_manager_source) => {
    const isQuit = list_type === Offer.ListType.break_contract;
    const result = {
        company_ids: arrToString(query.c_id),
        company_ids_condition: arrToString(query.c_id_cond),
        created_by: arrToString(query.c_by),
        created_by_condition: arrToString(query.c_by_cond),
        create_time: arrToString(timeStringToNumber(query.t_crt)),
        create_time_condition: arrToString(query.t_c_cond),
        customer_status: arrToString(query.s_status),
        customer_status_condition: arrToString(query.s_s_cond),
        customer_types: arrToString(query.s_type),
        customer_types_condition: arrToString(query.s_t_cond),
        list_type,
        offer_manager_source,
        page_index: query.p_index,
        page_size: query.p_size,
        request_channel: arrToString(query.channel),
        request_channel_condition: arrToString(query.c_cond),
        team_ids: arrToString(query.t_id),
        team_ids_condition: arrToString(query.t_id_cond),
        order_by: formatOrderBy(query.order_by),
        boarding_time: arrToString(timeStringToNumber(query.b_t)),
        boarding_time_condition: arrToString(query.b_t_cond),
        name: arrToString(query.name),
        name_condition: arrToString(query.name_cond),
    };
    if (isQuit) {
        result.break_contract_time = arrToString(timeStringToNumber(query.t_dst));
        result.break_contract_time_condition = arrToString(query.t_d_cond);
    }
    return result;
};
export { orderByToTableSorter, tableSorterToOrderBy, switchOrderBy, formatOrderBy, } from '@src/pages/urlTool';
export default urlQueryToApiQuery;
