import React, { useCallback, useEffect, useRef, } from 'react';
import { Condition } from '@src/pages/components/ConditionSelect';
import { DatePicker } from 'antd';
import { getSafe } from '@src/tools/util';
import moment from 'moment';
import { useDerivedModel } from 'femo';
const Picker = (props) => {
    const { value, onChange, condition, format, } = props;
    const cacheCondition = useRef(Condition.equal);
    const genLocalValue = useCallback((value) => {
        let innerValue = [];
        if (!(value instanceof Array)) {
            if (value) {
                innerValue = [value];
            }
        }
        else {
            innerValue = value;
        }
        const single = getSafe(innerValue, '[0]') ? moment(getSafe(innerValue, '[0]')) : undefined;
        const double = (innerValue || []).map((m) => {
            if (m) {
                return moment(m);
            }
            return m;
        });
        let v = single;
        if (condition === Condition.equal) {
            v = double;
        }
        return v;
    }, [condition]);
    const [v] = useDerivedModel(() => genLocalValue(value), props, (ns, ps, s) => {
        const nsValue = getSafe(ns, 'value');
        const psValue = getSafe(ps, 'value');
        if (nsValue !== psValue) {
            return genLocalValue(nsValue);
        }
        const nsCondition = getSafe(ns, 'condition');
        const psCondition = getSafe(ps, 'condition');
        if (nsCondition !== psCondition) {
            return genLocalValue(nsValue);
        }
        return s;
    });
    useEffect(() => {
        if (cacheCondition.current !== condition) {
            cacheCondition.current = condition;
            const tmpV = genLocalValue(v);
            onChange(tmpV);
        }
    }, [condition]);
    if (condition === Condition.equal) {
        return <DatePicker.RangePicker format={format} value={v} onChange={onChange}/>;
    }
    return <DatePicker format={format} value={v} onChange={onChange}/>;
};
export default Picker;
