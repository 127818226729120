import React, { useState, } from 'react';
import SelectCheckbox from '@src/pages/components/filter/components/SelectCheckbox';
export var Channel;
(function (Channel) {
    Channel[Channel["introducing"] = 100] = "introducing";
    Channel[Channel["hunter"] = 200] = "hunter";
    Channel[Channel["manual"] = 300] = "manual";
    Channel[Channel["other"] = 400] = "other";
    Channel[Channel["recruit_website"] = 500] = "recruit_website";
    Channel[Channel["recruit_portal"] = 600] = "recruit_portal";
    Channel[Channel["talent_pool"] = 700] = "talent_pool";
    Channel[Channel["quit_customer_back_flow"] = 800] = "quit_customer_back_flow";
    Channel[Channel["inner_push_activation"] = 900] = "inner_push_activation";
    Channel[Channel["head_hunting_activation"] = 1000] = "head_hunting_activation";
    Channel[Channel["inner_change_job"] = 1100] = "inner_change_job";
    Channel[Channel["intern_intern_turn_regular"] = 1200] = "intern_intern_turn_regular";
})(Channel || (Channel = {}));
const channels = [{
        id: Channel.introducing,
        name: '内推',
    }, {
        id: Channel.hunter,
        name: '猎头',
    }, {
        id: Channel.manual,
        name: '手动添加',
    }, {
        id: Channel.recruit_website,
        name: '招聘网站',
    }, {
        id: Channel.recruit_portal,
        name: '招聘门户',
    }, {
        id: Channel.talent_pool,
        name: '人才库',
    }, {
        id: Channel.quit_customer_back_flow,
        name: '离职人才回流',
    }, {
        id: Channel.head_hunting_activation,
        name: '猎头推荐激活',
    }, {
        id: Channel.inner_push_activation,
        name: '内推激活',
    }, {
        id: Channel.inner_change_job,
        name: '内部转岗/晋升',
    }, {
        id: Channel.intern_intern_turn_regular,
        name: '实习转正',
    }, {
        id: Channel.other,
        name: '其他',
    }];
export const getAllChannelIds = () => channels.map((t) => t.id);
const ChannelCheckbox = (props) => {
    const [allIds] = useState(() => channels.map((item) => item.id));
    return <SelectCheckbox {...props} allIds={allIds} allText='全部渠道' data={channels}/>;
};
export default ChannelCheckbox;
