import React from 'react';
import { Select } from 'antd';
const reasonsList = [
    '有更心仪的机会',
    '身体/家庭（学校）原因',
    '对工作内容/岗位角色不满意',
    '对公司发展/内部机制有concern',
    '对项目组的业务方向不感兴趣',
    '对个人成长有concern',
    '不接受6天工作制',
    '对薪资福利不满意',
    '地域原因',
    '住宿原因',
    '其他',
];
const BreachReasonSelect = (props) => (<Select {...props}>
      {reasonsList.map((s) => (<Select.Option value={s} key={s}>
              {s}
            </Select.Option>))}
    </Select>);
BreachReasonSelect.defaultProps = {
    placeholder: '请选择',
};
export default BreachReasonSelect;
