import React from 'react';
import { Form } from 'antd';
import InnerJobSelect from '@src/pages/components/InnerJobSelect';
const JobNameField = (props) => {
    const { labelInValue, ...restProps } = props;
    return (<Form.Item {...restProps}>
      <InnerJobSelect labelInValue={labelInValue} placeholder='请选择'/>
    </Form.Item>);
};
JobNameField.defaultProps = {
    label: '岗位名称',
};
export default JobNameField;
