import useVisible from '@src/hooks/useVisible';
import { useCallback, useRef, useState } from 'react';
import useResult from '@src/hooks/useResult';
const useSetOfferSalary = (v = false) => {
    const tmpResult = useVisible(v);
    const [record, updateRecord] = useState();
    const modeRef = useRef();
    const show = useCallback((r, mode) => {
        modeRef.current = mode;
        updateRecord(r);
        tmpResult.show();
    }, [tmpResult.show]);
    const hide = useCallback(() => {
        updateRecord(null);
        tmpResult.hide();
        modeRef.current = null;
    }, [tmpResult.hide]);
    return useResult({
        ...tmpResult,
        show,
        hide,
        record,
        mode: modeRef.current,
    });
};
export default useSetOfferSalary;
