import { gluer } from 'femo';
import { lazy } from 'react';
import { RoadMapType } from '@src/pages/interface';
const initRoad = {
    name: '开发中...',
    path: '/waiting',
    component: lazy(() => import('./index')),
    hasSider: false,
    hasHeader: false,
    type: RoadMapType.fallen,
};
const road = gluer(initRoad);
export default road;
