import { objectToQuery } from '@src/tools/util';
const prefix = '/api';
const genUrl = (path) => `${prefix}${path}`;
export const genUrlWithParams = (path, preStr = prefix) => (...args) => {
    let count = 0;
    const { length } = args;
    const tempPath = path.replace(/\/{[a-zA-Z0-9_]+}/g, (match) => {
        let target;
        if (length) {
            target = args[count];
        }
        else {
            target = `:${match.substring(2, match.length - 1)}`;
        }
        count += 1;
        return `/${target}`;
    });
    return `${preStr}${tempPath}`;
};
export const genUrlWithQuery = (path) => (obj) => {
    const query = objectToQuery(obj);
    if (query) {
        return `${path}?${query}`;
    }
    return path;
};
export default {
    logout: '/home/logout',
    buryToken: genUrl('/token/change_user'),
    getFlags: genUrl('/common/tool/get_kv_store'),
    saveFlags: genUrl('/common/tool/save_kv_store'),
    /* -----------------------------------------------通用接口------------------------------------------------------ */
    imgUrl: (path) => `https://bczmis-public.oss-cn-hangzhou.aliyuncs.com/${path}`,
    userInfo: genUrl('/home/user_info'),
    searchUser: genUrl('/common/staff/search'),
    searchAllUser: genUrl('/common/staff/search_of_all'),
    allTeam: genUrl('/common/staff/real_teams'),
    orgStruct: genUrl('/common/staff/team_tree'),
    commonJobList: genUrl('/common/staff/job_list'),
    allCompanyList: genUrl('/common/staff/company_list'),
    uploadFile: genUrl('/common/tool/upload_private_file'),
    uploadPublicFile: genUrl('/common/tool/upload_public_file'),
    userInfoById: genUrl('/common/staff/info_by_customer_id'),
    getStaffListByTeamId: genUrl('/common/staff/depart_onjob_member'),
    factorCheck: genUrl('/common/tool/financial_factor_check'),
    // 未确认列表(临时)
    notConfirmedList: genUrl('/common/staff/get_customer_none_confirmed_list'),
    /* -----------------------------------------------试用期管理------------------------------------------------------ */
    downloadProbationFile: genUrlWithParams('/hr/probation/report/{staffId}/{stageId}/{fileName}'),
    probationUserInfo: genUrlWithParams('/hr/probation/staff/{staffId}/{stageId}'),
    probationStageAll: genUrl('/hr/probation/stage'),
    probationStageDetail: genUrlWithParams('/hr/probation/stage/{stageId}'),
    probationOperations: genUrl('/hr/probation/operations'),
    probationPurposeAdd: genUrl('/hr/probation/goal'),
    probationPurposeGet: genUrlWithParams('/hr/probation/goal/{staffId}'),
    probationPurposeFinishGet: genUrlWithParams('/hr/probation/goal_finish/{staffId}'),
    probationPurposeConfirm: genUrlWithParams('/hr/probation/goal_check/{staffId}/{operation}'),
    probationResultConfirmLeader: genUrlWithParams('/hr/probation/turn_regular/leader/{staffId}/{operation}'),
    probationResultConfirmManager: genUrlWithParams('/hr/probation/turn_regular/manager/{staffId}/{operation}'),
    probationPurposeEvaluate: genUrlWithParams('/hr/probation/goal_finish/{staffId}'),
    probationCooperatorGet: genUrlWithParams('/hr/probation/cooperator_list/{staffId}/{stageId}'),
    probationCooperatorAdd: genUrlWithParams('/hr/probation/cooperator_list/{staffId}/{stageId}'),
    probationFirstStageQuestionPost: genUrl('/hr/probation/stage_one_ques'),
    probationSecondStageQuestionPost: genUrl('/hr/probation/stage_two_ques'),
    probationFirstStageAnswerGet: genUrlWithParams('/hr/probation/stage_one_ques/{staffId}/{submitStaffId}'),
    probationSecondStageAnswerGet: genUrlWithParams('/hr/probation/stage_two_ques/{staffId}/{submitStaffId}'),
    probationUploadReportFile: genUrlWithParams('/hr/probation/report/{staffId}/{stageId}/{type}'),
    probationRethinkPost: genUrl('/hr/probation/rethink'),
    probationRethinkDoc: genUrlWithParams('/hr/probation/rethink_report/{staffId}'),
    probationRethinkGet: genUrlWithParams('/hr/probation/rethink/{staffId}/{stageId}'),
    probation1On1Post: genUrlWithParams('/hr/probation/feedback/{stageId}'),
    probation1On1Get: genUrlWithParams('/hr/probation/feedback/{staffId}/{stageId}'),
    /* ----------------------------------------------权限管理------------------------------------------------------- */
    authDepartList: genUrl('/auth/depart/list'),
    authServiceInDepart: genUrl('/auth/service/list'),
    authServiceCreate: genUrl('/auth/service/create'),
    authServiceDelete: genUrl('/auth/service/delete'),
    authServiceUpdate: genUrl('/auth/service/edit'),
    authGroupList: genUrl('/auth/res_grp/list'),
    authGroupListAll: genUrl('/auth/res_grp/list_all'),
    authGroupCreate: genUrl('/auth/res_grp/create'),
    authGroupDelete: genUrl('/auth/res_grp/delete'),
    authGroupUpdate: genUrl('/auth/res_grp/edit'),
    authGroupInnerResList: genUrl('/auth/res_grp/inner_res/list'),
    authGroupInnerResListAll: genUrl('/auth/res_grp/inner_res/list_all'),
    authGroupInnerResDelete: genUrl('/auth/res_grp/inner_res/delete'),
    authGroupInnerResUninclude: genUrl('/auth/res_grp/inner_res/list_uninclude'),
    authGroupInnerResUnincludeAll: genUrl('/auth/res_grp/inner_res/list_uninclude_all'),
    authGroupInnerResAdd: genUrl('/auth/res_grp/inner_res/add_inner_res'),
    authGroupInnerUserList: genUrl('/auth/res_grp/inner_user/list'),
    authGroupInnerUserListAll: genUrl('/auth/res_grp/inner_user/list_all'),
    authGroupInnerUserDelete: genUrl('/auth/res_grp/inner_user/remove'),
    authGroupInnerUserAdd: genUrl('/auth/res_grp/inner_user/addnew'),
    authServiceInnerResList: genUrl('/auth/service/inner_res/list'),
    authServiceInnerResListAll: genUrl('/auth/service/inner_res/list_all'),
    authResCreate: genUrl('/auth/res/create'),
    authResUpdate: genUrl('/auth/res/edit'),
    authResDelete: genUrl('/auth/res/delete'),
    authResInnerUserList: genUrl('/auth/res/inner_user/list'),
    authResInnerUserListAll: genUrl('/auth/res/inner_user/list_all'),
    authResInnerUserDelete: genUrl('/auth/res/inner_user/remove'),
    authResInnerUserAdd: genUrl('/auth/res/inner_user/addnew'),
    authResQuoteGrpList: genUrl('/auth/res/quote_grp/list'),
    authResQuoteGrpListAll: genUrl('/auth/res/quote_grp/list_all'),
    authResQuoteGrpIncludeNew: genUrl('/auth/res/quote_grp/include_new'),
    authResQuoteGrpExcludeOld: genUrl('/auth/res/quote_grp/exclude_old'),
    authServiceHistoryList: genUrl('/auth/service/history'),
    authResOrGrpInnerUserBatchDelete: genUrl('/auth/res_or_grp/inner_user/batch_remove'),
    authResOrGrpInnerUserBatchAdd: genUrl('/auth/res_or_grp/inner_user/batch_addnew'),
    /* -----------------------------------------------招聘管理-招聘需求------------------------------------------------------ */
    recruitDemandStatusCategoryList: genUrl('/hr/employ/offical/count_of_status'),
    recruitDemandSearchList: genUrl('/hr/employ/offical/request_list'),
    recruitDemandBatchSetDone: genUrl('/hr/employ/offical/set_request_done'),
    recruitDemandProgress: genUrl('/hr/employ/offical/approval_list'),
    recruitDemandAnswer: genUrl('/hr/employ/offical/answer_request'),
    recruitDemandJobList: genUrl('/hr/employ/offical/jobs'),
    recruitDemandApply: genUrl('/hr/employ/offical/submit_request'),
    recruitDemandDispatchByMe: genUrl('/hr/employ/offical/my_request_list'),
    recruitDemandToDoByMe: genUrl('/hr/employ/offical/my_unhandle_list'),
    recruitDemandDoneByMe: genUrl('/hr/employ/offical/my_handled_list'),
    recruitDemandDetail: genUrl('/hr/employ/offical/request_detail'),
    recruitDemandCancel: genUrl('/hr/employ/offical/cancel_request'),
    // 入职/离职管理
    recruitOnboardJobTemplate: genUrl('/hr/employ/offical/job_template'),
    recruitMokaJobList: genUrl('/hr/employ/offical/moka_open_job_list'),
    recruitBindMokaJob: genUrl('/hr/employ/offical/bind_or_release_moka'),
    recruitBoardingList: genUrl('/hr/boarding/candidate_list'),
    recruitBoardingNewStaff: genUrl('/hr/boarding/add_boarding_staff'),
    recruitBoardingStaffAgain: genUrl('/hr/boarding/entry_again'),
    recruitBoardingSetBoardingTime: genUrl('/hr/boarding/set_boarding_time'),
    recruitBoardingTurnRegular: genUrl('/hr/boarding/candidate_turn_regular'),
    recruitBreachOffer: genUrl('/hr/offer/break_contract'),
    recruitBoardingBreachOffer: genUrl('/hr/boarding/break_contract'),
    recruitBoardingTodayAndTomorrow: genUrl('/hr/boarding/about_to'),
    recruitBoardingCountList: genUrl('/hr/boarding/candidate_list_count'),
    recruitExportFile: genUrl('/hr/boarding/candidate_list_export'),
    /* -----------------------------------------------组织架构------------------------------------------------------ */
    // 查询组织下的员工列表
    orgStaffList: genUrl('/hr/organize/customers_of_team'),
    // 离职员工列表
    orgResignedStaffList: genUrl('/hr/organize/quit_customers'),
    // 设置员工部门
    orgStaffOrgSet: genUrl('/hr/organize/set_customer_team'),
    // 删除一个部门
    orgDel: genUrl('/hr/organize/delete_team'),
    // 添加子部门
    orgSubOrgAdd: genUrl('/hr/organize/create_team'),
    // 编辑一个部门
    orgEdit: genUrl('/hr/organize/edit_team'),
    // 设置部门的金蝶系统 id、财务 id 以及是否为虚拟组织
    orgIdSet: genUrl('/hr/organize/set_team_financial_info'),
    // 上移或者下移一个部门
    orgSetOrder: genUrl('/hr/organize/set_team_sort_order'),
    // 校验手机号是否重复
    orgCheckDupMobile: genUrl('/hr/organize/check_dupl_mobile'),
    // 创建企业邮箱
    orgCreateCorpEmail: genUrl('/hr/organize/autogen_unused_corp_email'),
    // 检验企业邮箱是否可用
    orgCheckDupEmail: genUrl('/hr/organize/check_dupl_email'),
    // 录入新员工
    orgAddNewStaff: genUrl('/hr/organize/add_new_bcz_staff'),
    // 添加特殊员工
    orgAddNewSpecialStaff: genUrl('/hr/organize/add_new_special_staff'),
    /* -----------------------------------------------员工档案------------------------------------------------------ */
    // 实习生转正
    staffPracticeConverted: genUrlWithParams('/hr/staff_info/contract/upgrade_to_probation/{staffId}'),
    // 删除未开始履约的合同
    staffDelContract: genUrlWithParams('/hr/staff_info/contract/{contractId}'),
    // 修改合同
    staffUpdateContract: genUrlWithParams('/hr/staff_info/contract/{contractId}'),
    // 新增合同
    staffAddNewContract: genUrlWithParams('/hr/staff_info/contract/{staffId}'),
    // 查看合同信息
    staffContractDetail: genUrlWithParams('/hr/staff_info/contract_info/{staffId}'),
    // 编辑个人基础信息
    staffUpdateBasicInfo: genUrlWithParams('/hr/staff_info/basic_info/{staffId}'),
    // 添加通讯信息
    staffAddContactInfo: genUrlWithParams('/hr/staff_info/contact/{staffId}'),
    // 编辑通讯信息
    staffUpdateContactInfo: genUrlWithParams('/hr/staff_info/contact/{staffId}'),
    // 查看资料存档
    staffDocumentDetail: genUrlWithParams('/hr/staff_info/document/{staffId}'),
    // 查看岗位信息
    staffJobDetail: genUrlWithParams('/hr/staff_info/job_info/{staffId}'),
    // 查询员工信息
    staffPersonalInfo: genUrlWithParams('/hr/staff_info/personal_info/{staffId}'),
    // 查询多因素验证信息
    staffMfaInfo: genUrlWithParams('/hr/staff_info/mfa/{staffId}'),
    // 添加工作经历
    staffWorkExperienceAdd: genUrlWithParams('/hr/staff_info/work_experience/{staffId}'),
    // 编辑工作经历
    staffWorkExperienceUpdate: genUrlWithParams('/hr/staff_info/work_experience/{staffId}'),
    // 删除工作经历
    staffWorkExperienceDel: genUrlWithParams('/hr/staff_info/work_experience/{workExperienceId}'),
    // 删除教育经历
    staffEducationDel: genUrlWithParams('/hr/staff_info/education_info/{educationId}'),
    // 添加教育经历
    staffEducationAdd: genUrlWithParams('/hr/staff_info/education_info/{staffId}'),
    // 编辑教育经历
    staffEducationUpdate: genUrlWithParams('/hr/staff_info/education_info/{staffId}'),
    // 删除证书
    staffCertDel: genUrlWithParams('/hr/staff_info/certification/{certificationId}'),
    // 添加证书
    staffCertAdd: genUrlWithParams('/hr/staff_info/certification/{staffId}'),
    // 编辑证书
    staffCertUpdate: genUrlWithParams('/hr/staff_info/certification/{staffId}'),
    // 添加账户信息
    staffAccountAdd: genUrlWithParams('/hr/staff_info/account_info/{staffId}'),
    // 编辑账户信息
    staffAccountUpdate: genUrlWithParams('/hr/staff_info/account_info/{staffId}'),
    // 录入新员工
    staffNew: genUrlWithParams('/hr/staff_info/init_basic_info/{staffId}'),
    // 调岗
    staffDocsChange: genUrl('/docs/changeJob'),
    // 离职
    staffDocQuit: genUrl('/docs/quit'),
    // 转正
    staffConverted: genUrlWithParams('/docs/turnRegular/{staffId}'),
    // 转员工类型
    staffTypeChange: genUrlWithParams('/type_transfer/{staffId}/{newType}'),
    /* -----------------------------------------------招聘管理-招聘需求------------------------------------------------------ */
    valueToBeSetDataEmployee: genUrl('/hr/work_attitude_eval/who_to_pick_judge'),
    valueToBeInvolvedEmployee: genUrl('/hr/work_attitude_eval/submit_evaluator'),
    valueToBeEvaluatedList: genUrl('/hr/work_attitude_eval/my_judge_list'),
    valueToSubmit: genUrl('/hr/work_attitude_eval/submit_my_judge'),
    /* -----------------------------------------------绩效------------------------------------------------------ */
    // 绩效目标 hr 相关接口
    performanceAllGoals: genUrl('/hr/kpi/period'),
    performanceNewGoal: genUrl('/hr/kpi/period'),
    performanceGoalsList: genUrlWithParams('/hr/kpi/period/{id}'),
    performanceSearchGoals: genUrlWithParams('/hr/kpi/period/{id}/search'),
    performanceNewGroup: genUrlWithParams('/hr/kpi/period/{id}/add_evaluate_group'),
    performanceArchive: genUrlWithParams('/hr/kpi/period/{id}/archive'),
    performanceGoalsSummary: genUrlWithParams('/hr/kpi/period/{id}/progress'),
    performanceGoalHurry: genUrlWithParams('/hr/kpi/period/{id}/push'),
    performanceGoalSet: genUrlWithParams('/hr/kpi/period/{id}/set'),
    performanceGoalGraph: genUrlWithParams('/hr/kpi/period/{id}/{teamId}'),
    performanceCheckDuplicated: genUrl('/hr/kpi/period/name_conflict'),
    // 绩效指标及标签
    performanceTarget: genUrl('/hr/kpi/indicator'),
    performanceTargetEdit: genUrlWithParams('/hr/kpi/indicator/{id}'),
    performanceTargetBatchMove: genUrl('/hr/kpi/indicator/batch_update'),
    performanceTargetDel: genUrlWithParams('/hr/kpi/indicator/{id}'),
    performanceTargetBatchDel: genUrl('/hr/kpi/indicator/batch_delete'),
    performanceTargetLabel: genUrl('/hr/kpi/indicator_label'),
    performanceTargetNewLabel: genUrl('/hr/kpi/indicator_label'),
    performanceTargetLabelData: genUrl('/hr/kpi/indicator_label/data'),
    performanceTargetLabelDataByIdAndTypeId: genUrl('/hr/kpi/indicator_label/search'),
    performanceTargetLabelUpdate: genUrlWithParams('/hr/kpi/indicator_label/{id}'),
    performanceTargetLabelDel: genUrlWithParams('/hr/kpi/indicator_label/{id}'),
    performanceTargetLabelType: genUrl('/hr/kpi/indicator_type'),
    // 员工绩效目标
    performanceGoal: genUrlWithParams('/hr/kpi/staff_goal/{goalId}'),
    performanceGoalsToBeChecked: genUrl('/hr/kpi/staff_relate/flow_on_me'),
    performanceGoalToReview: genUrlWithParams('/hr/kpi/staff_relate/goal/{id}'),
    performanceGoalUpdate: genUrlWithParams('/hr/kpi/staff_relate/goal_detail/{goalDetailId}'),
    performanceGoalDel: genUrlWithParams('/hr/kpi/staff_relate/goal_detail/{goalDetailId}'),
    performanceGoalOperation: genUrl('/hr/kpi/staff_relate/goal_operation'),
    performanceGoals: genUrlWithParams('/hr/kpi/staff_relate/goals/{periodId}'),
    performanceBatchCopyGoals: genUrlWithParams('/hr/kpi/staff_relate/goals/multi/{goalId}'),
    performanceGoalContentNew: genUrlWithParams('/hr/kpi/staff_relate/goals/{goalId}'),
    performanceTeamGoal: genUrlWithParams('/hr/kpi/staff_relate/team_goal/{periodId}/{goalId}'),
    performanceTeamGoalMap: genUrlWithParams('/hr/kpi/staff_relate/team_goal_map/{periodId}/{teamId}'),
    performanceTeamGoalList: genUrlWithParams('/hr/kpi/staff_relate/team_goal_list/{periodId}/{teamId}'),
    performancePersonalGoalSearch: genUrl('/hr/kpi/staff_relate/goals/search/'),
    performanceReviewList: genUrlWithParams('/hr/kpi/feedback/subordinate/{periodId}'),
    performanceReviewGenerate: genUrlWithParams('/hr/kpi/feedback/{goalId}'),
    // 考评组
    performanceGroup: genUrl('/hr/kpi/evaluate_group'),
    performanceGroupNew: genUrl('/hr/kpi/evaluate_group'),
    performanceGroupDetail: genUrlWithParams('/hr/kpi/evaluate_group/{id}'),
    performanceGroupUpdate: genUrlWithParams('/hr/kpi/evaluate_group/{id}'),
    performanceGroupDelete: genUrlWithParams('/hr/kpi/evaluate_group/{id}'),
    performanceStaffListByTeam: genUrlWithParams('/hr/kpi/evaluate_group/team_info/{teamId}'),
    performanceArchiveOrg: genUrlWithParams('/archive_data/{periodId}'),
    performanceArchiveOrgAll: genUrlWithParams('/archive_data/all/{periodId}'),
    getUsersInSearch: genUrl('/hr/kpi/evaluate_group/search_staff'),
    performanceEvaluateList: genUrl('/hr/kpi/evaluate/period'),
    performanceEvaluateStart: genUrl('/hr/kpi/evaluate/period'),
    performanceEvaluateUpdate: genUrl('/hr/kpi/evaluate/period'),
    performanceEvaluateArchive: genUrlWithParams('/hr/kpi/evaluate/period/archive/{periodId}'),
    performanceEvaluatePush: genUrlWithParams('/hr/kpi/evaluate/period/send_push/{periodId}'),
    performanceEvaluateNormalList: genUrlWithParams('/hr/kpi/evaluate/goal/query/{periodId}'),
    performanceEvaluateKpiLevel: genUrl('/hr/kpi/evaluate/goal/hr_modify_kpi_level'),
    performanceEvaluateSelfEvaluate: genUrl('/hr/kpi/evaluate/goal/self_evaluate'),
    performanceEvaluateSuperiorEvaluate: genUrl('/hr/kpi/evaluate/goal/superior_evaluate'),
    performanceEvaluateResultConfirm: genUrl('/hr/kpi/evaluate/goal/confirm'),
    performanceEvaluateListOnMe: genUrl('/hr/kpi/evaluate/goal_on_me'),
    performanceEvaluateSetKpiLevels: genUrl('/hr/kpi/evaluate/goal/superior_kpi_level'),
    performanceEvaluateKpiConfirm: genUrl('/hr/kpi/evaluate/goal/confirm'),
    // 绩效评估改版接口
    performanceEvaluateBehaviorSave: genUrl('/hr/kpi/result/leader/evaluate/behavior'),
    performanceEvaluateLeaderSave: genUrlWithParams('/hr/kpi/result/leader/evaluate/{goalId}'),
    performanceEvaluateTeamList: genUrlWithParams('/hr/kpi/result/leader/team/{teamId}'),
    performanceEvaluateWaitingForMe: genUrlWithParams('/hr/kpi/result/leader/todo/{teamId}'),
    performanceEvaluateSelfList: genUrl('/hr/kpi/result/self/evaluate'),
    performanceEvaluateSelfSave: genUrlWithParams('/hr/kpi/result/self/evaluate/{goalId}'),
    performanceEvaluateKpiResult: genUrlWithParams('/hr/kpi/result/{resultId}'),
    performanceEvaluateArchiveOrg: genUrl('/hr/kpi/result/archived/org-tree'),
    performanceEvaluateFinalGradeSave: genUrl('/hr/kpi/result/leader/evaluate/final-grade'),
    performanceEvaluateGoalDetail: genUrlWithParams('/hr/kpi/result/goal/{goalId}'),
    performanceEvaluateNonKpiScoreSave: genUrl('/hr/kpi/result/leader/evaluate/non-kpi-score'),
    performanceExportResult: genUrlWithParams('/hr/kpi/result/export/{kpiResultDetailId}'),
    performanceEvaluateDisable: genUrl('/hr/kpi/result/self/evaluate/deadline'),
    // 离职管理
    // 离职管理各类型员工数量
    quitStaffSummaryCount: genUrl('/hr/quit_manage/cnt_of_customer_type'),
    // 离职员工列表
    quitStaffList: genUrl('/hr/quit_manage/quit_customer_list'),
    // 设置离职时间
    quitTime: genUrl('/hr/quit_manage/set_quit_time_ut'),
    // 删除离职审批
    quitRecord: genUrl('/hr/quit_manage/del_quit_record'),
    // KPI临时看板
    // 按部门获取kpi列表
    kpiDashboardList: genUrl('/hr/kpi/watchboard/mylist'),
    // 提交KPI打分
    kpiDashboardScore: genUrl('/hr/kpi/watchboard/submit_score'),
    // 发展计划列表
    plan: genUrl('/hr/kpi/plan'),
    planDetail: genUrlWithParams('/hr/kpi/plan/{id}'),
    // hr工具
    // 添加一个岗位
    hrToolAddJob: genUrl('/common/tool_script/hand_add_job'),
    // 下载所有人汇报关系
    hrToolDownloadRel: genUrl('/common/tool_script/download_leader_relationship'),
    // 下载指定类型用户信息
    hrToolDownloadStaff: genUrl('/common/tool_script/download_staff_info'),
    // 同步实习生Leader
    hrToolSyncInternLeader: genUrl('/common/tool_script/force_sync_intern_leader'),
    // 设置离职实习生leader
    hrToolSetInternLeader: genUrl('/common/tool_script/force_sync_intern_leader_of_quit'),
    // 删除员工信息
    hrToolDelCustomer: genUrl('/common/tool_script/delete_customer_by_customer_id'),
    // 设置实习生考勤标签
    hrToolSetInternLabel: genUrl('/common/tool_script/update_intern_clock_tag'),
    // 财务工具
    financeToolExportBeisenSalaryEmployee: genUrl('/beisen-salary-employee/export'),
    financeToolRefreshBeisenSalaryEmployee: genUrl('/beisen-salary-employee/refresh'),
    // offer管理
    // 产研员工 offer list
    offerList: genUrl('/hr/offer/offer_list'),
    // 产研员工 添加offer
    offerAdd: genUrl('/hr/offer/add_offer'),
    // 产研员工 设置offer薪资
    offerSalarySet: genUrl('/hr/offer/set_offer_salary'),
    // 产研员工 编辑offer
    offerEdit: genUrlWithParams('/hr/offer/edit_offer/{candidateId}'),
    // 产研员工 编辑渠道3人员
    offerChannelHostSet: genUrl('/hr/offer/set_referer'),
    // 获取offer
    offerGet: genUrlWithParams('/hr/offer/{offerId}'),
    // offer转出
    offerListExport: genUrl('/hr/offer/offer_list_export'),
    // 设置薪资隐藏权限
    offerSetSalaryHidden: genUrl('/hr/offer/set_salary_hidden_flag'),
    // 获取申请渠道
    offerGetRequestChannelList: genUrl('/hr/offer/get_request_channel_list'),
    // 设置申请渠道
    offerSetRequestChannel: genUrl('/hr/offer/set_request_channel'),
    // 费用 检测费用是否发放
    costCheckFeeStatus: genUrlWithParams('/hr/cost/check_cost_details_payed/{offerId}'),
    // 费用列表
    costList: genUrl('/hr/cost/cost_list'),
    // 设置内推总费用
    costIntroducingSet: genUrl('/hr/cost/set_inner_push_total_cost'),
    // 获取费用列表
    costDetailsList: genUrl('/hr/cost/cost_details_list'),
    // 发放费用
    costPay: genUrl('/hr/cost/cost_pay'),
    // 发放费用取消
    costPayCancel: genUrl('/hr/cost/cost_pay_cancel'),
    // 内推发放记录
    introducingPayRecordList: genUrl('/hr/cost/pay_record_inner_push'),
    // 设置猎头费用方案
    costSetHunterCostPlan: genUrl('/hr/cost/set_head_hunting_cost_plan'),
    // 费用规则列表
    costRuleList: genUrl('/hr/cost/cost_rule_list'),
    // 猎头费用记录
    hunterPayRecordList: genUrl('/hr/cost/pay_record_head_hunting'),
    // 检查是否有猎头费用
    checkHunterFeePayedOrNot: genUrl('/hr/cost/check_head_hunting_cost_payed'),
    // 编辑费用规则
    editCostRule: genUrlWithParams('/hr/cost/edit_cost_rule/{pid}'),
    // 添加费用规则
    addCostRule: genUrl('/hr/cost/add_cost_rule'),
    // 检测猎头名称是否重复
    checkDuplName: genUrl('/hr/cost/check_dupl_name'),
    // 删除方案
    deleteCostRule: genUrlWithParams('/hr/cost/delete_cost_rule/{pid}'),
    // 费用统计
    summaryFeeStatistics: genUrl('/hr/cost/cost_statistics'),
    // 费用趋势
    summaryTrendStatistics: genUrl('/hr/cost/cost_trend'),
    // 费用统计下载
    summaryStatisticsDownload: genUrl('/hr/cost/cost_statistics_download'),
    // 查询leader
    staffInfoGetLeaderByCustomerId: genUrl('/hr/staff_info/get_leader_by_customer_id'),
    // 设置薪资隐藏权限
    offerSetInternSalaryHiddenFlag: genUrl('/hr/offer/set_intern_salary_hidden_flag'),
    // 入职同步列表
    administrationOfferList: genUrl('/administration/induction_synchronize/offer_list'),
    // 设置设备型号
    administrationDeviceModelSet: genUrl('/administration/induction_synchronize/set_device_model'),
    // 设置设备准备情况
    administrationDevicePrepareSet: genUrl('/administration/induction_synchronize/set_device_prepare_description'),
    // 设置入职福利
    administrationInductionWelfareSet: genUrl('/administration/induction_synchronize/set_induction_welfare'),
    // 在职员工、离职员工列表
    administration3AccountCustomerList: genUrl('/administration/thirdparty_account/customers_list'),
    // 三方账号 在职、离职员工导出
    administration3AccountExportList: genUrl('/administration/thirdparty_account/customers_list_export'),
    // 设置wifi账号
    administrationWifiSet: genUrl('/administration/thirdparty_account/set_wifi_account'),
    // 设置滴滴账号
    administrationDiDiSet: genUrl('/administration/thirdparty_account/set_didi_account'),
    // 设置门禁
    administrationAccessSet: genUrl('/administration/thirdparty_account/set_access'),
    // 设置备注
    administrationAccountRemarkSet: genUrl('/administration/thirdparty_account/set_remark'),
    /* -----------------------------------------------账号管理------------------------------------------------------ */
    // 法务账号系统-公司列表
    lawAccountCompanyList: genUrl('/ams/common/companies'),
    // 法务账号系统-登录方式列表
    lawAccountLoginMethods: genUrl('/ams/common/login_methods'),
    // 法务账号系统-项目组列表
    lawAccountTeams: genUrl('/ams/common/teams'),
    // 法务账号系统-账号接收人列表
    lawAccountEmployees: genUrl('/ams/common/employees'),
    // 法务账号系统-新增平台
    lawAccountNewPlatform: genUrl('/ams/platform'),
    // 法务账号系统-编辑平台
    lawAccountUpdatePlatform: genUrlWithParams('/ams/platform/{id}'),
    // 法务账号系统-删除平台
    lawAccountDelPlatform: genUrlWithParams('/ams/platform/{id}'),
    // 法务账号系统-平台列表
    lawAccountPlatformAll: genUrl('/ams/platform/list'),
    // 法务账号系统-平台列表-分页
    lawAccountPlatformList: genUrl('/ams/platform/page'),
    // 法务账号系统-账号管理-新增账号
    lawAccountNewAccount: genUrl('/ams/account'),
    // 法务账号系统-账号管理-编辑账号-基础信息
    lawAccountUpdateAccountBasic: genUrlWithParams('/ams/account/{id}'),
    // 法务账号系统-账号管理-编辑账号-修改密码
    lawAccountUpdateAccountPwd: genUrlWithParams('/ams/account/{id}/password'),
    // 法务账号系统-账号管理-删除账号
    lawAccountDelAccount: genUrlWithParams('/ams/account/{id}'),
    // 法务账号系统-账号管理-查看账号密码
    lawAccountLookPassword: genUrlWithParams('/ams/account/{id}/password'),
    // 法务账号系统-账号管理-查看账号详情-基本信息
    lawAccountDetailBasic: genUrlWithParams('/ams/account/{id}'),
    // 法务账号系统-账号管理-查看账号详情-使用情况
    lawAccountUsageList: genUrlWithParams('/ams/account/{id}/applied'),
    // 法务账号系统-账号管理-查看账号详情-修改历史列表
    lawAccountHistoryList: genUrlWithParams('/ams/account/{id}/history'),
    // 法务账号系统-账号管理-账号列表
    lawOwnedAccountList: genUrl('/ams/account/owned'),
    lawAppliedAccountList: genUrl('/ams/account/applied'),
    // 法务账号系统-账号管理-账号列表-账号停用/启用
    lawAccountStatusChange: genUrlWithParams('/ams/account/{id}/change_status'),
    // 法务账号系统-账号管理-账号列表-同意/拒绝/取消申请/停止共享
    lawAccountAppliedStatus: genUrlWithParams('/ams/account/{id}/applied/{appliedId}/change_status'),
    // 法务账号系统-账号管理-申请页面-申请账号
    lawAccountApply: genUrlWithParams('/ams/account/{id}/apply'),
    // 法务账号系统-账号管理-申请页面-申请列表
    lawAccountApplyList: genUrl('/ams/account/apply'),
    // 法务账号系统-账号管理-申请页面-申请历史
    lawAccountApplyHistoryList: genUrl('/ams/account/applied/history'),
    // 法务账号系统-账号管理-移交账号
    lawAccountTransfer: genUrlWithParams('/ams/account/{id}/transfer'),
    // 法务账号系统-账号管理-移交账号-取消
    lawAccountChangeTransferStatus: genUrlWithParams('/ams/account/{id}/transfer/{transferId}/change_status'),
    // 法务账号系统-账号管理-移交账号的列表
    lawAccountTransferList: genUrl('/ams/account/transfers'),
    // 法务账号系统-账号管理-移交账号-历史列表
    lawAccountTransferHistoryList: genUrl('/ams/account/transfer/histories'),
    // 法务账号系统-账号管理-账号申请待处理列表
    lawAccountAppliedProcessPendingList: genUrl('/ams/account/applied/process_pendings'),
    // 法务账号系统-账号管理-账号申请处理历史列表
    lawAccountAppliedProcessHistoryList: genUrl('/ams/account/applied/process_histories'),
    // 法务账号系统-账号管理-待处理申请数量
    lawAccountAppliedProcessPendingCount: genUrl('/ams/account/applied/process_pendings/count'),
    // 法务账号系统-账号管理-账号移交待处理数量
    lawAccountTransferCount: genUrl('/ams/account/transfers/count'),
    // 法务账号系统-账号管理-账号历史密码查看
    lawAccountHistoryPwd: genUrlWithParams('/ams/account/history/{id}/password'),
    // 法务账号系统-账号管理-账号历史密码批量查看
    lawAccountHistoryPwdBatch: genUrlWithParams('/ams/account/{id}/history/passwords'),
    // 法务账号系统-账号管理-账号列表-分配账号
    lawAccountAssign: genUrlWithParams('/ams/account/{id}/assign'),
    // 查看账号公示状态是否可编辑
    lawAccountPublicEditable: genUrlWithParams('/ams/account/{id}/public_editable'),
    // 检查法务合规是否重复确认
    lawComplianceConfirmDuplicated: genUrl('/hr/legal/message/check_repeat_confirmed'),
    // 法务合规确认
    lawComplianceConfirm: genUrl('/hr/legal/message/confirmed'),
    /* -----------------------------------------------团队管理------------------------------------------------------ */
    // 员工负责的部门信息
    teamInfo: genUrl('/team/team_info_of_manage'),
    // 一级部门logo
    teamLogo: genUrl('/team/root_team_logo'),
    // 人员 概要
    teamStatisticsSummary: genUrl('/team/customer_of_team_statistics'),
    // 在职人员详情
    teamCustomerStatistics: genUrl('/team/customer_statistics'),
    // 人员变动趋势
    teamChangeTrend: genUrl('/team/customer_change_trend'),
    // 即将入职列表
    teamCandidateList: genUrl('/team/candidate_list'),
    // 即将离职列表
    teamQuitingList: genUrl('/team/quit_customer_list'),
    /* -----------------------------------------------员工管理------------------------------------------------------ */
    // 筛选条件
    staffManagementFilteredConditionList: genUrl('/hr/customer/manage/condition_list'),
    // 员工列表
    staffManagementList: genUrl('/hr/customer/manage/customer_list'),
    // 导出结果
    staffManagementListExport: genUrl('/hr/customer/manage/customer_list_export'),
    // 设置岗位
    staffManagementSetJob: genUrl('/common/staff/set_job_by_customer_ids'),
    // 设置部门
    staffManagementSetOrg: genUrl('/common/staff/set_team_by_customer_ids'),
    // 离职原因获取
    staffManagementQuitReason: genUrl('/hr/customer/manage/quit_reason_tree'),
    // 等保日志审计列表
    dengbaoLogList: genUrl('/audit/log/get_api_log_list'),
    // 检查hr销服《员工手册》消息是否已确认
    staffPrincipleConfirmCheck: genUrl('/hr/message/check_repeat_confirmed'),
    // 员工手册》消息确认
    staffPrincipleConfirm: genUrl('/hr/message/confirmed'),
    // 法务-获取查询申请记录
    assetsSearchApplyRecord: genUrl('/assets/search_apply_record'),
    // 法务-管理
    assetsSearchCertificateManage: genUrl('/assets/search_certificate_of_manage'),
    // 法务-获取证书列表
    assetsSearchCertificate: genUrl('/assets/search_certificate'),
    // 法务-新增证书
    assetsAddCertificate: genUrl('/assets/add_certificate'),
    // 法务-获取资质详情
    assetsGetCertificateById: genUrl('/assets/get_certificate_by_id'),
    // 法务-编辑资质
    assetsUpdateCertificate: genUrl('/assets/update_certificate'),
    // 法务-删除
    assetsDeleteCertificate: genUrl('/assets/delete_certificate'),
    // 法务-申请
    assetsApplyCertificate: genUrl('/assets/apply_certificate'),
    // 法务-检查是否需要审核
    assetsCheckApplyRecordAuditFlag: genUrl('/assets/check_apply_record_audit_flag'),
    // 法务-文件下载
    assetsDownloadCertificate: genUrl('/assets/download_certificate'),
    // 法务-审批
    assetsApplyCertificateAudit: genUrl('/assets/apply_certificate_audit'),
    // hr看板
    // 获取部门人数看板
    hrEmploymentPlanGetTeamsNumberOfPeopleInfo: genUrl('/hr/employment/plan/get_teams_number_of_people_info'),
    hrEmploymentPlanSetExplainCellValueOfCompany: genUrl('/hr/employment/plan/set_explain_cell_value_of_company'),
    hrEmploymentPlanSetCellValueOfCompany: genUrl('/hr/employment/plan/set_cell_value_of_company'),
    hrEmploymentPlanGetAllNumberOfPeopleTrendInfo: genUrl('/hr/employment/plan/get_all_number_of_people_trend_info'),
    hrEmploymentPlanSetCellValueOfAllNumberOfPeopleTrend: genUrl('/hr/employment/plan/set_cell_value_of_all_number_of_people_trend'),
    // 考勤日报
    attendanceGetVacationDaily: genUrl('/hr/attendance/get_vacation_daily'),
    // 获取请假类型
    attendanceGetVacationType: genUrl('/hr/attendance/get_vacation_type'),
    // 考勤月报
    attendanceGetVacationMonth: genUrl('/hr/attendance/get_vacation_month'),
    // 更新月报
    attendanceUpdateMonthInfo: genUrl('/hr/attendance/update_month_info'),
    // 更新月报-本月实际请假天数
    attendanceUpdateMonthInfoTwo: genUrl('/hr/attendance/update_month_info_two'),
    // 导入薪酬系统
    attendanceUpdateNotifyMis: genUrl('/hr/attendance/notify_mis'),
    // 发薪人员名单
    beisenSalaryEmployeeGetSalaryEmployee: genUrl('/beisen-salary-employee/get_salary_employee'),
    // 北森
    beisenSalaryEmployeeGetSalaryEmployeeInfoByCustomerId: genUrl('/beisen-salary-employee/get_salary_employee_info_by_customer_id'),
    // 新建
    beisenSalaryEmployeeAddSalaryEmployeeId: genUrl('/beisen-salary-employee/add_salary_employee'),
    // 编辑
    beisenSalaryEmployeeUpdateSalaryEmployee: genUrl('/beisen-salary-employee/update_salary_employee'),
    // 删除
    beisenSalaryEmployeeDeleteSalaryEmployee: genUrl('/beisen-salary-employee/delete_salary_employee'),
};
