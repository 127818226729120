import useLoadingDelay from '@src/hooks/useLoadingDelay';
import { useCallback, useState } from 'react';
const useRequestWithCallback = (request, initLoading, initSuccess) => {
    const [loading, updateLoading] = useLoadingDelay(initLoading);
    const [success, updateSuccess] = useState(initSuccess);
    const callback = useCallback((...args) => {
        updateLoading(true);
        return request(...args).finally(() => {
            updateLoading(false);
        }).then((res) => {
            updateSuccess(true);
            return res;
        }).catch((err) => {
            updateSuccess(false);
            return Promise.reject(err);
        });
    }, [request]);
    return [callback, loading, success, updateLoading];
};
export default useRequestWithCallback;
