import { Offer } from '@src/pages/HR/Offer/interface';
import apiUrls, { genUrlWithQuery } from '@src/api/urls';
export const defaultTabKey = Offer.TabKeys.regular;
export const defaultOfferUrlQuery = {
    tab_key: defaultTabKey,
};
export const offerUrl = genUrlWithQuery('/hr/offer');
export const offerExportUrl = genUrlWithQuery(apiUrls.offerListExport);
export const salaryMaskStr = '****';
export const isSalaryMasked = (v) => Object.is(`${v}`, salaryMaskStr);
