import { User } from '@src/api/interface';
import { lazy } from 'react';
import { gluer } from 'femo';
import { getSafe, queryToObject } from '@src/tools/util';
import { defaultOffer2RegularListQuery } from '@src/pages/HR/Offer2/NotRegular/OfferList';
import { OfferStatus } from '@src/pages/components/filter/StaffStatusCheckbox';
import { fetchOfferList, fetchQuitList } from '@src/pages/HR/Offer2/NotRegular/service';
import urlQueryToApiQuery from '@src/pages/HR/Offer/Regular/urlQueryToApiQuery';
import { Offer } from '@src/pages/HR/Offer/interface';
import { defaultOffer2UrlQuery } from '@src/pages/HR/Offer2/url';
import { Offer2 } from '@src/pages/HR/Offer2/interface';
import { defaultNotRegularUrlQuery } from '@src/pages/HR/Offer2/NotRegular';
import { defaultOffer2QuitListQuery } from '@src/pages/HR/Offer2/NotRegular/QuitList';
const initOffer2Road = {
    name: 'Offer管理2',
    path: '/offer2',
    permissions: [User.ModuleHRSubAuthKey.menu_hr_offer2],
    component: lazy(() => import('./index')),
    prepare: (routeParams, control) => {
        const { location } = routeParams;
        const search = getSafe(location, 'search', '');
        const query = queryToObject(search, {
            ...defaultOffer2UrlQuery,
        }, true);
        // 一级tab为 非产研员工
        if (query.tab_key === Offer2.TabKeys.notRegular) {
            const tmpQuery = queryToObject(search, {
                ...defaultNotRegularUrlQuery,
            }, true);
            // 二级tab为 offer列表
            if (tmpQuery.sub_key === Offer2.SubKeys.not_regular_offer_list) {
                const curQuery = queryToObject(search, {
                    ...defaultOffer2RegularListQuery,
                    s_status: [`${OfferStatus.todo}`],
                });
                control.silent((_d, s) => ({
                    ...s,
                    key: Offer2.SubKeys.not_regular_offer_list,
                }));
                return fetchOfferList(urlQueryToApiQuery(curQuery, Offer.ListType.waitingBoard, Offer.OfferManagerSource.notRegularOffer));
            }
            // 二级tab为 毁约列表
            if (tmpQuery.sub_key === Offer2.SubKeys.not_regular_quit_list) {
                const curQuery = queryToObject(search, {
                    ...defaultOffer2QuitListQuery,
                });
                control.silent((_d, s) => ({
                    ...s,
                    key: Offer2.SubKeys.not_regular_quit_list,
                }));
                return fetchQuitList(urlQueryToApiQuery(curQuery, Offer.ListType.break_contract, Offer.OfferManagerSource.notRegularOffer));
            }
        }
        return Promise.resolve();
    },
};
const road = gluer(initOffer2Road);
export default road;
