import React from 'react';
import { Form, Input } from 'antd';
const NameField = (props) => {
    const { placeholder, ...restProps } = props;
    return (<Form.Item {...restProps}>
      <Input autoComplete='off' placeholder={placeholder}/>
    </Form.Item>);
};
NameField.defaultProps = {
    label: '姓名',
    placeholder: '请输入',
};
export default NameField;
