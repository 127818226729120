import React, { useCallback, } from 'react';
import { getSafe, isEmpty, regex } from '@src/tools/util';
import { Form, Input } from 'antd';
import { organization } from '@src/api/actions';
import EmailSuffixSelect, { EmailSuffix } from '@src/pages/components/EmailSuffixSelect';
import { useDerivedState, useIndividualModel } from 'femo';
import style from './style.less';
const defaultSuffix = EmailSuffix.baicizhan;
const EnterpriseEmailInput = (props) => {
    const { value, onChange, canSelectEmail, initEmailSuffix, closeWarning, isRequired, ...rest } = props;
    const [, suffixModel] = useIndividualModel(initEmailSuffix);
    const [emailParts] = useDerivedState(() => `${(value || '')}`.split('@'), () => {
        const arr = `${(value || '')}`.split('@');
        if (value) {
            const tmp = arr[1];
            suffixModel.silent((_d, s) => {
                if (!isRequired && !arr[0] && onChange) {
                    onChange('');
                }
                else if (!tmp && arr[0] && onChange) {
                    onChange(`${arr[0]}${s}`);
                }
                if (tmp && `${tmp}`.startsWith('@')) {
                    return tmp;
                }
                if (tmp && !(`${tmp}`.startsWith('@'))) {
                    return `@${tmp}`;
                }
                return s;
            });
        }
        return arr;
    }, [value]);
    const onInputChange = useCallback((e) => {
        let suffix = getSafe(emailParts, '[1]', suffixModel());
        if (suffix.startsWith('@')) {
            suffix = suffix.substring(1);
        }
        const prefix = getSafe(e, 'target.value', '');
        if (onChange) {
            onChange(prefix ? `${prefix}@${suffix}` : '');
        }
    }, [onChange, emailParts]);
    const onSuffixChange = useCallback((key) => {
        const prefix = getSafe(emailParts, '[0]');
        if (onChange) {
            onChange(prefix ? `${prefix}${key}` : '');
        }
        if (!prefix) {
            suffixModel(key);
        }
        else {
            suffixModel.silent(key);
        }
    }, [onChange, emailParts]);
    const prefix = getSafe(emailParts, '[0]');
    return (<section className={style.enterpriseEmail}>
      <section>
        <Input onChange={onInputChange} value={prefix} autoComplete='off' placeholder='请输入' addonAfter={<EmailSuffixSelect disabled={!canSelectEmail} onChange={onSuffixChange} value={suffixModel()}/>} {...rest}/>
      </section>
      {!closeWarning && suffixModel() === EmailSuffix.hfzxtech ? <section className='waring'>该邮箱暂未对接，需到邮箱后台手动创建后进行填写</section> : null}
    </section>);
};
const getRules = (disableCheck, checkCustomerId, customerId) => [{
        validator: (_rule, value) => {
            const arr = `${value || ''}`.split('@');
            const prefix = getSafe(arr, '[0]', '');
            if (!isEmpty(prefix) && !regex.emailPrefix(prefix)) {
                return Promise.reject('只能包含字母、数字、下划线、点');
            }
            return Promise.resolve();
        },
    }, !disableCheck && {
        validator: (_rule, value) => {
            const prefix = getSafe(`${value || ''}`.split('@'), '[0]', '');
            if (regex.emailPrefix(prefix)) {
                return organization.checkDupEmail({
                    email: value,
                }).catch(() => Promise.resolve()).then((result) => {
                    const duplicated = getSafe(result, 'data.duplicated');
                    if (checkCustomerId) {
                        const cid = getSafe(result, 'data.customer_id');
                        if (!isEmpty(customerId) && cid === customerId) {
                            return Promise.resolve();
                        }
                    }
                    if (duplicated) {
                        return Promise.reject(<span>邮箱重复，请重新输入</span>);
                    }
                    return Promise.resolve();
                });
            }
            return Promise.resolve();
        },
        validateTrigger: ['onChange', 'onSubmit'],
    }].filter(Boolean);
const EnterpriseEmailField = (props) => {
    const { placeholder, disableCheck, customerId, checkCustomerId, canSelectEmail, initEmailSuffix, closeWarning, isRequired, ...restProps } = props;
    const rules = getRules(disableCheck, checkCustomerId, customerId);
    return (<Form.Item validateFirst {...restProps} rules={restProps.rules ? [...restProps.rules, ...rules] : rules}>
      <EnterpriseEmailInput isRequired={isRequired} closeWarning={closeWarning} canSelectEmail={canSelectEmail} initEmailSuffix={initEmailSuffix} placeholder={placeholder}/>
    </Form.Item>);
};
EnterpriseEmailField.defaultProps = {
    relatedFields: [],
    label: '企业邮箱',
    autoGen: true,
    placeholder: '请输入（选填）',
    disableCheck: false,
    checkCustomerId: false,
    canSelectEmail: false,
    initEmailSuffix: defaultSuffix,
    closeWarning: false,
    isRequired: false,
};
export default EnterpriseEmailField;
