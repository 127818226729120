import React from 'react';
import { Form } from 'antd';
import CompanySelect from '@src/pages/components/CompanySelect';
const CompanyField = (props) => (<Form.Item {...props}>
      <CompanySelect placeholder='请选择'/>
    </Form.Item>);
CompanyField.defaultProps = {
    label: '公司主体',
};
export default CompanyField;
