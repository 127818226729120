import { ProfileOutlined } from '@ant-design/icons';
import { User } from '@src/api/interface';
import { RoadMapType } from '@src/pages/interface';
import { gluer } from 'femo';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import offerRoad from './Offer/road';
import offer2Road from './Offer2/road';
const initialState = {
    name: 'HR系统',
    path: '/hr',
    permissions: [User.ModuleAuthKey.module_hr],
    component: () => <Redirect to='/hr/org'/>,
    icon: ProfileOutlined,
    subRoads: [{
            name: '人事运营',
            path: '/operation',
            permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./Operation')),
            subRoads: [
                {
                    type: RoadMapType.fallen,
                    name: '试用期管理',
                    path: '/probation',
                    component: () => <Redirect to='/hr/operation'/>,
                },
                {
                    type: RoadMapType.fallen,
                    name: '添加试用期目标',
                    path: '/probation/:stageId/:staffId/purpose/add',
                    component: lazy(() => import('./Operation/Probation/Purpose/AddPurpose')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '试用期目标',
                    path: '/probation/:stageId/:staffId/purpose/view',
                    component: lazy(() => import('./Operation/Probation/Purpose/ViewPurpose/ViewPurposeWithRouter')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '合作者名单',
                    path: '/probation/:stageId/:staffId/cooperator/view',
                    component: lazy(() => import('./Operation/Probation/Cooperator/ViewCooperator/ViewCooperatorWithRouter')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '添加合作者',
                    path: '/probation/:stageId/:staffId/cooperator/add',
                    component: lazy(() => import('./Operation/Probation/Cooperator/AddCooperator')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '员工自评表',
                    path: '/probation/:stageId/:staffId/survey/self-evaluation',
                    component: lazy(() => import('./Operation/Probation/Survey/SelfEvaluation')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '工作行为反馈',
                    path: '/probation/:stageId/:staffId/survey/feedback/leader',
                    component: lazy(() => import('./Operation/Probation/Survey/Feedback/ToLeader')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '工作行为反馈',
                    path: '/probation/:stageId/:staffId/survey/feedback/cooperator',
                    component: lazy(() => import('./Operation/Probation/Survey/Feedback/ToCooperator')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '问卷结果报告',
                    path: '/probation/:stageId/:staffId/survey/report',
                    component: lazy(() => import('./Operation/Probation/Survey/Report/ReportWithRouter')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '反思与总结',
                    path: '/probation/:stageId/:staffId/summary/self-reflect',
                    component: lazy(() => import('./Operation/Probation/firstStage/Summary/SelfReflect')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '阶段报告',
                    path: '/probation/:stageId/:staffId/summary/report',
                    component: lazy(() => import('./Operation/Probation/firstStage/Summary/Report/ReportWithRouter')),
                    hasHeader: false,
                    hasSider: false,
                },
                {
                    type: RoadMapType.fallen,
                    name: '转正结果',
                    path: '/probation/:stageId/:staffId/formal/result',
                    component: lazy(() => import('./Operation/Probation/secondStage/BecomingFormalResult/BecomingFormalResultWithRouter')),
                    hasHeader: false,
                    hasSider: false,
                },
                {
                    type: RoadMapType.fallen,
                    name: '填写1 on 1反馈',
                    path: '/probation/:stageId/:staffId/1on1/feedback/add',
                    component: lazy(() => import('./Operation/Probation/OneOnOne/AddFeedback')),
                    hasHeader: false,
                    hasSider: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '1 on 1反馈',
                    path: '/probation/:stageId/:staffId/1on1/feedback/view',
                    component: lazy(() => import('./Operation/Probation/OneOnOne/ViewFeedback/ViewFeedbackWithRouter')),
                    hasHeader: false,
                    hasSider: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '入职管理',
                    path: '/boarding',
                    component: lazy(() => import('./Operation/BoardingManagement/weixin/BreachAndOnBoardingList')),
                    hasSider: false,
                    hasHeader: false,
                }, {
                    type: RoadMapType.fallen,
                    name: '备餐提醒',
                    path: '/boarding/food-tip',
                    component: lazy(() => import('./Operation/BoardingManagement/weixin/TodayAndTomorrows')),
                    hasHeader: false,
                    hasSider: false,
                },
            ],
        }, {
            name: '招聘管理',
            path: '/recruit',
            permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_employ_official]),
            component: lazy(() => import('./Recruit')),
        }, offerRoad(), offer2Road(), {
            name: '组织架构',
            path: '/org',
            permissions: [User.ModuleHRSubAuthKey.menu_hr_org],
            component: lazy(() => import('./Organization')),
            subRoads: [{
                    type: RoadMapType.fallen,
                    name: '员工详情',
                    path: '/staff/detail',
                    permissions: [],
                    component: lazy(() => import('./Organization/Staff/Detail/DetailForPage')),
                }, {
                    type: RoadMapType.fallen,
                    name: '信息填写',
                    path: '/staff/new',
                    permissions: [],
                    component: lazy(() => import('./Organization/Staff/New')),
                    hasHeader: false,
                    hasSider: false,
                }],
        }, {
            name: '员工管理',
            path: '/sm',
            permissions: [User.ModuleHRSubAuthKey.menu_hr_customer_manage],
            component: lazy(() => import('@src/pages/HR/StaffManagement')),
        }, {
            name: '绩效管理',
            path: '/performance',
            permissions: [User.ModuleHRSubAuthKey.menu_hr_performance],
            component: lazy(() => import('./Performance/admin')),
        },
        {
            name: '招聘申请',
            path: '/recruit/apply/list',
            component: lazy(() => import('./Recruit/Demand/Apply/List')),
            hasSider: false,
            hasHeader: false,
            type: RoadMapType.fallen,
        }, {
            name: '招聘详情',
            path: '/recruit/apply/detail',
            component: lazy(() => import('./Recruit/Demand/Apply/Detail/ApplyDetailWithRouter')),
            hasSider: false,
            hasHeader: false,
            type: RoadMapType.fallen,
        }, {
            name: '',
            path: '/value',
            hasSider: false,
            hasHeader: false,
            type: RoadMapType.fallen,
            subRoads: [{
                    type: RoadMapType.fallen,
                    name: '收集名单',
                    path: '/collect/list',
                    component: lazy(() => import('./Value/ListCollection')),
                }, {
                    type: RoadMapType.fallen,
                    name: '价值观评估',
                    path: '/score',
                    component: lazy(() => import('./Value/Score')),
                }, {
                    type: RoadMapType.fallen,
                    name: '',
                    path: '/score/survey',
                    component: lazy(() => import('./Value/FakeScore')),
                }],
        }, {
            name: '',
            path: '/kpi-dashboard',
            hasSider: false,
            hasHeader: false,
            type: RoadMapType.fallen,
            subRoads: [{
                    type: RoadMapType.fallen,
                    name: '部门绩效看板',
                    path: '/tl',
                    component: lazy(() => import('./KpiDashboard/ForTl')),
                }, {
                    type: RoadMapType.fallen,
                    name: '部门绩效看板',
                    path: '/leader',
                    component: lazy(() => import('./KpiDashboard/ForLeader')),
                }],
        }, {
            hasHeader: false,
            hasSider: false,
            type: RoadMapType.fallen,
            name: '临时',
            path: '/mix',
            component: () => <Redirect to='/hr/mix/plan'/>,
            subRoads: [{
                    type: RoadMapType.fallen,
                    name: '发展计划',
                    path: '/plan',
                    component: lazy(() => import('./Mix/Plan')),
                }],
        }, {
            name: '临时工具',
            path: '/tmp-tools',
            permissions: [User.ModuleHRSubAuthKey.menu_hr_tool_box],
            component: lazy(() => import('./HrTools')),
        },
        {
            name: '发薪名单',
            path: '/finance-tmp-tools',
            permissions: [User.ModuleHRSubAuthKey.menu_salary_management],
            component: lazy(() => import('./FinanceTools')),
        },
        {
            name: '考勤',
            path: '/attendance-management',
            permissions: [User.Attendance.menu_attendance_management],
            component: lazy(() => import('@src/pages/HR/AttendanceManagement')),
        },],
};
const road = gluer(initialState);
export default road;
