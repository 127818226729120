import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import style from './style.less';
const Success = (props) => {
    const { className, text, type, iconComponent: IconComponent, } = props;
    return (<section className={classNames(style.success, className)}>
      <section className={classNames('success-content', {
            success: type === 'success',
            default: type === 'default',
            warn: type === 'warn',
        })}>
        <IconComponent />
        <section className='tip-desc'>
          {text}
        </section>
      </section>
    </section>);
};
Success.defaultProps = {
    text: '成功',
    type: 'success',
    iconComponent: CheckCircleOutlined,
};
export default Success;
