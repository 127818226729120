import React, { useCallback, } from 'react';
import { Select } from 'antd';
import Spinner from '@src/components/Spinner';
import NoData from '@src/components/NoData';
import Clamp from '@src/components/Clamp';
import { useModel } from 'femo';
import { getAllUsers } from './service';
import model from './model';
import style from './style.less';
const UserSelect = (props) => {
    const { range, ...restProps } = props;
    const getList = useCallback((_state) => getAllUsers(range).then((arr) => arr.map((user) => ({
        key: user.uid,
        value: user.uid,
        label: (<Clamp overlayClassName={style.clamp}>
        <span className='user-name'>{user.name}</span>
        <span className='user-email'>{user.email}</span>
      </Clamp>),
        fillBackLabel: user.name,
    }))), [range]);
    // @ts-ignore
    const [userList, , { loading }] = useModel(model, [getList]);
    let notFoundContent = <NoData />;
    if (loading) {
        notFoundContent = <Spinner type='small'/>;
    }
    return (<Select dropdownClassName={style.select} placeholder='请选择' notFoundContent={notFoundContent} showSearch optionLabelProp='fillBackLabel' optionFilterProp='fillBackLabel' mode='multiple' 
    // @ts-ignore
    options={userList} {...restProps}/>);
};
UserSelect.defaultProps = {
    range: 0, // 默认搜索所有
};
export default UserSelect;
