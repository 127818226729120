import React from 'react';
import { Row, Col } from 'antd';
import StaffTypeField from '@src/pages/HR/formFields/StaffType';
import StaffNameField from '@src/pages/HR/formFields/Name';
import MobileField from '@src/pages/HR/formFields/Mobile';
import IDField from '@src/pages/HR/formFields/IDField';
import BankNumberField from '@src/pages/HR/formFields/BankNumber';
import BankNameField from '@src/pages/HR/formFields/BankName';
import { fieldNames } from '@src/pages/HR/Organization/Struct/modals/StaffCommon/fields/config';
import { getRules } from '@src/pages/HR/Organization/Struct/formUtils';
const AffiliatedForm = (props) => {
    const { stickyMobile, disableStaffTypeField, extraFields, typeFilter, initialStaffType, checkMobile, } = props;
    return (<>
      {extraFields}
      <Row>
        <Col span={12}>
          <StaffTypeField initialValue={initialStaffType} typeFilter={typeFilter} disabled={disableStaffTypeField} name={fieldNames.staffType} rules={getRules('请选择')}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <StaffNameField rules={getRules('请输入')} name={fieldNames.staffName}/>
        </Col>
        <Col span={12}>
          <MobileField needCheck={checkMobile} stickyMobile={stickyMobile} rules={getRules('请输入')} name={fieldNames.staffMobile}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <IDField rules={getRules('请输入')} name={fieldNames.id_number}/>
        </Col>
        <Col span={12}>
          <BankNumberField rules={getRules('请输入')} name={fieldNames.bank_number}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <BankNameField rules={getRules('请输入')} name={fieldNames.bank_name}/>
        </Col>
      </Row>
    </>);
};
AffiliatedForm.defaultProps = {
    checkMobile: true,
};
export default AffiliatedForm;
