import { lawAccount } from '@src/api/actions';
import { getSafe } from '@src/tools/util';
export const getAppliedAccountList = (query) => lawAccount.getAppliedAccountList(query).then((res) => getSafe(res, 'data'));
export const getOwnedAccountList = (query) => lawAccount.getOwnedAccountList(query).then((res) => getSafe(res, 'data'));
export const postNewAccount = (data) => lawAccount.postNewAccount(data);
export const getAccountInfo = (id) => lawAccount.getAccountBasic(id).then((res) => getSafe(res, 'data'));
export const getAccountPublicEditable = (id) => lawAccount.getLawAccountPublicEditable(id).then((res) => getSafe(res, 'data'));
export const delAccount = (id) => lawAccount.delAccount(id);
export const putAccountBasic = (data) => lawAccount.putAccountBasic(data);
export const getPwd = (id, query) => lawAccount.getAccountPwd(id, query).then((res) => getSafe(res, 'data'));
export const getHistoryPwd = (id, query) => lawAccount.getAccountHistoryPwd(id, query).then((res) => getSafe(res, 'data'));
export const getHistoryPwdBatch = (data) => lawAccount.getAccountHistoryPwdBatch(data).then((res) => getSafe(res, 'data'));
export const putAccountPwd = (id, data) => lawAccount.putAccountPwd(id, data);
export const changeAccountStatus = (data) => lawAccount.putAccountStatus(data);
export const transferAccount = (data) => lawAccount.putAccountTransfer(data);
export const getAccountUsageList = (id, query) => lawAccount.getAccountUsageList(id, query).then((res) => getSafe(res, 'data'));
export const getAccountHistoryList = (id, query) => lawAccount.getAccountHistoryList(id, query).then((res) => getSafe(res, 'data'));
export const getAccountApplyList = (query) => lawAccount.getAccountApplyList(query).then((res) => getSafe(res, 'data'));
export const postAccountApply = (id, data) => lawAccount.postAccountApply(id, data);
export const getAccountApplyHistoryList = (query) => lawAccount.getAccountApplyHistoryList(query).then((res) => getSafe(res, 'data'));
export const getAccountAppliedProcessPendingList = (query) => lawAccount.getAccountAppliedProcessPendingList(query).then((res) => getSafe(res, 'data'));
export const getAccountAppliedProcessHistoryList = (query) => lawAccount.getAccountAppliedProcessHistoryList(query).then((res) => getSafe(res, 'data'));
export const putAccountApplyStatus = (id, data) => lawAccount.putAccountApplyStatus(id, data);
export const getAccountTransferList = (query) => lawAccount.getAccountTransferList(query).then((res) => getSafe(res, 'data'));
export const getAccountTransferHistoryList = (query) => lawAccount.getAccountTransferHistoryList(query).then((res) => getSafe(res, 'data'));
export const putAccountTransferStatus = (id, data) => lawAccount.putAccountTransferStatus(id, data);
export const getPlatformList = (query) => lawAccount.getPlatformList(query).then((res) => getSafe(res, 'data'));
export const postNewPlatform = (data) => lawAccount.postNewPlatform(data);
export const putPlatform = (data) => lawAccount.putPlatform(data);
export const delPlatform = (id) => lawAccount.delPlatform(id);
export const getAccountAppliedProcessPendingCount = () => lawAccount.getAccountAppliedProcessPendingCount().then((res) => getSafe(res, 'data', 0));
export const getAccountTransferCount = () => lawAccount.getAccountTransferCount().then((res) => getSafe(res, 'data', 0));
export const postAssignAccount = (id, data) => lawAccount.postAssignAccount(id, data);
// export const
