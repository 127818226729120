import useVisible from '@src/hooks/useVisible';
import { useCallback, useState, } from 'react';
import useResult from '@src/hooks/useResult';
const useOnBoardingAndEditModal = (v = false) => {
    const tmpResult = useVisible(v);
    const [data, updateData] = useState();
    const [record, updateRecord] = useState();
    const show = useCallback((d, r) => {
        updateData(d);
        updateRecord(r);
        tmpResult.show();
    }, [tmpResult]);
    const hide = useCallback(() => {
        tmpResult.hide();
        updateData(null);
        updateRecord(null);
    }, [tmpResult.hide]);
    return useResult({
        ...tmpResult,
        hide,
        show,
        data,
        record,
        updateRecord,
    });
};
export default useOnBoardingAndEditModal;
