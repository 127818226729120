export var RoadMapType;
(function (RoadMapType) {
    RoadMapType["living"] = "living";
    RoadMapType["fallen"] = "fallen";
})(RoadMapType || (RoadMapType = {}));
export var Loading;
(function (Loading) {
    Loading[Loading["success"] = 1] = "success";
    Loading[Loading["failed"] = -1] = "failed";
    Loading[Loading["pending"] = 0] = "pending";
})(Loading || (Loading = {}));
export var OwnerTypes;
(function (OwnerTypes) {
    OwnerTypes["employee"] = "employee";
    OwnerTypes["leader"] = "leader";
    OwnerTypes["hr"] = "hr";
    OwnerTypes["cooperator"] = "cooperator";
    OwnerTypes["manager"] = "manager";
})(OwnerTypes || (OwnerTypes = {}));
export var FileType;
(function (FileType) {
    FileType["FINAL"] = "FINAL";
    FileType["AUTOGEN"] = "AUTOGEN";
    FileType["STAFF_REPORT"] = "STAFF_REPORT";
    FileType["RETHINK"] = "RETHINK";
})(FileType || (FileType = {}));
