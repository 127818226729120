import React from 'react';
import { Form, Input } from 'antd';
const BankNameField = (props) => {
    const { disabled, hidden, placeholder, ...restProps } = props;
    return (<Form.Item {...restProps}>
      <Input disabled={disabled} autoComplete='off' placeholder={placeholder}/>
    </Form.Item>);
};
BankNameField.defaultProps = {
    label: '开户行',
    placeholder: '开户行',
};
export default BankNameField;
