import React from 'react';
import { Form, Input } from 'antd';
import { isEmpty, regex } from '@src/tools/util';
const rules = [{
        validator: (_rule, value) => {
            if (!isEmpty(value) && !regex.email(value)) {
                return Promise.reject('格式不正确');
            }
            return Promise.resolve();
        },
    }];
const PersonalEmailField = (props) => (<Form.Item validateFirst {...props} rules={props.rules ? [...props.rules, ...rules] : rules}>
      <Input autoComplete='off' placeholder='请输入'/>
    </Form.Item>);
PersonalEmailField.defaultProps = {
    label: '个人邮箱',
};
export default PersonalEmailField;
