import { getSafe } from '@src/tools/util';
import { Channel } from '@src/pages/components/filter/ChannelCheckbox';
import Placeholder from '@src/components/Placeholder';
import React from 'react';
import { Space } from 'antd';
const modifyChannelCell = (v, record, result) => {
    const channel = getSafe(record, 'request_channel_type');
    let content = null;
    if (channel === Channel.other) {
        content = '其他';
    }
    else {
        content = v || <Placeholder text='--'/>;
    }
    return (<Space>
      {content}
      <a onClick={(e) => {
            e.stopPropagation();
            result.show({
                offerId: getSafe(record, 'candidate_id'),
                firstChannel: getSafe(record, 'request_channel_first'),
                secondChannel: getSafe(record, 'request_channel_second'),
                thirdChannel: getSafe(record, 'request_channel_third'),
                thirdChannelRefererId: getSafe(record, 'referer_id'),
                channel: getSafe(record, 'request_channel_type'),
            });
        }}>修改</a>
    </Space>);
};
export default modifyChannelCell;
