import useVisible from '@src/hooks/useVisible';
import { useCallback, useRef } from 'react';
import useResult from '@src/hooks/useResult';
const useModifyChannel = () => {
    const tmpResult = useVisible(false);
    const dataRef = useRef(null);
    const show = useCallback((d) => {
        dataRef.current = d;
        tmpResult.show();
    }, [tmpResult.show]);
    const hide = useCallback(() => {
        dataRef.current = null;
        tmpResult.hide();
    }, [tmpResult.hide]);
    return useResult({
        ...tmpResult,
        show,
        hide,
        data: dataRef.current,
    });
};
export default useModifyChannel;
