import React, { useState, } from 'react';
import { TreeSelect } from 'antd';
import { getSafe } from '@src/tools/util';
import Clamp from '@src/components/Clamp';
import { StaffType } from '@src/api/interface';
import style from './style.less';
const exactTypeFilter = (arr) => {
    const fn = (list) => {
        const tmpList = list.filter((i) => {
            const type = getSafe(i, 'id');
            return type !== StaffType.all;
        });
        tmpList.forEach((t) => {
            if (t.children && t.children.length) {
                t.children = fn(t.children);
            }
        });
        return tmpList;
    };
    return fn(arr);
};
const genTreeData = (tree, canSelectParent) => tree.map((t) => {
    const name = getSafe(t, 'name');
    const id = getSafe(t, 'id');
    const children = getSafe(t, 'children') || [];
    const isLeaf = !getSafe(children, 'length');
    const childrenArr = genTreeData(children, canSelectParent);
    return {
        label: name,
        title: <Clamp>{name}</Clamp>,
        key: id,
        isLeaf,
        value: id,
        children: childrenArr,
        disabled: !canSelectParent && !isLeaf,
    };
});
// 创建时：有子类的只能选子类
// 查询时：父类子类都可选
export const getStaffTypes = () => [{
        name: '全部员工',
        id: StaffType.all,
    }, {
        name: '实习生类型',
        id: StaffType.practiceSummary,
        children: [{
                name: '实习生',
                id: StaffType.practice,
            }, {
                name: '实习生（外包）',
                id: StaffType.practiceOutsourcing,
            }, {
                name: '暑期实习生',
                id: StaffType.practiceSummer,
            }],
    }, {
        name: '产研员工',
        id: StaffType.regularSummary,
        children: [{
                name: '正式员工（产研）',
                id: StaffType.regular,
            }, {
                name: '正式员工（产研实习）',
                id: StaffType.regularPractice,
            }],
    }, {
        name: '外包员工',
        id: StaffType.dispatchOutSouring,
    }, {
        name: '销服员工',
        id: StaffType.largeScaleSummary,
        children: [{
                id: StaffType.largeScaleRegular,
                name: '正式员工（销服）',
            }, {
                id: StaffType.largeScaleRegularPractice,
                name: '正式员工（销服实习）',
            }, {
                id: StaffType.largeScaleOutsourcing,
                name: '外包（销服）',
            }],
    }, {
        name: '劳务派遣',
        id: StaffType.outsourcing,
    }, {
        name: '云账户类型',
        id: StaffType.cloud,
    }, {
        name: '其他',
        id: StaffType.otherSummary,
        children: [{
                name: '公共账号',
                id: StaffType.public,
            }, {
                name: '挂靠员工',
                id: StaffType.affiliated,
            },
            // {
            //   name: '特殊员工',
            //   id: StaffType.specialStaff,
            // },
            {
                name: '其他',
                id: StaffType.other,
            }],
    }];
const StaffTypeSelect = (props) => {
    const { exact, typeFilter, canSelectParent, defaultValue, ...restProps } = props;
    const [staffType] = useState(() => getStaffTypes());
    const [types] = useState(() => {
        let result = [...staffType];
        if (exact) {
            result = exactTypeFilter(result);
        }
        if (typeFilter) {
            result = typeFilter(result);
        }
        return result;
    });
    const [treeData] = useState(() => genTreeData(types, canSelectParent));
    return (<TreeSelect showSearch placeholder='请选择' treeDefaultExpandAll defaultValue={defaultValue} treeNodeFilterProp='label' treeNodeLabelProp='label' dropdownClassName={style.staffTypeSelect} treeData={treeData} {...restProps}/>);
};
StaffTypeSelect.defaultProps = {
    exact: false,
    defaultValue: null,
    canSelectParent: true,
};
export default StaffTypeSelect;
