import React, { useCallback } from 'react';
import { useDerivedStateToModel, useIndividualModel } from 'femo';
import { Select } from 'antd';
import { getSafe } from '@src/tools/util';
export var Condition;
(function (Condition) {
    Condition["equal"] = "equal";
    Condition["notEqual"] = "none_equal";
    Condition["notEarlier"] = "later_than_equal";
    Condition["notLater"] = "before_than_equal";
    Condition["include"] = "include";
    Condition["notSmaller"] = "greater_than_equal";
    Condition["notBigger"] = "less_than_equal";
    Condition["null"] = "empty";
    Condition["notEmpty"] = "none_empty";
})(Condition || (Condition = {}));
export const conditions = [{
        name: '等于',
        id: Condition.equal,
    }];
export const conditions_a = [{
        name: '等于',
        id: Condition.equal,
    }, {
        name: '不等于',
        id: Condition.notEqual,
    }];
export const conditions_b = [{
        name: '等于',
        id: Condition.equal,
    }, {
        name: '晚于等于',
        id: Condition.notEarlier,
    }, {
        name: '早于等于',
        id: Condition.notLater,
    }];
export const conditions_c = [{
        name: '包含',
        id: Condition.include,
    }];
export const conditions_d = [{
        name: '包含',
        id: Condition.include,
    }, {
        name: '为空',
        id: Condition.null,
    }, {
        name: '不为空',
        id: Condition.notEmpty,
    }];
const ConditionSelect = (props) => {
    const { value: propsValue, onChange, filter, conditions, ...restProps } = props;
    const [, valueModel] = useIndividualModel(propsValue);
    const selectChange = useCallback((v) => {
        if (onChange) {
            onChange(v);
        }
        else {
            valueModel(v);
        }
    }, [onChange]);
    const [value] = useDerivedStateToModel(props, valueModel, (nextProps, _prevProps, state) => {
        if ('value' in nextProps) {
            const { value, conditions } = nextProps;
            if (!(value instanceof Array) || !value.length || value.every((v) => (!(conditions || []).find((cond) => cond.id === v)))) {
                const tmp = getSafe(conditions, '[0].id');
                const r = tmp ? [tmp] : [];
                selectChange(r);
                return r;
            }
            return value;
        }
        return state;
    });
    return (<Select {...restProps} value={value} onChange={selectChange}>
      {conditions.map((c) => (<Select.Option key={c.id} value={c.id} label={c.name}>
              {c.name}
            </Select.Option>))}
    </Select>);
};
ConditionSelect.defaultProps = {
    conditions,
};
export default ConditionSelect;
