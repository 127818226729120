import React, { useEffect, useState, } from 'react';
import { Collapse, Skeleton } from 'antd';
import { DoubleLeftOutlined } from '@ant-design/icons';
import style from './style.less';
const FilterSearch = (props) => {
    const { title, rightTopWidgets } = props;
    const [header] = useState(() => '过滤');
    const [loading, updateLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            updateLoading(false);
        }, 200);
        return () => clearTimeout(timer);
    }, []);
    return (<Skeleton loading={loading}>
      <section style={{ height: '38px' }}>{title}</section>
      <Collapse defaultActiveKey='1' bordered={false} className={style.search} expandIconPosition='right' expandIcon={({ isActive }) => <DoubleLeftOutlined rotate={isActive ? 270 : 90}/>}>
        <Collapse.Panel key='1' header={header}>
          <section className='search-wrapper'>
            <section className='title'>
              <span className='title-text'>搜索符合下列条件的结果</span>
              <span className='placeholder'/>
              <span>
                {rightTopWidgets}
              </span>
            </section>
            <section className='main'>
              {props.children}
            </section>
          </section>
        </Collapse.Panel>
      </Collapse>
    </Skeleton>);
};
export default FilterSearch;
