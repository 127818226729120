import { applyDetailUrl } from '@src/pages/HR/Recruit/urls';
import { isMobile } from '@src/tools/util';
const ignoreUrls = (path) => {
    const waitingUrl = (path === '/waiting' || path.indexOf('/waiting/') === 0);
    // const demandApplyDetailUrl = testPath(applyDetailUrl());
    // const demandApplyDetailMobileUrl = testPath(applyDetailMobileUrl());
    return waitingUrl;
};
export const shouldJump = () => {
    const demandApplyDetailUrl = applyDetailUrl(null) === window?.location?.pathname || `${applyDetailUrl(null)}/` === window?.location?.pathname;
    // 如果是 pc 的申请详情，则需要跳转
    if (demandApplyDetailUrl && isMobile()) {
        window.location.href = `/page/mobile${window.location.pathname}${window.location.search}`;
    }
    return demandApplyDetailUrl && isMobile();
};
export default ignoreUrls;
