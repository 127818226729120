import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
const EntryTimeField = (props) => {
    const { getPopupContainer, ...restProps } = props;
    return (<Form.Item initialValue={moment()} {...restProps}>
      <DatePicker getPopupContainer={getPopupContainer} showToday/>
    </Form.Item>);
};
EntryTimeField.defaultProps = {
    label: '入职时间',
};
export default EntryTimeField;
