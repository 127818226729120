import React, { useCallback, } from 'react';
import { useIndividualModel, useDerivedStateToModel, useDerivedState, } from 'femo';
import { Checkbox, Row, Select } from 'antd';
import commonStyle from '@src/pages/components/filter/components/CategorySelectCheckbox/style.less';
import NoData from '@src/components/NoData';
import style from './style.less';
const SelectCheckbox = (props) => {
    const { value: propsValue, onChange, extraRef, allFlag, allText, allIds, data: propData, hideAllCheckbox: propHideAllCheckbox, showSearch, ...restProps } = props;
    const [hideAllCheckbox, hideAllCheckboxModel] = useDerivedState(() => propHideAllCheckbox, [propHideAllCheckbox]);
    const [data, dataModel] = useDerivedState(() => propData, [propData]);
    const [, valueModel] = useIndividualModel(propsValue || []);
    const [value] = useDerivedStateToModel(props, valueModel, (nextSource, _prevSource, state) => {
        if ('value' in nextSource) {
            let v = nextSource.value || [];
            if (v.includes(allFlag)) {
                v = allIds;
                if (v.length === 0) {
                    v = [allFlag];
                }
                else if (onChange) {
                    // 如果不为空数组，则需要通知给外部真实的数据
                    onChange(v);
                }
            }
            return v;
        }
        return state;
    });
    const isAllChecked = useCallback((selectedIds) => {
        const tmpIds = selectedIds || [];
        return tmpIds.includes(allFlag) || (propData.length === tmpIds.length && propData.length !== 0 && propData.every((t) => selectedIds.includes(t.id)));
    }, [propData, allFlag]);
    const [, allCheckedModel] = useIndividualModel(() => isAllChecked(value));
    useDerivedStateToModel(value, allCheckedModel, (nextSource, prevSource, state) => {
        let result = state;
        if (nextSource !== prevSource) {
            result = isAllChecked(nextSource);
        }
        if (extraRef) {
            extraRef.current.all = result;
        }
        return result;
    });
    const [allChecked] = useDerivedStateToModel(propData, allCheckedModel, (nt, pt, state) => {
        let result = state;
        if (nt !== pt) {
            result = isAllChecked(value);
        }
        if (extraRef) {
            extraRef.current.all = result;
        }
        return result;
    });
    const genSelectedValue = useCallback((v) => {
        if (allChecked) {
            return [{ label: allText, key: allFlag, value: allFlag }];
        }
        const arr = [];
        for (let i = 0; i <= v.length; i += 1) {
            const target = propData.find((t) => t.id === v[i]);
            if (target) {
                arr.push({
                    value: target.id,
                    key: target.id,
                    label: target.name,
                });
            }
        }
        return arr;
    }, [propData, allChecked, allText, allFlag]);
    const [, selectedValueModel] = useIndividualModel(() => genSelectedValue(value));
    useDerivedStateToModel(value, selectedValueModel, (nextSource, prevSource, state) => {
        if (nextSource !== prevSource) {
            return genSelectedValue(nextSource);
        }
        return state;
    });
    const [selectedValue] = useDerivedStateToModel(propData, selectedValueModel, (ns, ps, state) => {
        if (ns !== ps) {
            return genSelectedValue(value);
        }
        return state;
    });
    const isIndeterminate = useCallback((ids) => !isAllChecked(ids) && ids.length !== 0, [isAllChecked]);
    const [, indeterminateModel] = useIndividualModel(() => isIndeterminate(value));
    useDerivedStateToModel(value, indeterminateModel, (nextSource, prevSource, state) => {
        if (nextSource !== prevSource) {
            return isIndeterminate(nextSource);
        }
        return state;
    });
    const [indeterminate] = useDerivedStateToModel(propData, indeterminateModel, (nt, pt, state) => {
        if (nt !== pt) {
            return isIndeterminate(value);
        }
        return state;
    });
    const checkboxGroupChange = useCallback((checkedValues) => {
        if (onChange) {
            onChange([...checkedValues]);
        }
        else {
            valueModel([...checkedValues]);
        }
    }, [onChange]);
    const onCheckboxChange = useCallback((evt) => {
        const { target } = evt;
        const { checked, value: v } = target;
        let result = [...value];
        if (checked) {
            result = Array.from(new Set([...result, v]));
        }
        else {
            const index = value.indexOf(v);
            if (index >= 0) {
                result.splice(value.indexOf(v), 1);
            }
        }
        if (onChange) {
            onChange(result);
        }
        else {
            valueModel(result);
        }
    }, [value, onChange]);
    const onAllCheckboxChange = useCallback((evt) => {
        const { checked } = evt.target;
        let ids = [];
        if (checked) {
            ids = propData.map((t) => t.id);
        }
        checkboxGroupChange(ids);
    }, [propData, checkboxGroupChange]);
    const renderPanel = useCallback((ids) => {
        const eleArr = data.map((t) => (<section className='team-item' key={t.id}>
        <Row>
          <Checkbox onChange={onCheckboxChange} value={t.id}>{t.name}</Checkbox>
        </Row>
      </section>));
        let allEle = null;
        if (eleArr.length !== 0 && !hideAllCheckbox) {
            allEle = (<section className='all'>
          <Checkbox checked={allChecked} onChange={onAllCheckboxChange} indeterminate={indeterminate}>全选</Checkbox>
        </section>);
        }
        return (<>
        {allEle}
        <Checkbox.Group value={ids}>
          {eleArr.length ? eleArr : <NoData description='未找到'/>}
        </Checkbox.Group>
      </>);
    }, [data, allChecked, indeterminate, onAllCheckboxChange, checkboxGroupChange, hideAllCheckbox, onCheckboxChange]);
    const selectChange = useCallback((value) => {
        const result = [];
        const tmpV = value || [];
        for (let i = 0; i < tmpV.length; i += 1) {
            const target = propData.find((t) => tmpV[i].key === t.id);
            if (target) {
                result.push(target.id);
            }
        }
        if (showSearch) {
            dataModel.silent(propData);
            hideAllCheckboxModel.silent(propHideAllCheckbox);
        }
        checkboxGroupChange(result);
    }, [checkboxGroupChange, propData, propHideAllCheckbox, showSearch]);
    const onSearch = useCallback((inputStr) => {
        if (!showSearch)
            return;
        if (!inputStr) {
            hideAllCheckboxModel.silent(propHideAllCheckbox);
            dataModel(propData);
        }
        else {
            hideAllCheckboxModel.silent(true);
            const reg = new RegExp(inputStr);
            dataModel(() => (propData || []).filter((s) => reg.test(s.name)));
        }
    }, [propData, propHideAllCheckbox, showSearch]);
    const onFocus = useCallback(() => {
        if (!showSearch)
            return;
        hideAllCheckboxModel.silent(propHideAllCheckbox);
        dataModel(propData);
    }, [propData, propHideAllCheckbox]);
    return (<Select {...restProps} showSearch={showSearch} labelInValue dropdownMatchSelectWidth={false} mode='multiple' value={selectedValue} onSearch={onSearch} onChange={selectChange} 
    // open={visible}
    onFocus={onFocus} dropdownRender={() => (<section className={style.checkbox}>
          {renderPanel(value)}
        </section>)} className={commonStyle.select} dropdownAlign={{
            overflow: {
                adjustX: false,
                adjustY: false,
            },
        }}>

    </Select>);
};
SelectCheckbox.defaultProps = {
    placeholder: '请选择',
    allFlag: Date.now(),
};
export default SelectCheckbox;
