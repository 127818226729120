import administrationRoad from '@src/pages/Administration/road';
import authRoad from '@src/pages/Auth/road';
import dengBaoRoad from '@src/pages/DengBao/road';
import hrRoad from '@src/pages/HR/road';
import { RoadMapType } from '@src/pages/interface';
import lawAccountRoad from '@src/pages/Law/AccountManage/admin/road';
import complianceRoad from '@src/pages/Law/Compliance/road';
import pushRoad from '@src/pages/Push/road';
import waitingRoad from '@src/pages/Waiting/road';
import lawRoad from '@src/pages/Law/road';
import Welcome from '@src/pages/Welcome';
// import hrboard from '@src/pages/HRboard/road';
import React, { useEffect, useState, } from 'react';
import { Router } from 'react-router-dom';
import sentry from '@src/tools/sentry';
import { getSafe, isMobile, isPathStartWith } from '@src/tools/util';
import { InfoCircleOutlined, StrikethroughOutlined } from '@ant-design/icons';
import Success from '@src/components/Success';
import userModel from '@src/model/user';
import { common } from '@src/api/actions';
import Spinner from '@src/components/Spinner';
import NotFound from '@src/components/NotFound';
import { ApiCode, StaffType, User } from '@src/api/interface';
import ignoreUrls, { shouldJump } from '@src/Root/ignoreUrls';
import permissionModel from '@src/model/permission';
import { findUserPathRoadMap } from '@src/tools/auth';
import pagesRoadMap, { flatRoadMap } from '@src/pages/roadMap';
import StagingToken from '@src/pages/external/StagingToken';
import ChatSidePage from '@src/pages/external/ChatSidePage';
import roadMapValueForDepHr from '@src/pages/roadMapValueForDepHr';
import roadMapValueForDepLaw from '@src/pages/roadMapValueForDepLaw';
import roadMapValueForDepTeam from '@src/pages/roadMapValueForDepTeam';
import roadMapValueForDepLawWork from '@src/pages/roadMapValueForDepLawWork';
import { isProduction } from '@src/tools/env/index';
import history from './history';
var ModuleAccountManageSubAuthKey = User.ModuleAccountManageSubAuthKey;
class ErrorCatch extends React.Component {
    componentDidCatch(err, msg) {
        sentry.reportErrorBoundary(err, msg, this);
    }
    render() {
        return this.props.children;
    }
}
var ReadyState;
(function (ReadyState) {
    ReadyState[ReadyState["success"] = 0] = "success";
    ReadyState[ReadyState["fail"] = 1] = "fail";
    ReadyState[ReadyState["pending"] = 2] = "pending";
})(ReadyState || (ReadyState = {}));
const extractPermissions = (permissions) => {
    let result = {};
    try {
        // @ts-ignore
        Object.keys(permissions).forEach((key) => {
            const cur = permissions[key];
            result[key] = getSafe(cur, 'auth') || false;
            const sub_permits = getSafe(cur, 'sub_permits');
            result = {
                ...result,
                ...sub_permits,
            };
        });
    }
    catch (e) {
        sentry.reportError(e);
    }
    return result;
};
const Root = (props) => {
    const { children } = props;
    const [ready, updateReady] = useState(ReadyState.pending);
    const path = getSafe(window, 'location.pathname') || '';
    const jumpFlag = shouldJump();
    const host = getSafe(window, 'location.hostname') || getSafe(window, 'location.host') || '';
    const isTokenUrl = path === '/update-token' && !isProduction;
    const isChatSidePngUrl = path === '/chat-side-page';
    const noRequest = jumpFlag || isTokenUrl || isChatSidePngUrl;
    useEffect(() => {
        if (noRequest)
            return;
        userModel.info(() => common.getUserInfo().then((res) => {
            // 如果是员工入口的绩效管理，则展示另外一套路由
            const tmpPath = path || '';
            // 结尾不要传 斜杠
            if (isPathStartWith('/dep-hr', tmpPath)) {
                pagesRoadMap.relyOn([roadMapValueForDepHr.models.depHrRoad], ([hrValue], state) => ({
                    ...state,
                    hr: hrValue,
                }));
                pagesRoadMap(roadMapValueForDepHr.result);
            }
            else if (isPathStartWith('/dep-law', tmpPath)) {
                pagesRoadMap.relyOn([roadMapValueForDepLaw.models.depLawRoad], ([lawValue], state) => ({
                    law: lawValue,
                }));
                pagesRoadMap(roadMapValueForDepLaw.result);
            }
            else if (isPathStartWith('/dep-team', tmpPath)) {
                pagesRoadMap.relyOn([roadMapValueForDepTeam.models.depTeamRoad], ([teamValue], state) => ({
                    ...state,
                    team: teamValue,
                }));
                pagesRoadMap(roadMapValueForDepTeam.result);
            }
            else if (isPathStartWith('/dep-legal', tmpPath)) {
                // roadMapValueForDepLawWork
                pagesRoadMap.relyOn([roadMapValueForDepLawWork.models.depLegal], ([legalValue], state) => ({
                    ...state,
                    legal: legalValue,
                }));
                pagesRoadMap(roadMapValueForDepLawWork.result);
                permissionModel.permission(roadMapValueForDepLawWork.result);
                const keys = Object.keys(pagesRoadMap());
                // 过滤根据权限遍历路由
                pagesRoadMap(findUserPathRoadMap(permissionModel.permission(), flatRoadMap()).reduce((pre, cur, index) => {
                    pre[keys[index]] = cur;
                    return pre;
                }, {}));
            }
            else {
                pagesRoadMap({
                    hr: hrRoad(),
                    administration: administrationRoad(),
                    waiting: waitingRoad(),
                    auth: authRoad(),
                    lawAccount: lawAccountRoad(),
                    complianceRegular: complianceRoad.roadRegular(),
                    complianceSale: complianceRoad.roadSale(),
                    dengbao: dengBaoRoad(),
                    push: pushRoad(),
                    law: lawRoad(),
                    // hrBoard: hrboard(),
                    welcome: {
                        name: '',
                        path: '/',
                        type: RoadMapType.fallen,
                        component: Welcome,
                    },
                    finance: {
                        name: '财务系统',
                        icon: StrikethroughOutlined,
                        path: '/finance',
                        externUrl: 'https://mis.baicizhan-people.com/',
                        externProps: {
                            target: '_blank',
                            rel: 'noreferrer nofollow noopener',
                        },
                    },
                });
                // hrboard
                pagesRoadMap.relyOn([hrRoad, waitingRoad, authRoad, administrationRoad, lawAccountRoad, dengBaoRoad, pushRoad], (result, state) => ({
                    ...state,
                    hr: result[0],
                    waiting: result[1],
                    auth: result[2],
                    administration: result[3],
                    lawAccount: result[4],
                    dengbao: result[5],
                    push: result[6],
                    law: result[7],
                    // hrboard: result[8],
                }));
                const result = extractPermissions(getSafe(res, 'data.permissions'));
                const staffType = getSafe(res, 'data.user_info.customer_type');
                // 实习生无法看见 账号管理中的 账号审核、账号转移
                if (staffType === StaffType.practice || staffType === StaffType.practiceOutsourcing || staffType === StaffType.practiceSummer) {
                    result[ModuleAccountManageSubAuthKey.menu_account_approval_list] = false;
                    result[ModuleAccountManageSubAuthKey.menu_account_transfer_list] = false;
                }
                else {
                    result[ModuleAccountManageSubAuthKey.menu_account_approval_list] = true;
                    result[ModuleAccountManageSubAuthKey.menu_account_transfer_list] = true;
                }
                // 更新权限数据
                permissionModel.permission(result);
                const keys = Object.keys(pagesRoadMap());
                // 过滤根据权限遍历路由
                pagesRoadMap(findUserPathRoadMap(permissionModel.permission(), flatRoadMap()).reduce((pre, cur, index) => {
                    pre[keys[index]] = cur;
                    return pre;
                }, {}));
            }
            updateReady(ReadyState.success);
            return getSafe(res, 'data');
        }).catch((err) => {
            const code = getSafe(err, 'code');
            if (code !== ApiCode.needLogin) {
                updateReady(ReadyState.fail);
            }
        }));
    }, []);
    // 网页跳转
    if (!(window?.navigator?.userAgent?.includes('wxwork'))) {
        (function () {
            const { href, pathname, host } = window.location;
            if (pathname === '/hr/offer') {
                switch (host) {
                    case 'hr.baicizhan-people.com':
                        window.open(`https://cyamis.baicizhan.com${pathname}`, '_self');
                        return;
                    case 'cyamis.baicizhan.com':
                        return;
                    default:
                        break;
                }
            }
            const s_2_t_list = [
                [/^(http|https):\/\/cyamis.baicizhan.com/, 'https://hr.baicizhan-people.com'],
                [/^(http|https):\/\/cyamis-staging.baicizhan.com/, 'https://staging1.baicizhan-people.com'],
                [/^(http|https):\/\/cyamis-staging2.baicizhan.com/, 'https://staging2.baicizhan-people.com'],
            ];
            for (let i = 0; i < 3; i += 1) {
                const current = s_2_t_list[i];
                const s = current[0];
                const t = current[1];
                if (s.test(href)) {
                    window.open(href.replace(s, t), '_self');
                    return;
                }
            }
        }());
    }
    // 判断是否是需要跳转的页面，在此直接跳转
    if (jumpFlag) {
        return null;
    }
    // 判断是否是移动端页面
    if (isMobile() && !ignoreUrls(path)) {
        return <Success className='mobile' iconComponent={InfoCircleOutlined} type='warn' text='请在pc端打开该页面'/>;
    }
    // 如果是更新token页面，则渲染
    // 只在pc端展示，所以放在isMobile之后
    if (isTokenUrl) {
        return <StagingToken />;
    }
    // 只在pc端展示，所以放在isMobile之后
    if (isChatSidePngUrl) {
        return <ChatSidePage />;
    }
    if (ready === ReadyState.pending) {
        return <Spinner />;
    }
    if (ready === ReadyState.fail) {
        return <NotFound text='用户信息获取失败，请稍后重试...'/>;
    }
    return (<ErrorCatch>
      <Router history={history}>
        {children}
      </Router>
    </ErrorCatch>);
};
export default Root;
