import React, { useCallback, useEffect, useRef, } from 'react';
import { getSafe, getYMD, isEmpty } from '@src/tools/util';
import { Offer } from '@src/pages/HR/Offer/interface';
import { defaultOfferUrlQuery, isSalaryMasked, offerExportUrl, offerUrl, } from '@src/pages/HR/Offer/url';
import { addOffer, fetchOfferList } from '@src/pages/HR/Offer/Regular/service';
import { Button, Skeleton, Space, Table, } from 'antd';
import useNewStaffActions from '@src/pages/HR/Organization/Struct/modals/StaffCommon/useNewStaffActions';
import NewOfferModal from '@src/pages/HR/Organization/Struct/modals/StaffCommon';
import FilterSearch from '@src/pages/components/FilterSearch';
import ListFilterForm from '@src/pages/HR/Offer/filter/ListFilterForm';
import { StaffType, staffTypes, workTypeNames, } from '@src/api/interface';
import fieldNames from '@src/pages/HR/Operation/BoardingManagement/fieldNames';
import { offer, recruit } from '@src/api/actions';
import SetBoardingTime from '@src/pages/components/SetTime';
import SetOfferSalary from '@src/pages/components/SetOfferSalary';
import useSetOfferSalary from '@src/pages/components/SetOfferSalary/useSetOfferSalary';
import SetOfferSalaryModal from '@src/pages/components/SetOfferSalary/SetOfferSalaryModal';
import useTableYScroll from '@src/hooks/useTableYScroll';
import Spinner from '@src/components/Spinner';
import useOnBoardingAndEditModal from '@src/pages/HR/Operation/BoardingManagement/OnBoardingList/List/OnBoardingAndEditModal/useOnBoardingAndEditModal';
import OnBoardingAndEditModal from '@src/pages/HR/Operation/BoardingManagement/OnBoardingList/List/OnBoardingAndEditModal';
import DeleteModal from '@src/pages/HR/Offer/Regular/OfferList/DeleteModal';
import useDeleteModal from '@src/pages/HR/Offer/Regular/OfferList/DeleteModal/useDeleteModal';
import useQueryObjStrict from '@src/hooks/useQueryObjStrict';
import { Condition } from '@src/pages/components/ConditionSelect';
import { useBatchDerivedModel, useDerivedState, useModel } from 'femo';
import urlQueryToApiQuery, { orderByToTableSorter, switchOrderBy } from '@src/pages/HR/Offer/Regular/urlQueryToApiQuery';
import { offerList } from '@src/pages/HR/Offer/Regular/model';
import NewSvg from '@src/assets/svg/new.svg';
import LeaderWarning from '@src/pages/HR/Operation/BoardingManagement/OnBoardingList/List/LeaderWarning';
import SalaryRecordTable from '@src/pages/HR/Offer/Regular/SalaryRecordTable';
import Placeholder from '@src/components/Placeholder';
import { OfferStatus, offerStatusNames } from '@src/pages/components/filter/StaffStatusCheckbox';
import jobListModel from '@src/pages/components/InnerJobSelect/jobListModel';
import { securityCrash } from '@src/components/HOC';
import ListTotal from '@src/pages/components/ListTotal';
import ExportList from '@src/pages/components/ExportList';
import WorkTypeColumnTitle from '@src/pages/HR/Offer/WorkTypeColumnTitle';
import SetSalaryHidden from '@src/pages/HR/Offer/Regular/OfferList/SetSalaryHidden';
import ModifyChannel from '@src/pages/HR/common/ModifyChannel';
import useModifyChannel from '@src/pages/HR/common/ModifyChannel/useModifyChannel';
import modifyChannelCell from '@src/pages/HR/common/ModifyChannel/modifyChannelCell';
import columnDataIndex from '../columnDataIndex';
import style from './style.less';
export const defaultOfferRegularListQuery = {
    ...defaultOfferUrlQuery,
    order_by: 'asc',
    sub_key: Offer.SubKeys.regular_offer_list,
    p_index: 1,
    p_size: 20,
    s_type: [],
    s_t_cond: [Condition.equal],
    c_id: [],
    c_id_cond: [Condition.equal],
    t_id: [],
    t_id_cond: [Condition.equal],
    s_status: [],
    s_s_cond: [Condition.equal],
    channel: [],
    c_cond: [Condition.equal],
    c_by: [],
    c_by_cond: [Condition.equal],
    t_crt: [],
    t_c_cond: [Condition.equal],
    b_t: [],
    b_t_cond: [Condition.equal],
    name: [],
    name_cond: [Condition.include],
};
const initSearch = `?tab_key=${Offer.TabKeys.regular}&sub_key=${Offer.SubKeys.regular_offer_list}`;
const OfferList = (props) => {
    const { history, location, control, } = props;
    const flagRef = useRef(false);
    useEffect(() => {
        flagRef.current = true;
    }, []);
    const search = getSafe(location, 'search');
    const [query] = useQueryObjStrict(search, {
        ...defaultOfferRegularListQuery,
    });
    const [staffTypeFilter] = useBatchDerivedModel(() => ({
        value: query.s_type,
        condition: query.s_t_cond,
    }), {
        source: query.s_type,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.s_t_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [teamFilter] = useBatchDerivedModel(() => ({
        value: query.t_id,
        condition: query.t_id_cond,
    }), {
        source: query.t_id,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.t_id_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [companyFilter] = useBatchDerivedModel(() => ({
        value: query.c_id,
        condition: query.c_id_cond,
    }), {
        source: query.c_id,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: query.c_id,
                };
            }
            return state;
        },
    }, {
        source: query.c_id_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: query.c_id_cond,
                };
            }
            return state;
        },
    });
    const [staffStatusFilter] = useBatchDerivedModel(() => ({
        value: query.s_status,
        condition: query.s_s_cond,
    }), {
        source: query.s_status,
        callback: (ns, ps, state) => {
            if (!flagRef.current && !getSafe(ns, 'length')) {
                query.s_status = [`${OfferStatus.todo}`];
                return {
                    ...state,
                    value: query.s_status,
                };
            }
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.s_s_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [channelFilter] = useBatchDerivedModel(() => ({
        value: query.channel,
        condition: query.c_cond,
    }), {
        source: query.channel,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.c_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [createdByFilter] = useDerivedState(() => ({
        value: query.c_by,
        condition: query.c_by_cond,
    }), [query.c_by, query.c_by_cond]);
    const [createdTimeFilter] = useBatchDerivedModel(() => ({
        value: query.t_crt,
        condition: query.t_c_cond,
    }), {
        source: query.t_crt,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.t_c_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [boardingTimeFilter] = useBatchDerivedModel(() => ({
        value: query.b_t,
        condition: query.b_t_cond,
    }), {
        source: query.b_t,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.b_t_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const [nameFilter] = useBatchDerivedModel(() => ({
        value: query.name,
        condition: query.name_cond,
    }), {
        source: query.name,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    value: ns,
                };
            }
            return state;
        },
    }, {
        source: query.name_cond,
        callback: (ns, ps, state) => {
            if (ns !== ps) {
                return {
                    ...state,
                    condition: ns,
                };
            }
            return state;
        },
    });
    const push = useCallback((q) => {
        history.push(offerUrl({
            ...query,
            ...q,
        }));
    }, [query, history]);
    const replace = useCallback((q) => {
        history.replace(offerUrl({
            ...query,
            ...q,
        }));
    }, [query, history]);
    const afterSubmit = useCallback(() => {
        replace({
            t: Date.now(),
        });
    }, [replace]);
    const request = useCallback((state) => {
        const tab = getSafe(query, 'tab_key');
        const subTab = getSafe(query, 'sub_key');
        if (tab === Offer.TabKeys.regular && subTab === Offer.SubKeys.regular_offer_list) {
            return fetchOfferList(urlQueryToApiQuery(query, Offer.ListType.waitingBoard, Offer.OfferManagerSource.regularOffer));
        }
        return state;
    }, [query]);
    const [list, , { loading }] = useModel(offerList, [request], {
        control: control().key === Offer.SubKeys.regular_offer_list ? control : undefined,
    });
    const [firstLoading] = useDerivedState(loading, (s) => {
        if (s)
            return loading;
        return s;
    }, [loading]);
    const y = useTableYScroll(450);
    const newStaffActions = useNewStaffActions();
    const setOfferSalaryResult = useSetOfferSalary();
    const editResult = useOnBoardingAndEditModal();
    const delResult = useDeleteModal();
    const modifyChannelResult = useModifyChannel();
    const submit = useCallback((v) => {
        push({
            ...v,
            p_index: 1,
            p_size: 20,
            t: Date.now(),
        });
    }, [push]);
    const onTableChange = useCallback((pagination, _filters, _sorter, extra) => {
        const q = {};
        if (extra.action === 'paginate') {
            q.p_index = pagination.current;
        }
        else if (extra.action === 'sort') {
            q.order_by = switchOrderBy(query.order_by);
        }
        push(q);
    }, [push, query.order_by]);
    const typeFilter = useCallback((arr) => arr.filter((type) => type.id === StaffType.regularSummary), []);
    const genColumns = (orderBy) => [{
            title: '姓名',
            dataIndex: columnDataIndex.name,
            width: 100,
            fixed: 'left',
            render: (value, record) => {
                const now = Date.now();
                const createUt = (getSafe(record, columnDataIndex.createdTime) || 0) * 1000;
                const benchmark = 24 * 60 * 60 * 1000;
                const newFlag = Math.abs(now - createUt) < benchmark;
                if (newFlag) {
                    return (<span className='name'>
            <span className='new-svg'><NewSvg /></span>
            {value}
          </span>);
                }
                return value;
            },
        }, {
            title: '入职岗位',
            dataIndex: columnDataIndex.jobName,
            width: 150,
        }, {
            title: (<WorkTypeColumnTitle />),
            dataIndex: columnDataIndex.work_type,
            width: 150,
            render: (v) => getSafe(workTypeNames, `${v}`),
        }, 
        // {
        //   title: '入职团队',
        //   dataIndex: columnDataIndex.rootTeamName,
        //   width: 150,
        // },
        {
            title: '入职部门',
            dataIndex: columnDataIndex.teamName,
            width: 150,
        }, {
            title: '入职地点',
            dataIndex: columnDataIndex.boardPlace,
            width: 150,
        }, {
            title: 'Leader',
            dataIndex: columnDataIndex.leaderName,
            width: 100,
            render: (_v, record) => {
                const lid = getSafe(record, 'leader_customer_id');
                const name = getSafe(record, columnDataIndex.leaderName);
                if (lid === 0 && name) {
                    return <LeaderWarning name={name}/>;
                }
                return name || <Placeholder text='--'/>;
            },
        }, {
            title: '员工类型',
            dataIndex: columnDataIndex.staffType,
            width: 100,
            render: (v) => staffTypes[v],
        }, {
            title: '手机',
            dataIndex: columnDataIndex.mobile,
            width: 150,
        }, {
            title: '邮箱',
            dataIndex: columnDataIndex.email,
            width: 200,
        }, {
            title: '公司主体',
            dataIndex: columnDataIndex.companyName,
            width: 150,
        }, {
            title: '工作地点',
            dataIndex: columnDataIndex.workplace,
            width: 100,
        }, {
            title: '申请渠道',
            children: [{
                    title: '一级渠道',
                    dataIndex: columnDataIndex.firstChannel,
                    width: 150,
                    render: (v, record) => modifyChannelCell(v, record, modifyChannelResult),
                }, {
                    title: '二级渠道',
                    dataIndex: columnDataIndex.secondChannel,
                    width: 150,
                    render: (v, record) => modifyChannelCell(v, record, modifyChannelResult),
                }, {
                    title: '三级渠道',
                    dataIndex: columnDataIndex.thirdChannel,
                    width: 150,
                    render: (value, record) => modifyChannelCell(value, record, modifyChannelResult),
                }],
        }, {
            title: 'offer类型',
            dataIndex: columnDataIndex.offerType,
            width: 200,
        }, {
            title: '创建时间',
            dataIndex: columnDataIndex.createdTime,
            width: 150,
            render: (v) => {
                if (typeof v === 'number' && v !== 0) {
                    return getYMD(v * 1000);
                }
                return v;
            },
        }, {
            title: '创建人',
            dataIndex: columnDataIndex.creator,
            width: 150,
            render: (v, r) => {
                const inputType = getSafe(r, 'input_type');
                const createdBy = getSafe(r, 'created_by');
                if (!createdBy && inputType === Offer.InputType.moka) {
                    return <LeaderWarning name={v} hidePopover/>;
                }
                return v;
            },
        }, {
            title: '员工状态',
            width: 130,
            dataIndex: columnDataIndex.staffStatus,
            render: (value) => getSafe(offerStatusNames, `${value}`) || <Placeholder text='--'/>,
        }, {
            title: '入职时间',
            width: 150,
            dataIndex: columnDataIndex.boardingTime,
            sorter: true,
            sortOrder: orderByToTableSorter(orderBy),
            fixed: 'right',
            render: (value, record) => {
                const offerStatus = getSafe(record, 'offer_status');
                if (offerStatus === OfferStatus.todo) {
                    return (<SetBoardingTime formatData={(params) => ({
                            candidate_id: getSafe(params, 'id'),
                            [fieldNames.entryTime]: getSafe(params, 'time'),
                        })} allowClear request={recruit.postRecruitBoardingSetBoardingTime} afterSubmit={afterSubmit} time_ut={value} id={getSafe(record, 'candidate_id')}/>);
                }
                return (<section style={{ textAlign: 'center' }}>
          {value ? getYMD(value * 1000) : <Placeholder text='--'/>}
        </section>);
            },
        }, {
            title: 'offer薪资',
            width: 100,
            dataIndex: columnDataIndex.salary,
            fixed: 'right',
            render: (value, record) => {
                const offerStatus = getSafe(record, 'offer_status');
                if (isSalaryMasked(value)) {
                    return value;
                }
                if ([OfferStatus.todo,
                    OfferStatus.done,
                    OfferStatus.invalid].includes(offerStatus)) {
                    return (<SetOfferSalary salary={value} onSet={() => {
                            setOfferSalaryResult.show(record, 'set');
                        }} onEdit={() => {
                            setOfferSalaryResult.show(record, 'edit');
                        }}/>);
                }
                if (isEmpty(value)) {
                    return <Placeholder />;
                }
                return `${value}/月`;
            },
        }, {
            title: '操作',
            key: 'operation',
            width: 150,
            fixed: 'right',
            render: (_v, record) => {
                const offerStatus = getSafe(record, 'offer_status');
                const offerSalarySet = <SetSalaryHidden record={record} replace={replace}/>;
                let btnEl = null;
                if (offerStatus === OfferStatus.todo) {
                    btnEl = [
                        <a key='1' onClick={(e) => {
                                e.stopPropagation();
                                e.cancelable = true;
                                const candidateId = getSafe(record, 'candidate_id');
                                editResult.show({
                                    candidate_id: candidateId,
                                    staffType: getSafe(record, 'customer_type'),
                                }, record);
                            }}>编辑</a>,
                        <a key='2' className='break-btn' onClick={(e) => {
                                e.stopPropagation();
                                e.cancelable = true;
                                delResult.show(getSafe(record, 'candidate_id'));
                            }}>毁约</a>,
                    ];
                }
                if (offerSalarySet || btnEl) {
                    return (<Space>
            {btnEl}
            {offerSalarySet}
          </Space>);
                }
                return null;
            },
        }];
    const [columns] = useDerivedState(() => genColumns(query.order_by), [query.order_by]);
    const editPost = useCallback((data) => offer.editOffer(getSafe(editResult, 'record.candidate_id'), data), [editResult.record]);
    const formatValues = useCallback((values) => {
        // entry_time_ut替换成boarding_time_ut
        const tmp = {
            ...values,
        };
        const jobId = values.job_id;
        const jobList = jobListModel.jobList();
        let jobName = '';
        if (editResult.visible) {
            jobName = getSafe(editResult, 'record.job_name');
        }
        for (let i = 0; i < jobList.length; i += 1) {
            const cat = jobList[i];
            const jobs = getSafe(cat, 'jobs', []);
            const target = jobs.find((j) => j.job_id === jobId);
            if (target) {
                jobName = getSafe(target, 'job_name');
            }
        }
        tmp.boarding_time_ut = values.entry_time_ut;
        delete tmp.entry_time_ut;
        tmp.job_name = jobName;
        tmp.probation_salary = values.salary_probation;
        delete tmp.salary_probation;
        tmp.probation_salary_type = values.salary_probation_type;
        delete tmp.salary_probation_type;
        tmp.turn_regular_salary = values.salary_regular;
        delete tmp.salary_regular;
        tmp.turn_regular_salary_type = values.salary_regular_type;
        delete tmp.salary_regular_type;
        tmp.salary_type = values.salary_intern_type;
        delete tmp.salary_intern_type;
        tmp.salary = values.salary_intern;
        delete tmp.salary_intern;
        return tmp;
    }, [editResult]);
    return (<section className={style.offerList}>
      <section className='header-wrap'>
        <Button className='add-offer' type='primary' onClick={() => newStaffActions.show(undefined)}>添加offer</Button>
        <NewOfferModal checkMobile={false} formatValues={formatValues} hideCorpEmail initialStaffType={StaffType.regular} typeFilter={typeFilter} title='添加offer' apiAction={addOffer} onOk={afterSubmit} onClose={newStaffActions.close} orgId={newStaffActions.orgId} visible={newStaffActions.visible}/>
        <FilterSearch>
          <ListFilterForm staffType={staffTypeFilter} team={teamFilter} company={companyFilter} staffStatus={staffStatusFilter} channel={channelFilter} createdTime={createdTimeFilter} createdBy={createdByFilter} boardingTime={boardingTimeFilter} name={nameFilter} onSubmit={submit}/>
        </FilterSearch>
      </section>
      <ListTotal widgets={<ExportList url={offerExportUrl(urlQueryToApiQuery(query, Offer.ListType.waitingBoard, Offer.OfferManagerSource.regularOffer))}/>} count={getSafe(list, 'total_count', 0)}/>
      <Skeleton loading={firstLoading}>
        <Table 
    // @ts-ignore
    onChange={onTableChange} rowKey='candidate_id' dataSource={list.offer_list} 
    // @ts-ignore
    columns={columns} expandable={{
            expandedRowRender: (record) => (<SalaryRecordTable list={getSafe(record, 'offer_salary_list')}/>),
            rowExpandable: (record) => !!getSafe(record, 'offer_salary_list.length'),
        }} pagination={{
            pageSize: query.p_size,
            current: query.p_index,
            hideOnSinglePage: true,
            total: list.total_count,
            showSizeChanger: false,
        }} scroll={{
            y,
            x: 1650,
        }} loading={{
            spinning: loading,
            indicator: <Spinner type='small'/>,
        }}/>
      </Skeleton>
      <SetOfferSalaryModal afterSubmit={afterSubmit} result={setOfferSalaryResult}/>
      <OnBoardingAndEditModal mode='edit' formatValues={formatValues} apiAction={editPost} hideCorpEmail saveText='保存' title='编辑信息' afterSubmit={afterSubmit} result={editResult}/>
      <DeleteModal afterSubmit={afterSubmit} result={delResult}/>
      <ModifyChannel afterSubmit={afterSubmit} result={modifyChannelResult}/>
    </section>);
};
// withRouter不单单是注入属性，其会引起组件刷新
export default React.memo(securityCrash(OfferList), (_prevProps, nextProps) => nextProps.activeSubKey !== Offer.SubKeys.regular_offer_list || nextProps.location.search === initSearch);
