import { RoadMapType } from '@src/pages/interface';
import history from '@src/Root/history';
import { getSafe } from '@src/tools/util';
import { Badge, Layout, Menu } from 'antd';
import { useDerivedState } from 'femo';
import React, { useCallback, useState } from 'react';
import { EXTERN_KEY_PREFIX } from './interface';
import style from './style.less';
const { SubMenu } = Menu;
const MenuItem = Menu.Item;
const { Sider } = Layout;
const colapisble = (map) => !((map || []).find((r) => getSafe(r, 'path', '').indexOf('/dep-hr') !== -1));
const EmptyIcon = () => null;
const LeftSider = (props) => {
    const { sider, currentRoad, } = props;
    const [collapsed, updateCollapsed] = useState(false);
    const onCollapse = useCallback((colla) => {
        updateCollapsed(colla);
    }, [updateCollapsed]);
    const [curContext] = useState(() => ({
        cachedElements: [],
    }));
    const renderMenus = (menus) => menus.map((item) => {
        let elements = null;
        const { roadTrigger } = item;
        if (item.type !== RoadMapType.fallen) {
            const IconComponent = item.icon || EmptyIcon;
            if (item.hasLivingRoadInSubRoads) {
                elements = (<SubMenu title={<span>{item.name}{roadTrigger}</span>} key={item.completePath} icon={<IconComponent />}>
              {renderMenus(item.subRoads)}
            </SubMenu>);
                // 跳转到非系统内的页面的链接，渲染一个 a 标签
            }
            else if (item.externUrl) {
                elements = (<MenuItem icon={<IconComponent />} key={`${EXTERN_KEY_PREFIX}-${item.externUrl}`}>
              <a href={item.externUrl} rel="noopener noreferrer" {...item.externProps || {}}>
                {item.name}
              </a>
              {roadTrigger}
            </MenuItem>);
            }
            else {
                elements = (<MenuItem onClick={() => {
                        history.push(item.completePath);
                    }} key={item.completePath} title={item.name} icon={<IconComponent />}>
              {item.name}
              {item.badge ? (<Badge size='small' offset={[item.badge > 9 ? 20 : 10, 0]} count={item.badge} overflowCount={99}><span style={{ color: 'transparent' }}>1</span></Badge>) : ''}
              {roadTrigger}
            </MenuItem>);
            }
        }
        return elements;
    });
    useDerivedState(() => {
        curContext.cachedElements = renderMenus(sider);
    }, [sider]);
    // 菜单状态
    const [keys, keysModel] = useDerivedState((state) => {
        const { type } = currentRoad;
        const openKeys = [];
        let current = currentRoad.parent;
        let selectedKeys = [];
        const isLiving = type === RoadMapType.living;
        if (isLiving) {
            selectedKeys = [currentRoad.completePath];
        }
        while (current) {
            openKeys.unshift(current.completePath);
            if (!isLiving) {
                if (current.type === RoadMapType.living && selectedKeys.length === 0) {
                    selectedKeys = [current.completePath];
                }
            }
            current = current.parent;
        }
        const obj = {
            openKeys,
            selectedKeys,
        };
        return {
            ...obj,
            openKeys: Array.from(new Set([...(state?.openKeys ?? []), ...obj.openKeys])),
        };
    }, [sider, currentRoad]);
    // 菜单项点击事件
    const handleItemClick = useCallback((obj) => {
        const key = getSafe(obj, 'key') || '';
        if (key.startsWith(`${EXTERN_KEY_PREFIX}-`)) {
            return;
        }
        // 避免重复渲染，此处采用静默更新
        // 菜单项自身会 history.push
        keysModel.silent((_d, state) => ({
            ...state,
            selectedKeys: [key],
        }));
    }, []);
    // 次级菜单展开状态变化事件
    const handleSubMenuOpenChange = useCallback((oks) => {
        keysModel((_d, state) => ({
            ...state,
            openKeys: oks,
        }));
    }, []);
    const backHome = () => {
        history.push('/');
    };
    const { openKeys, selectedKeys } = keys;
    return (<Sider className={style.misSider} width={style.siderwidth} collapsible={colapisble(sider)} collapsed={collapsed} onCollapse={onCollapse}>
      <header className='mis-logo' onClick={backHome}>
        <section className='mis-logo-png'/>
        <section className='mis-logo-placeholder'/>
        <span className='mis-logo-text'>MIS</span>
      </header>
      <Menu openKeys={openKeys} selectedKeys={selectedKeys} onClick={handleItemClick} onOpenChange={handleSubMenuOpenChange} mode="inline" theme='dark'>
        {curContext.cachedElements}
      </Menu>
    </Sider>);
};
export default LeftSider;
