import { User } from '@src/api/interface';
import { lazy } from 'react';
import { gluer } from 'femo';
import { getSafe, queryToObject } from '@src/tools/util';
import { defaultOfferRegularListQuery } from '@src/pages/HR/Offer/Regular/OfferList';
import { fetchOfferList, fetchQuitList } from '@src/pages/HR/Offer/Regular/service';
import urlQueryToApiQuery from '@src/pages/HR/Offer/Regular/urlQueryToApiQuery';
import { Offer } from '@src/pages/HR/Offer/interface';
import { defaultOfferUrlQuery } from '@src/pages/HR/Offer/url';
import { defaultRegularQuery } from '@src/pages/HR/Offer/Regular';
import { defaultOfferQuitListQuery } from '@src/pages/HR/Offer/Regular/QuitList';
import { OfferStatus } from '@src/pages/components/filter/StaffStatusCheckbox';
const initOfferRoad = {
    name: 'Offer管理',
    permissions: [User.ModuleHRSubAuthKey.menu_hr_offer],
    path: '/offer',
    component: lazy(() => import('./index')),
    prepare: (routeParams, control) => {
        const { location } = routeParams;
        const search = getSafe(location, 'search', '');
        const query = queryToObject(search, {
            ...defaultOfferUrlQuery,
        }, true);
        const tab = getSafe(query, 'tab_key');
        // 一级tab为产研员工
        // 只做产研员工的预加载
        if (tab === Offer.TabKeys.regular) {
            const tmpQuery = queryToObject(search, {
                ...defaultRegularQuery,
            }, true);
            const subTab = getSafe(tmpQuery, 'sub_key');
            // 二级tab 为offer列表
            if (subTab === Offer.SubKeys.regular_offer_list) {
                const curQuery = queryToObject(search, {
                    ...defaultOfferRegularListQuery,
                    s_status: [`${OfferStatus.todo}`],
                });
                control.silent((_d, s) => ({
                    ...s,
                    key: Offer.SubKeys.regular_offer_list,
                }));
                return fetchOfferList(urlQueryToApiQuery(curQuery, Offer.ListType.waitingBoard, Offer.OfferManagerSource.regularOffer));
            }
            // 二级tab 为毁约offer列表
            if (subTab === Offer.SubKeys.regular_quit_list) {
                const curQuery = queryToObject(search, {
                    ...defaultOfferQuitListQuery,
                });
                control.silent((_d, s) => ({
                    ...s,
                    key: Offer.SubKeys.regular_quit_list,
                }));
                return fetchQuitList(urlQueryToApiQuery(curQuery, Offer.ListType.break_contract, Offer.OfferManagerSource.regularOffer));
            }
        }
        return Promise.resolve();
    },
};
const road = gluer(initOfferRoad);
export default road;
