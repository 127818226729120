import { useCallback } from 'react';
import { useIndividualModel } from 'femo';
import { Select } from 'antd';
import { getChannelList } from '../service';
const ChannelSelect = (props) => {
    const { requestChannel, ...rest } = props;
    const getList = useCallback(() => getChannelList({
        requestChannel: requestChannel || '',
    }), [requestChannel]);
    const [list] = useIndividualModel([], [getList]);
    return (<Select {...rest}>
      {list.map((item) => (<Select.Option value={item} key={item}>
              {item}
            </Select.Option>))}
    </Select>);
};
ChannelSelect.defaultProps = {
    requestChannel: '',
};
export default ChannelSelect;
