import React, { Suspense, } from 'react';
import Spinner from '@src/components/Spinner';
import { useDerivedState } from 'femo';
import Routes from './index';
const UndertakeRoute = (props) => {
    const { road, } = props;
    const [routes] = useDerivedState(() => [road], [road]);
    const loadingEl = <Spinner />;
    return (<Suspense fallback={loadingEl}>
      <Routes routes={routes}/>
    </Suspense>);
};
export default UndertakeRoute;
