import React, { useCallback, useState, } from 'react';
import { Button, Input } from 'antd';
import { getSafe } from '@src/tools/util';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
import { common } from '@src/api/actions';
import { isProduction } from '@src/tools/env/index';
const StagingToken = (_props) => {
    // const host = getSafe(window, 'location.hostname') || getSafe(window, 'location.host') || '';
    const [value, updateValue] = useState('');
    const onChange = useCallback((evt) => {
        updateValue(getSafe(evt, 'target.value'));
    }, []);
    const [requestToken, loading] = useRequestWithCallback(common.updateToken, false, false);
    // 线上不展示
    if (isProduction)
        return null;
    return (<section style={{
            height: '400px', textAlign: 'center', width: '300px', marginLeft: 'auto', marginRight: 'auto',
        }}>
      <label htmlFor='customer_id' style={{ marginBottom: '24px' }}>输入员工ID：</label>
      <Input value={value} onChange={onChange} id='customer_id'/>
      <Button style={{ marginTop: '24px' }} loading={loading} disabled={!value} onClick={() => requestToken(value)}>确定</Button>
    </section>);
};
export default StagingToken;
