import { Condition } from '@src/pages/components/ConditionSelect';
import { Input } from 'antd';
import { useDerivedState } from 'femo';
const TextInput = (props) => {
    const { condition, value, composedValueChange, ...rest } = props;
    const disabled = condition === Condition.null || condition === Condition.notEmpty;
    useDerivedState(() => {
        if (disabled && value) {
            composedValueChange('');
        }
    }, [condition]);
    return <Input value={value} {...rest} disabled={disabled}/>;
};
export default TextInput;
