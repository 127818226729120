import moment from 'moment';
import axios from 'axios';
import solarlunar from 'solarlunar';
export const isEmpty = (value) => value === null || value === undefined || value === '' || Object.is(value, NaN);
export const isArrayEmpty = (arr) => !(arr instanceof Array) || !arr.length;
/**
 * 查询字符串转对象
 * @param searchStr 查询字符串
 * @param initialQuery 是查询字符串的对象形式，表明每个值的类型，作为后续转化数据的依据
 * @param strict 是否开启严格模式
 * ⚠️ 对数组解析的结果做了缓存，以提升效率
 */
// 约定数组是这种形式: team_id=1,2,3,4,5
const queryCacheMap = new Map();
export const queryToObject = (searchStr, initialQuery, strict = false) => {
    let str_1 = `${searchStr}`;
    if (str_1.startsWith('?')) {
        str_1 = str_1.substring(1);
    }
    if (!str_1)
        return initialQuery;
    const obj = {};
    const str_2 = str_1.split('&');
    for (let i = 0; i < str_2.length; i += 1) {
        const temp = str_2[i].split('=');
        const key = temp[0];
        // 如果是严格模式，则只取initialQuery里面出现过的key
        if (strict && !(key in initialQuery)) {
            continue;
        }
        const value = temp[1] ? temp[1].trim() : '';
        // eslint-disable-next-line no-continue
        if (temp.length < 2)
            continue;
        try {
            obj[key] = decodeURIComponent(value);
        }
        catch (e) {
            // eslint-disable-next-line prefer-destructuring
            obj[key] = value;
        }
        if (isEmpty(obj[key])) {
            // @ts-ignore
            obj[key] = initialQuery[key];
        }
        // @ts-ignore
        const initialValue = initialQuery[key];
        // 只处理initialQuery中类型为boolean和number的
        // 其他值会被当做字符串
        if (typeof initialValue === 'boolean') {
            if (process.env.NODE_ENV === 'development') {
                if (obj[key] !== 'true' && obj[key] !== 'false') {
                    throw new Error(`the value of '${key}' in url is not 'true' neither 'false', but its type in the initialQuery is boolean. please check it out`);
                }
            }
            if (obj[key] === 'true') {
                obj[key] = true;
            }
            else {
                // 'true' 和 ' false'之外的其他值都会被处理为布尔值 true
                obj[key] = obj[key] !== 'false';
            }
        }
        else if (typeof initialValue === 'number') {
            obj[key] = Number(obj[key]);
        }
        else if (initialValue instanceof Array && !(obj[key] instanceof Array)) {
            const mapKey = obj[key];
            if (queryCacheMap.has(mapKey)) {
                obj[key] = queryCacheMap.get(mapKey);
            }
            else {
                // 约定的"1,2,3"这种字符串代表数组
                obj[key] = mapKey.split(',').filter((v) => !isEmpty(v));
                queryCacheMap.set(mapKey, [...(obj[key])]);
            }
        }
    }
    if (initialQuery) {
        const initKeys = Object.keys(initialQuery);
        initKeys.forEach((k) => {
            if (!(k in obj)) {
                // @ts-ignore
                obj[k] = initialQuery[k];
            }
        });
    }
    return obj;
};
/**
 * 对象转查询字符串
 * @param obj
 */
export const objectToQuery = (obj) => {
    if (isEmpty(obj))
        return null;
    return Object.keys(obj).reduce((pre, cur) => {
        let temp;
        const value = obj[cur];
        if (isEmpty(value)) {
            return pre;
        }
        if (!pre) {
            temp = `${cur}=${encodeURIComponent(value)}`;
        }
        else {
            temp = `${pre}&${cur}=${encodeURIComponent(value)}`;
        }
        return temp;
    }, '');
};
/**
 * 当前客户端浏览器是否为移动端
 */
export const isMobile = () => /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
const isDefaultFn = (d) => typeof d === 'function';
/**
 * 安全取操作
 * @param target
 * @param keyPath
 * @param defaultValue 当为函数时，不管取得的值是否为空，都会经过defaultValue函数处理然后返回，函数的返回值将作为最终的值。不应大量使用函数，可能会有性能问题
 */
export const getSafe = (target, keyPath, defaultValue) => {
    try {
        // const regex = /^\[\d\]$/;
        const mixRegex = /^.*\[\d\]$/;
        const rest = keyPath.split('.');
        let temp = target;
        rest.forEach((key) => {
            if (process.env.NODE_ENV === 'development') {
                if (typeof key !== 'string') {
                    throw new Error(`getValueOf: only handle the string index of Object. target: ${target}, key: ${key}`);
                }
            }
            if (mixRegex.test(key)) {
                const leftIndex = key.lastIndexOf('[');
                const rightIndex = key.lastIndexOf(']');
                const number = key.substring(leftIndex + 1, rightIndex);
                if (leftIndex === 0) {
                    temp = temp && temp[Number(number)];
                }
                else {
                    const tmpFirst = key.substring(0, leftIndex);
                    temp = temp && temp[tmpFirst] && temp[tmpFirst][Number(number)];
                }
            }
            else {
                temp = temp && temp[key];
            }
        });
        if (isEmpty(temp)) {
            if (isDefaultFn(defaultValue)) {
                return defaultValue(temp);
            }
            return defaultValue;
        }
        if (isDefaultFn(defaultValue)) {
            return defaultValue(temp);
        }
        return temp;
    }
    catch (e) {
        if (isDefaultFn(defaultValue)) {
            return defaultValue();
        }
        return defaultValue;
    }
};
export const debounce = (fn, delay) => {
    let timer;
    return (...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn(...args);
            timer = null;
        }, delay);
    };
};
export const throttle = (fn, period) => {
    let pre = Date.now();
    return (...args) => {
        const now = Date.now();
        if (now - pre > period) {
            pre = now;
            fn(...args);
        }
    };
};
/**
 * 获取YYYY-MM-DD的日期字符串
 * @param date
 * @param formatStr
 */
export const getYMD = (date, formatStr = 'YYYY-MM-DD') => moment(date).format(formatStr);
export const getYMDhm = (date) => {
    const dateObj = moment(date);
    return dateObj.format('YYYY-MM-DD HH:mm');
};
export const getYMDhms = (date) => {
    const dateObj = moment(date);
    return dateObj.format('YYYY-MM-DD HH:mm:ss');
};
export const getMD = (date) => moment(date).format('MM-DD');
export const genAxiosCancelSource = () => axios.CancelToken.source();
export const regex = {
    url: (value) => {
        const reg = /^((http(s)?):\/\/)?([-_\w])+(\.([-_\w])+)*(\.[-_\w][-_\w]+).*$/i;
        return reg.test(value);
    },
    number: (value) => {
        const reg = /^\d+\.?$/;
        return reg.test(value);
    },
    variableName: (value) => {
        const reg = /^(\w|\d|_)+$/i;
        return reg.test(value);
    },
    mobile: (value) => {
        const reg = /^1[3456789]\d{9}$/;
        return reg.test(value);
    },
    email: (value) => {
        const reg = /^\w+@\w+(\.(\w)+)*$/;
        return reg.test(value);
    },
    emailPrefix: (value) => {
        const reg = /^[\S\w\d_.]+$/;
        return reg.test(value);
    },
    id: (value) => {
        // 代码来源：https://www.cnblogs.com/ifat3/p/8570062.html
        // 此方法有问题
        // const checkCode = (val: string) => {
        //   const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        //   const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        //   const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
        //   const code = val.substring(17);
        //   if (p.test(val)) {
        //     let sum = 0;
        //     for (let i = 0; i < 17; i += 1) {
        //       // @ts-ignore
        //       sum += val[i] * factor[i];
        //     }
        //     if (parity[sum % 11] === code.toUpperCase()) {
        //       return true;
        //     }
        //   }
        //   return false;
        // };
        // 港澳通行证
        const regHM = /^[HM](([0-9]{8})|([0-9]{10}))$/;
        if (regHM.test(value)) {
            return true;
        }
        const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/i;
        const checkDate = function (val) {
            const pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
            if (pattern.test(val)) {
                const year = val.substring(0, 4);
                const month = val.substring(4, 6);
                const date = val.substring(6, 8);
                const date2 = new Date(`${year}-${month}-${date}`);
                if (date2 && date2.getMonth() === (parseInt(month, 10) - 1)) {
                    return true;
                }
            }
            return false;
        };
        const checkProv = function (val) {
            const pattern = /^[1-9][0-9]/;
            const provs = {
                11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江 ', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北 ', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏 ', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门',
            };
            if (pattern.test(val)) {
                // @ts-ignore
                if (provs[val]) {
                    return true;
                }
            }
            return false;
        };
        if (reg.test(value)) {
            const date = value.substring(6, 14);
            if (checkDate(date)) {
                if (checkProv(value.substring(0, 2))) {
                    return true;
                }
            }
        }
        const residencePermitReg = /^8[123]0000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/;
        return residencePermitReg.test(value);
    },
};
export const variablePlaceholderReplace = (value, origin) => value.replace(/{[a-zA-Z0-9_]+}/g, (match) => {
    // match会是 {depart_name}形如这种的
    const key = match.substring(1, match.length - 1);
    return origin[key];
});
export const stringToNumber = (str, radix = 10) => {
    const result = parseInt(str, radix);
    if (Object.is(result, NaN)) {
        return str;
    }
    return result;
};
export const flatArr = (arr) => {
    const temp = [];
    const iterator = (innerArr) => {
        innerArr.forEach((item) => {
            if (!(item instanceof Array)) {
                temp.push(item);
            }
            else if (item.length === 0) {
                temp.push('');
            }
            else {
                iterator(item);
            }
        });
    };
    iterator(arr);
    return temp;
};
export const isInViewPortVertical = (element) => {
    if (!element || !element.getBoundingClientRect)
        return true;
    const viewHeight = window.innerHeight || getSafe(document, 'documentElement.clientHeight');
    const boundingClientRect = element.getBoundingClientRect();
    const top = getSafe(boundingClientRect, 'top');
    // console.log('bottom', bottom, 'viewHeight', viewHeight, get(element, 'scrollTop'));
    return top >= 0 && top < viewHeight;
};
export const isNumber = (num) => typeof num === 'number';
export const isString = (str) => typeof str === 'string';
export const isSet = (s) => s instanceof Set;
export const isArray = (array) => array instanceof Array;
// prefix 结尾不要传斜杠
export const isPathStartWith = (prefix, path) => ((path.startsWith(prefix) && path === prefix) || path.startsWith(`${prefix}/`));
export const parseJsonString = (jsonString) => {
    let result = null;
    try {
        result = JSON.parse(jsonString);
    }
    catch (e) {
        // todo
    }
    return result;
};
// 招商银行卡号检测
// 储蓄卡
export const luhnCheck = (card_number) => {
    if (!(/^[0-9]{16}$/).test(card_number)) {
        return false;
    }
    const nums = card_number.split('').reverse();
    let odd_sum = 0;
    let even_sum = 0;
    nums.forEach((num, i) => {
        let tmpNum = Number(num);
        if ((i + 1) % 2) {
            odd_sum += tmpNum * 1;
        }
        else {
            tmpNum *= 2;
            tmpNum = tmpNum > 9 ? (tmpNum - 9) : tmpNum;
            even_sum += tmpNum;
        }
    });
    const sum = odd_sum + even_sum;
    return !(sum % 10);
};
export const bankDcCardCheck = (bank, cardNumber) => {
    const obj = {
        // 中国邮政储蓄银行卡号检测
        // 储蓄卡
        PSB: (cardNum) => {
            const regs = [
                /^(621096|621098|622150|622151|622181|622188|622199|955100|621095|620062|621285|621798|621799|621797|620529|621622|621599|621674|623218|623219)\d{13}$/,
                /^(62215049|62215050|62215051|62218850|62218851|62218849)\d{11}$/,
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
        // 中国工商银行
        // 储蓄卡
        ICB: (cardNum) => {
            const regs = [
                /^(620200|620302|620402|620403|620404|620406|620407|620409|620410|620411|620412|620502|620503|620405|620408|620512|620602|620604|620607|620611|620612|620704|620706|620707|620708|620709|620710|620609|620712|620713|620714|620802|620711|620904|620905|621001|620902|621103|621105|621106|621107|621102|621203|621204|621205|621206|621207|621208|621209|621210|621302|621303|621202|621305|621306|621307|621309|621311|621313|621211|621315|621304|621402|621404|621405|621406|621407|621408|621409|621410|621502|621317|621511|621602|621603|621604|621605|621608|621609|621610|621611|621612|621613|621614|621615|621616|621617|621607|621606|621804|621807|621813|621814|621817|621901|621904|621905|621906|621907|621908|621909|621910|621911|621912|621913|621915|622002|621903|622004|622005|622006|622007|622008|622010|622011|622012|621914|622015|622016|622003|622018|622019|622020|622102|622103|622104|622105|622013|622111|622114|622017|622110|622303|622304|622305|622306|622307|622308|622309|622314|622315|622317|622302|622402|622403|622404|622313|622504|622505|622509|622513|622517|622502|622604|622605|622606|622510|622703|622715|622806|622902|622903|622706|623002|623006|623008|623011|623012|622904|623015|623100|623202|623301|623400|623500|623602|623803|623901|623014|624100|624200|624301|624402|623700|624000)\d{12}$/,
                /^(622200|622202|622203|622208|621225|620058|621281|900000|621558|621559|621722|621723|620086|621226|621618|620516|621227|621288|621721|900010|623062|621670|621720|621379|621240|621724|621762|621414|621375|622926|622927|622928|622929|622930|622931|621733|621732|621372|621369|621763)\d{13}$/,
                /^(402791|427028|427038|548259|621376|621423|621428|621434|621761|621749|621300|621378|622944|622949|621371|621730|621734|621433|621370|621764|621464|621765|621750|621377|621367|621374|621731|621781)\d{10}$/,
                /^(9558)\d{15}$/,
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
        // 中国农业银行
        // 储蓄卡
        ABC: (cardNum) => {
            const regs = [
                /^(622841|622824|622826|622848|620059|621282|622828|622823|621336|621619|622821|622822|622825|622827|622845|622849|623018|623206|621671|622840|622843|622844|622846|622847|620501)\d{13}$/,
                /^(95595|95596|95597|95598|95599)\d{14}$/,
                /^(103)\d{16}$/,
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
        // 中国银行
        // 储蓄卡
        BOC: (cardNum) => {
            const regs = [
                /^(621660|621661|621662|621663|621665|621667|621668|621669|621666|456351|601382|621256|621212|621283|620061|621725|621330|621331|621332|621333|621297|621568|621569|621672|623208|621620|621756|621757|621758|621759|621785|621786|621787|621788|621789|621790|622273|622274|622771|622772|622770|621741|621041)\d{13}$/,
                /^(621293|621294|621342|621343|621364|621394|621648|621248|621215|621249|621231|621638|621334|621395|623040|622348)\d{10}$/,
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
        // 中国建设银行
        // 储蓄卡
        CCB: (cardNum) => {
            const regs = [
                /^(621284|436742|589970|620060|621081|621467|621598|621621|621700|622280|622700|623211|623668)\d{13}$/,
                /^(421349|434061|434062|524094|526410|552245|621080|621082|621466|621488|621499|622966|622988|622382|621487|621083|621084|620107)\d{10}$/,
                /^(436742193|622280193)\d{10}$/,
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
        // 中国交通银行
        // 储蓄卡
        COMM: (cardNum) => {
            const regs = [
                /^(622261|622260|622262|621002|621069|621436|621335)\d{13}$/,
                /^(620013)\d{10}$/,
                /^(405512|601428|405512|601428|622258|622259|405512|601428)\d{11}$/,
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
        // 招商银行
        // 储蓄卡和预付费卡
        CMB: (cardNum) => {
            const regs = [
                /^(402658|410062|468203|512425|524011|622580|622588|622598|622609|95555|621286|621483|621485|621486|621299)\d{10}$/,
                /^(690755)\d{9}$/,
                /^(690755)\d{12}$/,
                /^(620520)\d{13}$/, // 预付费卡
            ];
            return regs.some((reg) => reg.test(cardNum));
        },
    };
    const checkFn = obj[bank];
    return checkFn(cardNumber);
};
export const formatRegexString = (str) => str.replace(/(\()|(\))|(\{)|(\})|(\.)/g, (match) => {
    if (match === '(') {
        return '（';
    }
    if (match === ')') {
        return '（';
    }
    if (match === '}') {
        return '】';
    }
    if (match === '{') {
        return '【';
    }
    // if (match === '.') {
    //   return '点';
    // }
    return match;
});
export const lunarToSolar = (momentObj) => {
    const pickedDay = moment(momentObj);
    const year = pickedDay.year();
    const month = pickedDay.month();
    const date = pickedDay.date();
    const lunar2solarData = solarlunar.lunar2solar(Number(year), Number(month + 1), Number(date));
    const cYear = getSafe(lunar2solarData, 'cYear');
    const cMonth = getSafe(lunar2solarData, 'cMonth');
    const cDay = getSafe(lunar2solarData, 'cDay');
    return moment([cYear, cMonth - 1, cDay]);
};
export const solarToLunar = (momentObj) => {
    const year = momentObj.year();
    const month = momentObj.month();
    const date = momentObj.date();
    const lunarData = solarlunar.solar2lunar(Number(year), Number(month + 1), Number(date));
    const lYear = getSafe(lunarData, 'lYear');
    const lMonth = getSafe(lunarData, 'lMonth');
    const lDay = getSafe(lunarData, 'lDay');
    return moment([lYear, lMonth - 1, lDay]);
};
export const solarBirthDayStr = (momentObj) => {
    const year = momentObj.year();
    const month = momentObj.month();
    const date = momentObj.date();
    return `公历${year}年${month + 1}月${date}日`;
};
export const lunarBirthDayStr = (momentObj) => {
    const year = momentObj.year();
    const month = momentObj.month();
    const date = momentObj.date();
    const lunarData = solarlunar.solar2lunar(Number(year), Number(month + 1), Number(date));
    const lYear = getSafe(lunarData, 'lYear');
    const lMonth = getSafe(lunarData, 'lMonth');
    const lDay = getSafe(lunarData, 'lDay');
    return `农历${lYear}年${lMonth}月${lDay}日`;
};
export const getOffset = (dom) => {
    let obj = dom;
    let top = obj.offsetTop;
    let left = obj.offsetLeft;
    while (obj.offsetParent) {
        // @ts-ignore
        obj = obj.offsetParent;
        top += obj.offsetTop;
        left += obj.offsetLeft;
    }
    return {
        top,
        left,
    };
};
export const precisionNum = (target, precision) => Number(Number(target).toFixed(precision));
export const ensureStringLength = (str, length) => {
    if (typeof str === 'string') {
        const reg = /[^x00-xff]/g;
        const newStr = str.replace(reg, () => '****');
        const { length: strLength } = newStr;
        if (strLength > length) {
            return `${str.substring(0, length - 4)}...`;
        }
        if (strLength < length) {
            return `${str}${' '.repeat(length - strLength)}`;
        }
    }
    return str;
};
