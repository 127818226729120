import React from 'react';
import Lottie from 'react-lottie';
import classNames from 'classnames';
// import LoadingSVG from '@src/assets/svg/loading.svg';
import { isEmpty, isMobile } from '@src/tools/util';
import animationJson from './spinner_colorful.json';
import styleLess from './style.less';
const lottieOptions = {
    animationData: animationJson,
    loop: true,
    autoplay: true,
};
const Spinner = (props) => {
    const { className, size, style, type, } = props;
    let tempSize = size;
    if (isEmpty(tempSize) || typeof size !== 'number') {
        if (type === 'small') {
            tempSize = 32;
        }
        else if (type === 'large') {
            tempSize = 54;
        }
        else if (type === 'mid') {
            tempSize = 40;
        }
        else {
            tempSize = 48;
        }
    }
    const isMobileFlag = isMobile();
    const lottieSize = isMobileFlag ? `${tempSize / 100}rem` : tempSize;
    return (<section style={style} className={classNames(styleLess.spinner, className)}>
      <section className='spinner-wrap'>
        <Lottie options={lottieOptions} width={lottieSize} height={lottieSize} isClickToPauseDisabled={true}/>
      </section>
    </section>);
};
Spinner.defaultProps = {
    type: 'default',
};
export default Spinner;
