import React, { useCallback } from 'react';
import { Form } from 'antd';
import ConditionSelect from '@src/pages/components/ConditionSelect';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import UserSelect from '../../components/UserSelect';
const CreatedByFilterField = (props) => {
    const { ...restProps } = props;
    const renderCondition = useCallback((_v, condition, onChange) => (<ConditionSelect value={condition} onChange={onChange}/>), []);
    const renderValue = useCallback((value, _c, onChange) => (<UserSelect value={value} onChange={onChange}/>), []);
    return (<Form.Item label='创建人' {...restProps} {...fieldLayout}>
        <FilterFieldCompose renderCondition={renderCondition} renderValue={renderValue}/>
      </Form.Item>);
};
export default CreatedByFilterField;
