import { Select } from 'antd';
export var EmailSuffix;
(function (EmailSuffix) {
    EmailSuffix["baicizhan"] = "@baicizhan.com";
    EmailSuffix["hfzxtech"] = "@hfzxtech.com";
})(EmailSuffix || (EmailSuffix = {}));
const emailSuffixArr = [{
        id: EmailSuffix.baicizhan,
        name: EmailSuffix.baicizhan,
    }, {
        id: EmailSuffix.hfzxtech,
        name: EmailSuffix.hfzxtech,
    }];
const EmailSuffixSelect = (props) => (<Select {...props}>
      {emailSuffixArr.map((item) => (<Select.Option value={item.id} key={item.id}>
              {item.name}
            </Select.Option>))}
    </Select>);
EmailSuffixSelect.defaultProps = {
    placeholder: '请选择',
};
export default EmailSuffixSelect;
