import React from 'react';
import { Select } from 'antd';
import { getSafe } from '@src/tools/util';
export var ContractTypes;
(function (ContractTypes) {
    ContractTypes[ContractTypes["solid"] = 0] = "solid";
    ContractTypes[ContractTypes["random"] = 1] = "random";
    ContractTypes[ContractTypes["practice"] = 2] = "practice";
    ContractTypes[ContractTypes["outsourcing"] = 3] = "outsourcing";
    ContractTypes[ContractTypes["outsourcingForeign"] = 4] = "outsourcingForeign";
    ContractTypes[ContractTypes["practiceTurnRegular"] = 5] = "practiceTurnRegular";
    ContractTypes[ContractTypes["outsourcingContract"] = 6] = "outsourcingContract";
    ContractTypes[ContractTypes["partTimeOutsourcing"] = 7] = "partTimeOutsourcing";
})(ContractTypes || (ContractTypes = {}));
const contractTypes = [{
        id: ContractTypes.solid,
        name: '固定期限劳动合同',
    }, {
        id: ContractTypes.random,
        name: '无固定期限劳动合同',
    }, {
        id: ContractTypes.practice,
        name: '实习协议',
    }, {
        id: ContractTypes.practiceTurnRegular,
        name: '实习协议(转正式)',
    }, {
        id: ContractTypes.outsourcing,
        name: '派遣员工劳动合同（中智)',
    }, {
        id: ContractTypes.outsourcingForeign,
        name: '派遣员工合同（外籍)',
    }, {
        id: ContractTypes.outsourcingContract,
        name: '劳动合同（外包）',
    }, {
        id: ContractTypes.partTimeOutsourcing,
        name: '兼职外包合同',
    }];
export const contractTypesValue = {
    [ContractTypes.solid]: '固定期限劳动合同',
    [ContractTypes.random]: '无固定期限劳动合同',
    [ContractTypes.practice]: '实习协议',
    [ContractTypes.practiceTurnRegular]: '实习协议(转正式)',
    [ContractTypes.outsourcing]: '派遣员工劳动合同（中智)',
    [ContractTypes.outsourcingForeign]: '派遣员工合同（外籍)',
    [ContractTypes.outsourcingContract]: '劳动合同（外包）',
    [ContractTypes.partTimeOutsourcing]: '兼职外包合同',
};
const ContractTypeSelect = (props) => (<Select {...props}>
      {contractTypes.map((type) => (<Select.Option key={getSafe(type, 'id')} value={getSafe(type, 'name')}>
            {getSafe(type, 'name')}
          </Select.Option>))}
    </Select>);
export default ContractTypeSelect;
