import React from 'react';
import { Form, InputNumber } from 'antd';
const rules = [];
const SalaryField = (props) => {
    const { disabled, ...rest } = props;
    return (<Form.Item validateFirst {...rest} rules={rest.rules ? [...rest.rules, ...rules] : rules}>
      <InputNumber disabled={disabled} min={0} precision={0} style={{ width: '100%' }} autoComplete='off' placeholder='请输入'/>
    </Form.Item>);
};
SalaryField.defaultProps = {
    label: '薪资',
    name: '',
};
export default SalaryField;
