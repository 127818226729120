export var Offer;
(function (Offer) {
    let ListType;
    (function (ListType) {
        ListType[ListType["waitingBoard"] = 0] = "waitingBoard";
        ListType[ListType["break_contract"] = 100] = "break_contract";
    })(ListType = Offer.ListType || (Offer.ListType = {}));
    let OfferManagerSource;
    (function (OfferManagerSource) {
        OfferManagerSource[OfferManagerSource["regularOffer"] = 100] = "regularOffer";
        OfferManagerSource[OfferManagerSource["notRegularOffer"] = 200] = "notRegularOffer";
    })(OfferManagerSource = Offer.OfferManagerSource || (Offer.OfferManagerSource = {}));
    let TabKeys;
    (function (TabKeys) {
        TabKeys["regular"] = "regular";
        TabKeys["cost"] = "cost";
    })(TabKeys = Offer.TabKeys || (Offer.TabKeys = {}));
    let SubKeys;
    (function (SubKeys) {
        SubKeys["regular_offer_list"] = "offer_list";
        SubKeys["regular_quit_list"] = "quit_list";
        SubKeys["cost_summary"] = "summary";
        SubKeys["cost_introducing"] = "introducing";
        SubKeys["cost_hunter"] = "hunter";
        SubKeys["cost_rule"] = "rule";
    })(SubKeys = Offer.SubKeys || (Offer.SubKeys = {}));
    let Del;
    (function (Del) {
        Del[Del["done"] = 1] = "done";
        Del[Del["normal"] = 0] = "normal";
    })(Del = Offer.Del || (Offer.Del = {}));
    let InputType;
    (function (InputType) {
        InputType[InputType["moka"] = 0] = "moka";
        InputType[InputType["manual"] = 100] = "manual";
    })(InputType = Offer.InputType || (Offer.InputType = {}));
    let TimeUnit;
    (function (TimeUnit) {
        TimeUnit[TimeUnit["day"] = 100] = "day";
        TimeUnit[TimeUnit["month"] = 200] = "month";
    })(TimeUnit = Offer.TimeUnit || (Offer.TimeUnit = {}));
    let PayRecordFinanceStatus;
    (function (PayRecordFinanceStatus) {
        PayRecordFinanceStatus[PayRecordFinanceStatus["waiting"] = 0] = "waiting";
        PayRecordFinanceStatus[PayRecordFinanceStatus["approved"] = 5] = "approved";
        PayRecordFinanceStatus[PayRecordFinanceStatus["paid"] = 9] = "paid";
    })(PayRecordFinanceStatus = Offer.PayRecordFinanceStatus || (Offer.PayRecordFinanceStatus = {}));
})(Offer || (Offer = {}));
