import { useCallback, useRef, } from 'react';
import EnhancedModal from '@src/components/EnhancedModal';
import ChannelSelect from '@src/pages/HR/common/ModifyChannel/ChannelSelect';
import { Button, Col, Form, Input, message, Row, Space, } from 'antd';
import { getSafe } from '@src/tools/util';
import { useDerivedState, useException } from 'femo';
import UserSelect from '@src/pages/components/UserSelect';
import { setChannel } from '@src/pages/HR/common/ModifyChannel/service';
import { scrollToFirstErrorField } from '@src/pages/HR/Operation/Probation/commonLogic';
import { cost } from '@src/api/actions';
import { PayStatus } from '@src/pages/components/filter/IssueStatusCheckbox';
import useRequestWithCallback from '@src/hooks/useRequestWithCallback';
const formLayout = {
    labelCol: {
        sm: 6,
        xs: 6,
    },
    wrapperCol: {
        sm: 16,
        xs: 16,
    },
};
const formNames = {
    firstChannel: 'firstChannel',
    secondChannel: 'secondChannel',
    thirdChannel: 'thirdChannel',
    thirdChannelRefererId: 'thirdChannelRefererId',
};
const apiError = 'api error';
const ModifyChannel = (props) => {
    const { result, afterSubmit } = props;
    const formRef = useRef(null);
    const manualException = useException(() => !result.visible);
    const [dyFirstChannel, dyFirstChannelModel] = useDerivedState(() => getSafe(result, 'data.firstChannel', ''), [result]);
    const [dySecondChannel, dySecondChannelModel] = useDerivedState(() => getSafe(result, 'data.secondChannel', ''), [result]);
    const [isIntro] = useDerivedState(() => dyFirstChannel.indexOf('内推') !== -1 && dyFirstChannel.indexOf('门户') !== -1, [dyFirstChannel]);
    const [dyThirdChannel, _dyThirdChannelModel] = useDerivedState(() => {
        const label = getSafe(result, 'data.thirdChannel');
        if (isIntro) {
            return {
                label,
                key: getSafe(result, 'data.thirdChannelRefererId'),
            };
        }
        return label;
    }, [isIntro, result]);
    useDerivedState(null, () => {
        if (!formRef.current)
            return;
        formRef.current.setFields([{
                name: formNames.thirdChannel,
                errors: [],
                touched: false,
                validating: false,
                value: null,
            }]);
    }, [isIntro]);
    const onValuesChange = useCallback((changedValues) => {
        if (formNames.firstChannel in changedValues) {
            dyFirstChannelModel(changedValues[formNames.firstChannel]);
            formRef.current.setFieldsValue({
                [formNames.secondChannel]: undefined,
            });
        }
        if (formNames.secondChannel in changedValues) {
            dySecondChannelModel(changedValues[formNames.secondChannel]);
        }
    }, []);
    const onSubmit = () => formRef.current.validateFields().then((values) => {
        const offerId = getSafe(result, 'data.offerId');
        const setChannelFn = (v) => setChannel({
            offerId,
            ...v,
        }).catch(() => Promise.reject(apiError)).then(() => {
            manualException.tryThrow();
            result.hide();
            if (afterSubmit) {
                afterSubmit();
            }
        });
        if (isIntro) {
            const third = getSafe(values, formNames.thirdChannel);
            values[formNames.thirdChannel] = getSafe(third, 'label');
            values[formNames.thirdChannelRefererId] = getSafe(third, 'key');
            return cost.checkCostStatus(offerId).then((res) => {
                manualException.tryThrow();
                const payStatus = getSafe(res, 'data.payStatus');
                if (payStatus === PayStatus.notPay) {
                    return Promise.resolve();
                }
                message.error('内推奖金已发放, 不可修改');
                return Promise.reject(apiError);
            }).then(() => setChannelFn(values));
        }
        return setChannelFn(values);
    }).catch((err) => {
        manualException.tryThrow();
        if (err !== apiError) {
            scrollToFirstErrorField(err, formRef.current);
        }
    });
    const [submit, loading] = useRequestWithCallback(onSubmit, false, false);
    const footer = (<Space>
      <Button onClick={result.hide}>取消</Button>
      <Button type='primary' loading={loading} onClick={submit}>确定</Button>
    </Space>);
    return (<EnhancedModal title='修改渠道' visible={result.visible} onCancel={result.hide} destroyOnClose width={960} footer={footer}>
      <Form ref={formRef} onValuesChange={onValuesChange} hideRequiredMark layout='horizontal' {...formLayout}>
        <Row>
          <Col span={8}>
            <Form.Item label='一级渠道' name={formNames.firstChannel} rules={[{
                required: true,
                message: '请选择',
            }]} initialValue={dyFirstChannel}>
              <ChannelSelect />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='二级渠道' name={formNames.secondChannel} initialValue={dySecondChannel}>
              <ChannelSelect requestChannel={dyFirstChannel}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='三级渠道' name={formNames.thirdChannel} initialValue={dyThirdChannel} rules={isIntro ? [{
                required: true,
                message: '请选择',
            }] : [{
                max: 10,
                message: '最多10个字',
            }]}>
              {isIntro ? (<UserSelect />) : <Input />}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </EnhancedModal>);
};
export default ModifyChannel;
