import React from 'react';
import { isEmpty } from '@src/tools/util';
import style from './style.less';
const SetOfferSalary = (props) => {
    const { salary, onSet, onEdit, } = props;
    return (<section onClick={(e) => {
            e.stopPropagation();
            e.cancelable = true;
        }}>
      <section>
        {!isEmpty(salary) ? (<span className={style.time} onClick={onEdit}>
                {salary}/月
            <span className='edit'>修改</span>
              </span>) : <a onClick={onSet}>设置</a>}
      </section>
    </section>);
};
export default SetOfferSalary;
