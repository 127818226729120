export var Type;
(function (Type) {
    Type["myApply"] = "my_apply";
    Type["myApproval"] = "my_approval";
})(Type || (Type = {}));
export var Tab;
(function (Tab) {
    Tab["todo"] = "todo";
    Tab["done"] = "done";
})(Tab || (Tab = {}));
