export var Offer2;
(function (Offer2) {
    let TabKeys;
    (function (TabKeys) {
        TabKeys["notRegular"] = "notRegular";
    })(TabKeys = Offer2.TabKeys || (Offer2.TabKeys = {}));
    let SubKeys;
    (function (SubKeys) {
        SubKeys["not_regular_offer_list"] = "offer_list";
        SubKeys["not_regular_quit_list"] = "quit_list";
    })(SubKeys = Offer2.SubKeys || (Offer2.SubKeys = {}));
})(Offer2 || (Offer2 = {}));
