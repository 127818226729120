import React, { useCallback, } from 'react';
import { useDerivedStateToModel, useIndividualModel } from 'femo';
import { Col, Row } from 'antd';
import { getSafe } from '@src/tools/util';
import { securityCrash } from '@src/components/HOC';
const FilterFieldCompose = (props) => {
    const { value: propValue, onChange, nameOfValue, nameOfCondition, nameOfExtend, renderCondition, renderValue, renderExtends, valueSpanStyle, } = props;
    const [, valueModel] = useIndividualModel(propValue);
    const [value] = useDerivedStateToModel(props, valueModel, (nextProps, _prevProps, state) => {
        if ('value' in nextProps) {
            return nextProps.value;
        }
        return state;
    });
    const conditionChange = useCallback((c) => {
        let r = c;
        if (!(r instanceof Array)) {
            r = [r];
        }
        const v = {
            ...value,
            [nameOfCondition]: r,
        };
        if (onChange) {
            onChange(v);
        }
        else {
            valueModel(v);
        }
    }, [onChange, value]);
    const valueChange = useCallback((v) => {
        let t = v;
        if (!(v instanceof Array)) {
            if (v) {
                t = [v];
            }
            else {
                t = [];
            }
        }
        const r = {
            ...value,
            [nameOfValue]: t,
        };
        if (onChange) {
            onChange(r);
        }
        else {
            valueModel(r);
        }
    }, [onChange, value]);
    const extendChange = useCallback((v) => {
        let t = v;
        if (!(v instanceof Array)) {
            if (v) {
                t = [v];
            }
            else {
                t = [];
            }
        }
        const r = {
            ...value,
            [nameOfExtend]: {
                // @ts-ignore
                ...(value[nameOfExtend]),
                value: t,
            },
        };
        if (onChange) {
            onChange(r);
        }
        else {
            valueModel(r);
        }
    }, [onChange, value]);
    const v = getSafe(value, `${nameOfValue}`);
    const condition = getSafe(value, `${nameOfCondition}`);
    return (<Row>
      <Col span={4}>
        {renderCondition(v, condition, conditionChange)}
      </Col>
      <Col span={1}/>
      <Col style={valueSpanStyle} span={renderExtends ? 13 : 18}>
        {renderValue(v, condition, valueChange)}
      </Col>
      {renderExtends ? (<>
            <Col span={1}/>
            <Col span={5}>
              {renderExtends(value, extendChange)}
            </Col>
          </>) : null}
    </Row>);
};
FilterFieldCompose.defaultProps = {
    nameOfValue: 'value',
    nameOfCondition: 'condition',
    nameOfExtend: 'extends',
};
export default securityCrash(FilterFieldCompose);
