import React, { useCallback } from 'react';
import { Form } from 'antd';
import ConditionSelect, { conditions_a } from '@src/pages/components/ConditionSelect';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import StaffStatusCheckbox from '@src/pages/components/filter/StaffStatusCheckbox';
const StaffStatusFilterField = (props) => {
    const renderCondition = useCallback((_v, c, onChange) => (<ConditionSelect conditions={conditions_a} value={c} onChange={onChange}/>), []);
    const renderValue = useCallback((value, _c, onChange) => (<StaffStatusCheckbox value={value} onChange={onChange}/>), []);
    return (<Form.Item label='员工状态' {...props} {...fieldLayout}>
      <FilterFieldCompose renderCondition={renderCondition} renderValue={renderValue}/>
    </Form.Item>);
};
export default StaffStatusFilterField;
