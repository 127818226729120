import React, { useCallback, useState, } from 'react';
import { Form } from 'antd';
import ConditionSelect, { conditions_a } from '@src/pages/components/ConditionSelect';
import CategorySelectCheckbox from '@src/pages/components/filter/components/CategorySelectCheckbox';
import fieldLayout from '@src/pages/components/filter/fieldLayout';
import FilterFieldCompose from '@src/pages/components/FilterFieldCompose';
import { StaffType } from '@src/api/interface';
import { getStaffTypes } from '@src/pages/components/StaffTypeSelect';
const CustomerTypeFilterField = (props) => {
    const { disabledTypes, spreadAll, typeFilter, ...restProps } = props;
    const [types] = useState(() => getStaffTypes());
    const renderCondition = useCallback((_v, condition, onChange) => (<ConditionSelect conditions={conditions_a} value={condition} onChange={onChange}/>), []);
    const renderValue = useCallback((value, _c, onChange) => (<CategorySelectCheckbox data={types} allFlag={StaffType.all} allText='全部员工' itemFilter={typeFilter} spreadAll={spreadAll} disabledIds={disabledTypes} value={value} onChange={onChange}/>), [disabledTypes, spreadAll, typeFilter]);
    return (<Form.Item label='员工类型' {...restProps} {...fieldLayout}>
        <FilterFieldCompose renderCondition={renderCondition} renderValue={renderValue}/>
      </Form.Item>);
};
export default CustomerTypeFilterField;
