import { genUrlWithQuery } from '@src/api/urls';
import { Tab, Type } from './Demand/Apply/List/interface';
export const demandListUrl = genUrlWithQuery('/hr/recruit');
export const initApplyListQuery = {
    type: Type.myApply,
    tab: Tab.todo,
};
export const initApplyDetailQuery = {
    request_id: null,
    approval_id: null,
};
export const applyListUrl = genUrlWithQuery('/hr/recruit/apply/list');
// pc 端申请详情
export const applyDetailUrl = genUrlWithQuery('/hr/recruit/apply/detail');
// mobile 端申请详情
export const applyDetailMobileUrl = genUrlWithQuery('/page/mobile/hr/recruit/apply/detail');
