import React, { useEffect } from 'react';
import { Select } from 'antd';
import { getSafe } from '@src/tools/util';
import { fetchCompanyList } from '@src/pages/components/CompanySelect/service';
import { useDerivedState, useModel } from 'femo';
import model from './model';
const CompanySelect = (props) => {
    const { showNoCompany, ...rest } = props;
    const [originList] = useModel(model.list);
    const [list] = useDerivedState(() => {
        if (showNoCompany) {
            return [{
                    company_id: 0,
                    company_name: '无',
                }, ...originList];
        }
        return originList;
    }, () => {
        if (showNoCompany) {
            return [{
                    company_id: 0,
                    company_name: '无',
                }, ...originList];
        }
        return originList;
    }, [showNoCompany, originList]);
    useEffect(() => {
        fetchCompanyList();
    }, []);
    return (<Select {...rest}>
      {list.map((company) => (<Select.Option value={getSafe(company, 'company_id')} key={getSafe(company, 'company_id')}>
              {getSafe(company, 'company_name')}
            </Select.Option>))}
    </Select>);
};
CompanySelect.defaultProps = {
    showNoCompany: false,
};
export default CompanySelect;
