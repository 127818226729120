import { ContractTypes, contractTypesValue } from '@src/pages/components/ContractTypeSelect';
import moment from 'moment';
import { StaffType } from '@src/api/interface';
import { getSafe } from '@src/tools/util';
export var FormMode;
(function (FormMode) {
    FormMode["new"] = "new";
    FormMode["turnRegularStaff"] = "turnRegularStaff";
    FormMode["contractChange"] = "contractChange";
})(FormMode || (FormMode = {}));
export const isRegularLargeType = (staffType) => staffType === StaffType.largeScaleRegular; // 企业邮箱非必填（不属于产研员工，可进行转员工类型），新增「试用期薪资」、「正常薪资」、「浮动奖金」都是必填
export const isRegularPractice = (staffType) => staffType === StaffType.regularPractice; // 正式员工（产研实习），增加了 实习薪资和浮动奖金字段
export const isLargeScaleRegularPractice = (staffType) => staffType === StaffType.largeScaleRegularPractice; // 正式员工（销服实习）， 增加了实习薪资
export const isRegularType = (staffType) => (staffType === StaffType.regular // 属于产研员工（不可进行转员工类型） 100
    || staffType === StaffType.regularPractice // 属于产研员工（不可进行转员工类型）101
    || staffType === StaffType.largeScaleRegular // 企业邮箱非必填（不属于产研员工，可进行转员工类型），新增「试用期薪资」、「正常薪资」、「浮动奖金」都是必填 700
    || staffType === StaffType.largeScaleRegularPractice // 企业邮箱非必填（不属于产研员工，可进行转员工类型）701
    || staffType === StaffType.cloud // 企业邮箱非必填（不属于产研员工，可进行转员工类型）800
    || staffType === StaffType.other // 企业邮箱非必填（属于产研员工？不能进行转员工类型）600
);
export const isOutSourcingType = (staffType) => (staffType === StaffType.outsourcing // 可进行转员工类型
    || staffType === StaffType.dispatchOutSouring // 可进行转员工类型
    || staffType === StaffType.largeScaleOutsourcing // 可进行转员工类型
);
export const isPracticeType = (staffType) => (staffType === StaffType.practice // 可进行转员工类型
    || staffType === StaffType.practiceSummer // 可进行转员工类型
    || staffType === StaffType.practiceOutsourcing // 可进行转员工类型
);
export const setTimeByStartTimeCommon = (form, names, staffType, mode, autoSet = true) => {
    const contractStartTime = typeof names.contractStartName === 'string' ? form.getFieldValue(names.contractStartName) : names.contractStartName;
    const contractType = form.getFieldValue(names.contractTypeName);
    const isNotInfinite = contractType !== contractTypesValue[ContractTypes.random];
    const result = {};
    if (contractStartTime) {
        let regularTime = moment(contractStartTime).add(6, 'months');
        // 这些判断和 添加员工时使用哪个表单一致
        const isOutSourcing = isOutSourcingType(staffType);
        const isPractice = isPracticeType(staffType);
        const isRegular = isRegularType(staffType);
        const shouldSet = mode === FormMode.new || mode === FormMode.turnRegularStaff;
        if (shouldSet && isOutSourcing) {
            regularTime = moment(contractStartTime).add(2, 'months');
        }
        let endTime = moment(contractStartTime).add(3, 'years').subtract(1, 'day');
        if (shouldSet && isOutSourcing) {
            endTime = moment(contractStartTime).add(2, 'years').subtract(1, 'day');
        }
        else if (shouldSet && isPractice) {
            endTime = moment(contractStartTime).add(3, 'months').subtract(1, 'day');
        }
        // @ts-ignore
        if (isRegular
            || isOutSourcing
            || isPractice) {
            if (names.turnRegularTimeName) {
                result[names.turnRegularTimeName] = regularTime;
            }
            if (isNotInfinite) {
                result[names.contractEndName] = endTime;
            }
            if (autoSet) {
                form.setFieldsValue(result);
            }
        }
    }
    return {
        endTime: result[names.contractEndName],
        turnRegularTime: result[names.turnRegularTimeName],
    };
};
export const contractEndTimeRules = [{
        validator: (_rule, value) => {
            if (value) {
                const nowEnd = moment().endOf('day').valueOf();
                const valueTime = moment(value).valueOf();
                if (valueTime <= nowEnd)
                    return Promise.reject('合同结束时间需要大于今天');
            }
            return Promise.resolve();
        },
        validateTrigger: ['onSubmit', 'onChange'],
    }];
export const getRules = (message, isCorpEmail = false) => {
    if (!isCorpEmail) {
        return [{
                required: true,
                message,
            }];
    }
    return [{
            required: true,
            message,
        }, {
            validator: (_rule, value) => {
                const arr = `${value || ''}`.split('@');
                const prefix = getSafe(arr, '[0]');
                const suffix = getSafe(arr, '[1]');
                if (!prefix && suffix) {
                    return Promise.reject('请输入');
                }
                return Promise.resolve();
            },
        }];
};
