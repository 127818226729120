import { ProfileOutlined } from '@ant-design/icons';
import { User } from '@src/api/interface';
import { gluer } from 'femo';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
const initialState = {
    name: '法务系统',
    path: '/legal',
    permissions: [User.LawAuthKey.module_invisible_assets],
    component: () => <Redirect to='/legal/apply'/>,
    icon: ProfileOutlined,
    subRoads: [
        {
            name: '无形资产申请记录',
            path: '/apply-records',
            permissions: [User.LawAuthKey.menu_invisible_assets_management],
            //  permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./IntangibleAssets/ApplyRecords/RecordsPerson')),
        },
        {
            name: '无形资产申请',
            path: '/apply',
            permissions: [User.LawAuthKey.menu_invisible_assets_management],
            //  permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./IntangibleAssets/Manage/Apply')),
        },
        {
            name: '无形资产管理',
            path: '/manage',
            permissions: [User.LawAuthKey.menu_invisible_assets_management],
            //  permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./IntangibleAssets/Manage/index')),
        },
        {
            name: '无形资产申请管理',
            path: '/apply-records-manage',
            permissions: [User.LawAuthKey.menu_invisible_assets_management],
            //  permissions: new Set([User.ModuleHRSubAuthKey.menu_hr_probation]),
            component: lazy(() => import('./IntangibleAssets/ApplyRecords/RecordsManage')),
        },
    ],
};
const road = gluer(initialState);
export default road;
