import * as Sentry from '@sentry/react';
const sentry = {
    reportErrorBoundary(error, message, context) {
        console.error(error, message, context);
        Sentry.captureException(error);
    },
    reportError(error) {
        Sentry.captureException(error);
    },
};
export default sentry;
