import React, { useCallback } from 'react';
import { Offer2 } from '@src/pages/HR/Offer2/interface';
import { getSafe } from '@src/tools/util';
import useQueryObjStrict from '@src/hooks/useQueryObjStrict';
import { defaultOffer2UrlQuery, offer2Url } from '@src/pages/HR/Offer2/url';
import { Tabs } from 'antd';
import OfferList from '@src/pages/HR/Offer2/NotRegular/OfferList';
import QuitList from '@src/pages/HR/Offer2/NotRegular/QuitList';
import style from './style.less';
const defaultActiveKey = Offer2.SubKeys.not_regular_offer_list;
export const defaultNotRegularUrlQuery = {
    ...defaultOffer2UrlQuery,
    sub_key: defaultActiveKey,
};
const NotRegularPage = (props) => {
    const { location, history, control } = props;
    const search = getSafe(location, 'search') || '';
    const [query] = useQueryObjStrict(search, {
        ...defaultNotRegularUrlQuery,
    });
    const activeKey = getSafe(query, 'sub_key');
    const onChange = useCallback((key) => {
        history.push(offer2Url({
            tab_key: Offer2.TabKeys.notRegular,
            sub_key: key,
        }));
    }, []);
    return (<Tabs className={style.offer2} defaultActiveKey={activeKey} activeKey={activeKey} onChange={onChange}>
      <Tabs.TabPane tab='offer列表' key={Offer2.SubKeys.not_regular_offer_list}>
        <OfferList history={history} location={location} activeSubKey={activeKey} control={control}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab='毁约列表' key={Offer2.SubKeys.not_regular_quit_list}>
        <QuitList history={history} location={location} activeSubKey={activeKey} control={control}/>
      </Tabs.TabPane>
    </Tabs>);
};
export default NotRegularPage;
