import React from 'react';
import { Form } from 'antd';
import OrgSelect from '@src/pages/components/OrgSelect';
const OrganizationField = (props) => {
    const { disableTop } = props;
    return (<Form.Item {...props}>
      <OrgSelect hideDel disableTop={disableTop} placeholder='请选择'/>
    </Form.Item>);
};
OrganizationField.defaultProps = {
    label: '所属部门',
};
export default OrganizationField;
