import { useCallback, useState } from 'react';
import useResult from '@src/hooks/useResult';
const useVisible = (v) => {
    const [visible, updateVisible] = useState(v);
    const show = useCallback(() => {
        updateVisible(true);
    }, []);
    const hide = useCallback(() => {
        updateVisible(false);
    }, []);
    return useResult({
        visible,
        show,
        hide,
    });
};
export default useVisible;
