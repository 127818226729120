import React from 'react';
import { SalaryPaymentKind } from '@src/api/interface';
import { Select } from 'antd';
import { getSafe } from '@src/tools/util';
import { useDerivedState } from 'femo';
import Clamp from '@src/components/Clamp';
const salaryType = [{
        id: SalaryPaymentKind.byDay,
        name: '每天',
    }, {
        id: SalaryPaymentKind.byWeek,
        name: '每周',
    }, {
        id: SalaryPaymentKind.byMonth,
        name: '每月',
    }, {
        id: SalaryPaymentKind.byPieceAndTime,
        name: '计时计件',
    }, {
        id: SalaryPaymentKind.byDayWithoutCheckIn,
        name: '按天免打卡',
    }];
const SalaryTypeSelect = (props) => {
    const { isIntern, setSalaryDisabled, ...rest } = props;
    useDerivedState(() => {
        if ('value' in props && setSalaryDisabled) {
            if (props.value === SalaryPaymentKind.byPieceAndTime) {
                setSalaryDisabled(true);
            }
            else {
                setSalaryDisabled(false);
            }
        }
    }, [props.value]);
    const [arr] = useDerivedState(() => {
        if (isIntern) {
            return salaryType.filter((s) => s.id === SalaryPaymentKind.byPieceAndTime || s.id === SalaryPaymentKind.byDayWithoutCheckIn || s.id === SalaryPaymentKind.byDay);
        }
        return salaryType.filter((s) => s.id !== SalaryPaymentKind.byPieceAndTime && s.id !== SalaryPaymentKind.byDayWithoutCheckIn);
    }, [isIntern]);
    return (<Select defaultValue={SalaryPaymentKind.byDay} {...rest}>
      {arr.map((type) => (<Select.Option value={getSafe(type, 'id')} key={getSafe(type, 'id')}>
            <Clamp>
              {getSafe(type, 'name')}
            </Clamp>
          </Select.Option>))}
    </Select>);
};
export default SalaryTypeSelect;
